import emailImg from '../assets/img/Mail.svg';

export default function EmailConfirmation() {
  return (
    <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <div className="flex flex-col items-center gap-4">
          <img src={emailImg} className="size-80" alt="imagem email" />
        </div>
        <div className="text-center max-w-5xl">
          <h1
            className="px-3.5 py-2.5 text-4xl font-bold text-primary-500"
          >
            Enviamos o link de ativação para o seu email.
            <br />
            Verifique a caixa de entrada ou o SPAM/Lixo eletronico
          </h1>
        </div>
      </div>
    </main>
  );
}

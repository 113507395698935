import { formatValue } from '../../../components/Currency';
import { Calculated } from '../../../interfaces';

interface InvoiceProps {
  calculated: Calculated[] | undefined
}
export default function InvoiceTable({ calculated }: InvoiceProps) {
  return (
    <div className="px-4 sm:px-6 lg:px-8 overflow-y-auto max-h-[900px]">
      <div className="-mx-4 mt-4 flow-root sm:mx-0">
        <table className="min-w-full">
          <colgroup>
            <col className="w-full sm:w-1/2" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
          </colgroup>
          <thead className="border-b border-gray-300 text-gray-900">
            <tr>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Status
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Cotas
              </th>
              <th scope="col" className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            {calculated && calculated.map((project) => (
              <tr key={project.status} className="border-b border-gray-200 ">
                <td className="flex justify-between px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">
                  <span className="font-semibold text-black">{project.status}</span>
                </td>
                <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">{project.numeros_solicitados}</td>
                <td className="py-5 pl-3 pr-4 text-left text-sm text-gray-500 sm:pr-0">{formatValue(project.valor_total_pago.toString())}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

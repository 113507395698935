import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

interface ModalConfirmCampaignProps {
  open: boolean
  setOpen: (value: boolean) => void
  quantityNumbers: number
  onConfirm: () => void
}

export default function ModalConfirmCampaign({
  open,
  setOpen,
  quantityNumbers,
  onConfirm,
}: ModalConfirmCampaignProps) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <h1>
        Deseja realmente criar uma campanha com
        {' '}
        <span className="font-bold">
          {quantityNumbers?.toLocaleString()}
          {' '}
          bilhetes
        </span>
        {' '}
        disponíveis para venda?
      </h1>
      <div className="flex gap-4 w-full">
        <Button
          label="Sim"
          customClass="w-full bg-primary-700 hover:bg-primary-500"
          onClick={onConfirm}
        />
        <Button
          label="Não"
          customClass="w-full bg-rose-700 hover:bg-rose-500"
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  );
}

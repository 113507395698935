import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GiftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Tabs from 'components/Tabs';
// import { Button } from '../../../components/Button';
// import { InputItem } from '../../../interfaces';
import { Cota } from 'interfaces';
import AutomaticPrize, { PrizesData } from './Prizes/AutomaticPrize';
// eslint-disable-next-line import/no-cycle
import ManualPrize from './Prizes/ManualPrize';

interface ModalProps {
  toggleModal: boolean;
  setOpen: (value: boolean) => void;
  // inputs: InputItem[]
  // setInputs: React.Dispatch<React.SetStateAction<InputItem[]>>
  onSubmitHandler: (data: PrizesData) => void
  setOpenCotaPremiada: React.Dispatch<React.SetStateAction<boolean>>;
  cotas: Cota[];
  setCotas: React.Dispatch<React.SetStateAction<Cota[]>>
  setSelectedCota: React.Dispatch<React.SetStateAction<Cota>>
  campaingId: string
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  isLastPage: boolean
  totalPages: number
  saveAward: (cota: Cota) => void
  uuidGenerated: string
}

function ModalEditPrizes({
  toggleModal,
  setOpen,
  onSubmitHandler,
  setOpenCotaPremiada, cotas, setCotas, setSelectedCota,
  campaingId,
  page,
  setPage,
  isLastPage,
  saveAward,
  totalPages,
  uuidGenerated,
}: ModalProps) {
  const [selectedTab, setSelectedTab] = useState(1);
  const [tabs, setTabs] = useState([
    {
      id: 1, name: 'Cotas Manual', href: '#1', current: true,
    },
    {
      id: 2, name: 'Cotas Automáticas', href: '#', current: false,
    },
  ]);
  const handleCloseModal = () => {
    setOpen(false);
  };

  // const addInput = () => {
  //   const newInputs: InputItem[] = [...inputs, {
  //     existedInput: false,
  //   }];
  //   setInputs(newInputs);
  // };

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, id?: string) => {
  //   const newInputs: InputItem[] = inputs.map((input) => {
  //     if (input.id === id) {
  //       return { ...input, value: event.target.value };
  //     }
  //     return input;
  //   });
  //   setInputs(newInputs);
  // };

  // const changeNumberValue = (number: string, id?: string) => {
  //   const newInputs: InputItem[] = inputs.map((input) => {
  //     if (input.id === id) {
  //       return { ...input, number };
  //     }
  //     return input;
  //   });
  //   setInputs(newInputs);
  // };

  const changeTabs = (id: number) => {
    const updateTabs = tabs.map((tab) => {
      if (tab.id === id) {
        setSelectedTab(id);
        return {
          ...tab,
          current: true,
        };
      }
      return {
        ...tab,
        current: false,
      };
    });
    setTabs(updateTabs);
  };

  return (
    <Transition.Root show={toggleModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {

        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:ml-20 sm:p-4 sm:mt-8 md:p-0 md:mt-0">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[540px] sm:p-4">
                <div className="text-left">
                  <div className="rounded-md p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white">
                    <div className="flex gap-2 justify-between">
                      <div className="flex justify-center items-center gap-3">
                        <GiftIcon className="size-4" />
                        <h2 className="text-gray-800 font-semibold">
                          Prêmios da campanha
                        </h2>
                      </div>
                      <div>
                        <XMarkIcon className="w-6 text-gray-700 cursor-pointer" onClick={handleCloseModal} />
                      </div>
                    </div>
                    <div className="flex flex-col my-4">
                      <Tabs tabs={tabs} onChange={changeTabs} />
                      {selectedTab === 1 && (
                        <ManualPrize
                          cotas={cotas}
                          setCotas={setCotas}
                          setOpenCotaPremiada={setOpenCotaPremiada}
                          setSelectedCota={setSelectedCota}
                          campaignId={campaingId}
                          page={page}
                          setPage={setPage}
                          isLastPage={isLastPage}
                          saveAward={saveAward}
                          totalPages={totalPages}
                          uuidGenerated={uuidGenerated}
                        />
                      )}
                      {selectedTab === 2 && (
                        <AutomaticPrize
                          onSubmitHandler={onSubmitHandler}
                        />
                      )}
                    </div>
                    {/* <div className="gap-4 grid md:grid-cols-2 sm:grid-cols-1 ">
                        <Button
                          label="Adicionar prêmio"
                          customClass="bg-primary-700 hover:bg-primary-500"
                          onClick={addInput}
                        />
                        <Button
                          label="Confirmar"
                          customClass="bg-primary-700 hover:bg-primary-500"
                          onClick={handleCloseModal}
                        />
                      </div>
                      <Button
                        label="Gerar cotas"
                        customClass="w-full mt-2 bg-primary-700 hover:bg-primary-500"
                        onClick={() => {
                          handleCloseModal();
                          setOpenModal(true);
                        }}
                      /> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { ModalEditPrizes };

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BanknotesIcon, XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { v4 as uuidV4 } from 'uuid';
import { Promo } from 'interfaces';
import { useCampaignsContext } from 'context/CampaignsContext';
import { Button } from '../../../components/Button';

interface ModalProps {
  toggleModal: boolean;
  setOpen: (value: boolean) => void;
  campaignId: string;
  callback: (callback: () => void) => void;
}

function ModalEditPromos({
  toggleModal, setOpen, campaignId, callback,
}: ModalProps) {
  const {
    promotions: promos,
    setPromotions: setPromos,
  } = useCampaignsContext();
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    console.log(promos);
    callback(() => {
      handleCloseModal();
    });
  };

  const addInput = () => {
    const isAllDeleted = promos?.every((value) => value.deleted);
    if (isAllDeleted) {
      setPromos((prevState) => ([
        {
          id: uuidV4(),
          quantidade: 0,
          valor: '0',
          rifa_id: campaignId,
          updated: false,
          deleted: false,
          new: true,
        },
        ...(prevState || []),
      ]));
    } else {
      setPromos((prevState) => ([
        ...(prevState || []),
        {
          id: uuidV4(),
          quantidade: 0,
          valor: '0',
          rifa_id: campaignId,
          updated: false,
          deleted: false,
          new: true,
        },
      ]));
    }
  };

  const removeInput = (id: string) => {
    const updatedPromos = promos?.map((promo) => {
      if (promo.id === id) {
        return {
          ...promo,
          deleted: true,
        };
      }
      return promo;
    });
    setPromos(updatedPromos);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { value, name } = event.currentTarget;
    const updatedPromo = promos?.map((promo) => {
      if (promo.id === id) {
        return {
          ...promo,
          [name]: value,
        };
      }
      return promo;
    }) as Promo[];
    setPromos(updatedPromo);
  };

  return (
    <Transition.Root show={toggleModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:ml-20 sm:p-4 sm:mt-8 md:p-0 md:mt-0">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[540px] sm:p-4">
                <div className="text-left">
                  <div
                    className="rounded-md p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white"
                  >
                    <div className="flex gap-2 justify-between">
                      <div className="flex justify-center items-center gap-3">
                        <BanknotesIcon className="size-4" />
                        <h2 className="text-gray-800 font-semibold">
                          Promoções da campanha
                        </h2>
                      </div>
                      <div>
                        <XMarkIcon className="w-6 text-gray-700 cursor-pointer" onClick={handleCloseModal} />
                      </div>
                    </div>
                    <div className="flex flex-col my-8">
                      <div>
                        {promos?.map((promo, index) => !promo.deleted && (
                          <div className="py-4 relative" key={promo.id}>
                            <span className="text-gray-600 font-semibold">
                              {index === 0 ? 'Promoção de Destaque' : 'Promoção'}
                            </span>
                            <div className="flex flex-col gap-4">
                              <input
                                value={promo.quantidade || ''}
                                name="quantidade"
                                placeholder="Quantidade de bilhetes"
                                onChange={
                                  (
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleInput(event, promo.id)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
                              />

                              <input
                                name="valor"
                                value={promo.valor.toString().replace('.', ',') || ''}
                                placeholder="0,00"
                                onChange={
                                  (
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleInput(event, promo.id)
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
                              />
                            </div>

                            <Button
                              label=""
                              Icon={TrashIcon}
                              customClass={`hover:bg-transparent hover:border-rose-300 border border-transparent group size-6 flex items-center justify-center bg-rose-400 
                              !rounded-full absolute right-0 top-3`}
                              iconClass="group-hover:!text-rose-500 !m-0 size-[14px]"
                              onClick={() => removeInput(promo.id)}
                            />

                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="gap-4 grid md:grid-cols-2 sm:grid-cols-1 ">
                      {
                        promos && promos.filter((value) => !value.deleted).length < 7 && (
                          <Button
                            label="Adicionar promoção"
                            customClass="bg-primary-700 hover:bg-primary-500"
                            onClick={addInput}
                          />
                        )
                      }
                      <Button
                        label="Confirmar"
                        customClass="bg-primary-700 hover:bg-primary-500"
                        onClick={handleConfirmation}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { ModalEditPromos };

import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { padNumber } from 'utils';
import { useUserContext } from '../../../context/GlobalContext';
import { PixQRCode } from '../../../components/PixQrCode';
import { useCampaignsContext } from '../../../context/CampaignsContext';
import { getPaymentValue, removePaymentValue } from '../../../services/localstorageService';

export default function PixPage() {
  const {
    pixCopyPaste, checkPaymentCallBack, paymentData, txid, pixDate,
  } = useUserContext();
  const { campaignBySharedId } = useCampaignsContext();
  const [time, setTime] = useState(26000);

  const navigate = useNavigate();
  const copyPaste = () => {
    navigator.clipboard.writeText(pixCopyPaste);
    toast.success('Chave pix copiada com sucesso');
  };

  const {
    minutes, seconds, start, totalSeconds: remainTimeInSeconds,
  } = useTimer({ expiryTimestamp: pixDate, onExpire: () => console.warn('onExpire called') });

  useEffect(() => {
    start();
    setTimeout(() => {
      checkPaymentCallBack(txid);
      if (time > 3000) {
        setTime(time - 1000);
      }
    }, time);
  }, [time]);

  useEffect(() => {
    if (paymentData?.status === 'CONCLUIDA') {
      toast.success('Estaremos aguardando seu pagamento.');
      removePaymentValue();
      navigate('/campaign/confirmation');
    }
  }, [paymentData]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const value = getPaymentValue();

  const handleProgressBar = () => {
    const totalSeconds = 180;
    return ((remainTimeInSeconds / totalSeconds) * 100).toFixed(2);
  };

  return (
    <div>
      <div className=" bg-primary-900 h-full sm:p-2 md:p-8 mt-10 flex mx-auto flex-col items-center justify-center max-w-[1280px]">
        <div className="bg-primary-500 p-4 rounded-md w-full flex flex-col justify-center items-center max-w-[650px]">
          <div className="text-center">
            <h1 className="text-white text-2xl">
              Aguardando pagamento
              <br />
              <span className="text-white font-semibold"> Não perca tempo!</span>
            </h1>
          </div>
          {
              campaignBySharedId && (
              <div className="mt-4">
                <img src={campaignBySharedId?.imagem} className="w-full max-w-[360px]" alt="banner" />
              </div>
              )
          }
          {
            remainTimeInSeconds > 0 ? (
              <>
                <div className="flex flex-col text-white w-full px-2 py-2">
                  <span className="text-sm">Tempo para você pagar acaba em:</span>
                  <div className="flex">
                    <span className="text-3xl font-bold">
                      {padNumber(minutes.toString(), 2)}
                      :
                    </span>
                    <span className="text-3xl font-bold">{padNumber(seconds.toString(), 2)}</span>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                    <div
                      style={{ width: `${handleProgressBar()}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-secondary-500"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <PixQRCode pixCode={pixCopyPaste} />
                </div>
                <div className="flex flex-col w-full max-w-[500px] gap-7 mt-6">
                  {value && (
                  <h4 className="text-white text-center">
                    Valor:
                    {value}
                  </h4>
                  )}
                  <Input label="" value={pixCopyPaste} customClass="w-full" errorMessage="" />
                  <Button onClick={() => copyPaste()} label="Copiar código Pix" customClass="bg-white border border-primary-500 !text-primary-500 hover:border-white hover:bg-primary-700 hover:!text-white" />
                </div>
              </>
            ) : (
              <div className="flex w-full py-10">
                <h1 className="text-white text-lg">QR Code expirado, tente novamente mais tarde.</h1>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

import { useState } from 'react';
import Tabs from '../../components/Tabs';
import { PayMentMethod } from './PaymentMethod';
import { UserProfile } from './UserProfile';

export default function Settings() {
  const [selectedTab, setSelectedTab] = useState(1);
  const [tabs, setTabs] = useState([
    {
      id: 1, name: 'Minha conta', href: '#1', current: true,
    },
    // {
    //   id: 2, name: 'Meios de pagamento', href: '#', current: false,
    // },
  ]);
  const changeTabs = (id: number) => {
    const updateTabs = tabs.map((tab) => {
      if (tab.id === id) {
        setSelectedTab(id);
        return {
          ...tab,
          current: true,
        };
      }
      return {
        ...tab,
        current: false,
      };
    });
    setTabs(updateTabs);
  };

  return (
    <div>
      <Tabs tabs={tabs} onChange={changeTabs} />
      {selectedTab === 1 && <UserProfile /> }
      {selectedTab === 2 && <PayMentMethod /> }
    </div>
  );
}

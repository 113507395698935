import { FireIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Button';
import banner from '../../../assets/img/bannerCampanha.png';
import avatar from '../../../assets/img/rectangleAvatar.png';

export default function Winners() {
  return (
    <div>
      <div className=" bg-primary-900 h-full sm:p-2 md:p-8 mt-10 flex mx-auto flex-col items-center justify-center max-w-[1280px]">
        <div className="flex gap-1 justify-start w-full items-start pb-2">
          <FireIcon className="size-5 text-white" />
          <h1 className="font-bold self-start text-white">Campanhas</h1>
        </div>
        <div className="w-full bg-primary-500 rounded-t-2xl">
          <img src={banner} className="w-full" alt="banner" />
        </div>
        <div className="bg-primary-500 w-full p-4">
          <div className="grid sm:grid-cols-1 md:grid-cols-2">
            <div>
              <h3 className="uppercase text-white text-lg font-bold">Carnaval da doutora</h3>
              <span className="text-sm text-white">Produto: XXXXX</span>
            </div>
            <div className="flex sm:justify-center md:justify-end">
              <div>
                <span className="text-white">Apenas</span>
                <span className="text-[40px] block font-bold text-white">R$ 4,90</span>
              </div>
            </div>
          </div>
          <div className="md:my-4 sm:mt-4 text-center">
            <Button label="Concluido" customClass="font-semiBold w-full text-[16px] bg-orange-100 !text-primary-500 hover:bg-orange-100" />
            <p className="text-white text-sm my-4">Vendas até 10/02/24 ás 21:00</p>
          </div>
        </div>
        <div className="bg-white w-full sm:p-0 md:p-4 text-center">
          <div className="flex items-center sm:flex-col md:flex-row sm:mt-4 md:mt-auto justify-center">
            <h4 className="text-primary-900 text-xl font-semibold">Sorteio loteria federal: </h4>
            <span className="text-orange-500 text-2xl font-bold px-1">10/02/24 a partir das 22:00</span>
          </div>
          <h4 className="font-extrabold text-2xl sm:p-4 md:px-32 text-primary-900 sm:mt-0 md:mt-8">Ganhadores!</h4>
        </div>
        <div className="p-4 bg-white w-full">
          <h3 className="text-primary-900 font-semibold">Prêmio Principal</h3>
          <div className="flex gap-6 mt-3 border border-primary-900 rounded-md p-4 items-center">
            <img src={avatar} className="sm:size-14 md:size-auto" alt="avatar" />
            <div>
              <h4 className="font-extrabold text-2xl text-primary-900">João da silva</h4>
              <p className="text-primary-900 text-xl">
                Ganhador(a) com o número da sorte
                <b> 902788</b>
              </p>
            </div>
          </div>
        </div>
        <div className="p-4 bg-white w-full pt-6">
          <h3 className="text-primary-900 font-semibold">Prêmios Secundários</h3>
          <div className="flex gap-6 mt-3 border border-primary-900 rounded-md p-4 items-center">
            <img src={avatar} className="sm:size-14 md:size-auto" alt="avatar" />
            <div>
              <h4 className="font-extrabold text-2xl text-primary-900">João da silva</h4>
              <p className="text-primary-900 text-xl">
                Ganhador(a) com o número da sorte
                <b> 902788</b>
              </p>
            </div>
          </div>
          <div className="flex gap-6 mt-3 border border-primary-900 rounded-md p-4 items-center">
            <img src={avatar} className="sm:size-14 md:size-auto" alt="avatar" />
            <div>
              <h4 className="font-extrabold text-2xl text-primary-900">João da silva</h4>
              <p className="text-primary-900 text-xl">
                Ganhador(a) com o número da sorte
                <b> 902788</b>
              </p>
            </div>
          </div>
          <div className="flex gap-6 mt-3 border border-primary-900 rounded-md p-4 items-center">
            <img src={avatar} className="sm:size-14 md:size-auto" alt="avatar" />
            <div>
              <h4 className="font-extrabold text-2xl text-primary-900">João da silva</h4>
              <p className="text-primary-900 text-xl">
                Ganhador(a) com o número da sorte
                <b> 902788</b>
              </p>
            </div>
          </div>
          <div className="flex gap-6 mt-3 border border-primary-900 rounded-md p-4 items-center">
            <img src={avatar} className="sm:size-14 md:size-auto" alt="avatar" />
            <div>
              <h4 className="font-extrabold text-2xl text-primary-900">João da silva</h4>
              <p className="text-primary-900 text-xl">
                Ganhador(a) com o número da sorte
                <b> 902788</b>
              </p>
            </div>
          </div>
        </div>
        <div className=" w-full bg-white p-4 rounded-b-md">
          <Button label="Ver meus títulos" customClass="w-full h-20 text-xl flex items-center size-16  !text-gray-white group-hover:!text-white border border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-primary-400 hover:bg-primary-500" />
        </div>
      </div>
    </div>
  );
}

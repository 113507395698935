import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Link, useNavigate, useOutlet, useParams,
} from 'react-router-dom';
import { useCampaignsContext } from 'context/CampaignsContext';
import logo from '../assets/img/Header.png';
import { LoginModal } from '../pages/PurchaseCampaign/Components/LoginModal';
import { useUserContext } from '../context/GlobalContext';
import cryImg from '../assets/img/crying.svg';
import { isAuthenticated, removeToken } from '../services/localstorageService';
import classNames from '../utils';

const navigation = [
  { name: 'Campanha', current: true },
];

export default function CampaignLayout() {
  const navigate = useNavigate();
  const params = useParams();
  const { toggleLoginModalCallback } = useUserContext();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [campaignId, setCampaignId] = useState<string>('');
  const outlet = useOutlet();
  const {
    campaignBySharedId,
    getCampaignBySharedId,
    campaignBySharedIdLoading,
  } = useCampaignsContext();
  const urlId = () => {
    const { sharedId } = params;
    if (sharedId) {
      setCampaignId(sharedId);
    }
  };
  const verifyIsAuth = () => {
    if (isAuthenticated()) {
      navigate('/campaign/bilhetes');
    } else {
      toggleLoginModalCallback();
    }
  };

  useEffect(() => {
    urlId();
  }, []);

  useEffect(() => {
    if (campaignId) getCampaignBySharedId(campaignId);
  }, [campaignId]);

  const goBack = () => {
    navigate(`/campaign/${campaignId}`);
    setMobileMenuOpen(false);
  };

  return (
    <div>
      <header className="bg-primary-700">
        <nav
          className="mx-auto flex fixed bg-primary-700 w-full z-10 items-center justify-between p-2 lg:px-8"
          aria-label="Global"
        >
          <div className="flex items-center gap-x-12">
            {
              !campaignBySharedIdLoading && (
                <a href="/" className="-m-1.5 p-1.5">
                  <img
                    className="h-14"
                    src={campaignBySharedId?.logo === '' || campaignBySharedId === undefined ? logo : campaignBySharedId?.logo}
                    alt="logo"
                  />
                </a>
              )
            }

            <div className="lg:flex lg:gap-x-12">
              {
               campaignBySharedId !== undefined && campaignBySharedId?.titulo_pagina !== '' && (
               <span
                 role="presentation"
                 onClick={goBack}
                 className="text-sm font-semibold leading-6 text-white"
               >
                 {campaignBySharedId?.titulo_pagina}
               </span>
               )
              }

            </div>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex">
            <a
              href="# "
              onClick={() => verifyIsAuth()}
              className="text-sm font-semibold leading-6 text-white"
            >
              Meus bilhetes
              {' '}
              <span aria-hidden="true">&rarr;</span>
            </a>
            <a
              href="# "
              onClick={() => {
                removeToken();
                setMobileMenuOpen(false);
              }}
              className="text-sm font-semibold leading-6 text-white ml-8"
            >
              Desconectar
            </a>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel
            className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
          >
            <div className="flex items-center justify-between">
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <span
                      key={item.name}
                      role="presentation"
                      onClick={goBack}
                      className="-mx-3 block cursor-pointer rounded-lg px-3 p text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
                <div className="my-2">
                  <a
                    href="# "
                    onClick={() => {
                      verifyIsAuth();
                      setMobileMenuOpen(false);
                    }}
                    className="-mx-3 block rounded-lg px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Meus bilhetes
                  </a>
                </div>
                <div className={classNames('my-2', isAuthenticated() ? '' : 'hidden')}>
                  <a
                    href="# "
                    onClick={() => {
                      removeToken();
                      setMobileMenuOpen(false);
                    }}
                    className="-mx-3 block rounded-lg px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Desconectar
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <main className="min-h-screen bg-primary-900 relative">
        <div className="mx-auto sm:px-1 pt-8 lg:px-8 pb-44">
          <div
            className="h-full flex mx-auto sm:mt-10 flex-col items-center justify-center max-w-[1280px]"
          >
            {outlet || (
            <div className="w-full p-4 flex justify-center items-center flex-col gap-4">
              <div className="flex flex-col gap-1 items-center text-white">
                <img src={cryImg} className="size-10 invert" alt="imagem de um emoti chorando" />
                <p>Não há nada por aqui</p>
              </div>
            </div>
            )}
          </div>
        </div>
        <div className="bg-zinc-800 h-20 p-4 absolute bottom-0 w-full flex flex-col justify-center items-center">
          <img src={logo} className="size-20" alt="" />
          <div className="text-white">
            <Link to="/termos" className="text-md font-semibold">Termos de uso</Link>
            {' | '}
            <Link to="/politica-de-privacidade" className="text-md font-semibold">Política de privacidade</Link>
          </div>
        </div>
      </main>
      <LoginModal />
    </div>
  );
}

import {
  object, string,
} from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../components/Input';
import { CheckBox } from '../components/CheckBox';
import { Button } from '../components/Button';
import { PhoneInput } from '../components/PhoneInput';
import logo from '../assets/img/Header.png';
import { useUserContext } from '../context/GlobalContext';
import Loading from '../components/Loading';

const loginSchema = object({
  email: string().email()
    .required('Email obrigatório'),
  password: string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  name: string()
    .required('Nome obrigatório.'),
  last_name: string()
    .required('Sobrenome obrigatório.'),
  cpf: string()
    .required('CPF obrigatório.'),
  phone_number: string().required('Telefone obrigatório'),
});
export default function SignUp() {
  const { invite } = useParams();
  const {
    registerCallback,
    registerLoading,
    errorRegister,
    registerAssociateCallback,
    registerAssociateLoading,
    errorAssociateRegister,
    errorRegisterUser,
    registerUserLoading,
  } = useUserContext();
  const [isRegister, setIsRegister] = useState(false);
  const navigate = useNavigate();

  const {
    setValue, formState: { errors }, handleSubmit, reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } : any = event.currentTarget;
    setValue(name, value);
  };
  const onSubmitHandler = (data: any) => {
    if (invite) {
      registerAssociateCallback(data, setIsRegister, invite);
    } else {
      registerCallback(data, setIsRegister);
    }
  };

  useEffect(() => {
    if (errorRegister) {
      toast.error(`${errorRegister}`);
    }
    if (errorAssociateRegister) {
      toast.error(`${errorAssociateRegister}`);
    }
    if (errorRegisterUser) {
      toast.error(`${errorRegisterUser}`);
    }
  }, [errorRegister, errorAssociateRegister, errorRegisterUser]);

  useEffect(() => {
    if (isRegister) {
      toast.success('Usuário cadastrado com sucesso');
      reset();
      navigate('/');
    }
  }, [isRegister]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center  py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center">
        <img src={logo} className="sm h-16 md:h-32" alt="logo" />
      </div>
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[650px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg">
          <div className="flex text-center flex-col">
            <h1 className="text-gray-800 text-lg font-semibold">Registre-se</h1>
            <p className="text-gray-700 text-sm font-normal">Preencha os campos abaixo para registrar-se</p>
          </div>
          <form className="space-y-6 mt-6" action="#" method="POST" onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-5">
              <Input label="Nome" name="name" onChange={onChange} errorMessage={errors.name?.message} />
              <Input label="Sobrenome" name="last_name" onChange={onChange} errorMessage={errors.last_name?.message} />
            </div>
            <div className="flex flex-col gap-y-4">
              <Input label="CPF" mask="999.999.999-99" name="cpf" onChange={onChange} errorMessage={errors.cpf?.message} />
              <Input label="Email" name="email" onChange={onChange} errorMessage={errors.email?.message} />
              <Input label="Password" name="password" type="password" onChange={onChange} errorMessage={errors.password?.message} />
              <PhoneInput label="Telefone" name="phone_number" onChange={onChange} errorMessage={errors.phone_number?.message} />
            </div>
            <div className="flex items-center">
              <CheckBox label="" name="remember-me" />
              <div className="text-sm leading-6">
                <p>
                  Ao se registrar, você aceita nossos
                  <a
                    href="/#"
                    className="text-gray-900 mx-1 hover:text-indigo-500 text-decoration-line: underline"
                  >
                    Termos de Uso
                  </a>
                  e a nossa
                  <a
                    href="/"
                    className="text-gray-900 mx-1 hover:text-indigo-500 text-decoration-line: underline"
                  >
                    Política de
                    Privacidade.
                  </a>
                </p>
              </div>
            </div>
            <div>
              <Button label="Registrar-se" type="submit" customClass="bg-primary-700 hover:bg-primary-500 w-full" />
            </div>
          </form>
          <div>
            <div className="relative mt-5">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm leading-6">
                <span className="bg-white px-2 text-gray-500">
                  <a className="text-gray-600 px-1 font-medium hover:text-indigo-500" href="/">Fazer login</a>
                </span>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-2 gap-4" />
          </div>
        </div>
      </div>
      <Loading open={(registerLoading || registerAssociateLoading || registerUserLoading)} />
    </div>
  );
}

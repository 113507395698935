import * as React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Campaign from 'pages/Campaign';
import NewCampaign from 'pages/Campaign/NewCampaign';
import EditCampaing from 'pages/Campaign/EditCampaign';
import CampaignAvailable from 'pages/PurchaseCampaign/CampaignAvailable';
import UserLogin from 'pages/PurchaseCampaign/UserLogin';
import PixPage from 'pages/PurchaseCampaign/PixPage';
import Winners from 'pages/PurchaseCampaign/Winners';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import NewClient from 'pages/Client';
import Login from './pages/Login';
import Page404 from './pages/404';
import SignUp from './pages/SignUp';
import { Logged } from './layout/Logged';
import Affiliates from './pages/Affiliates';
import Settings from './pages/Settings';
import CampaignLayout from './layout/CampaignLayout';
import EmailConfirmation from './pages/EmailConfirmation';
import MyTitles from './pages/PurchaseCampaign/MyTitles';
import Confirmation from './pages/PurchaseCampaign/Confirmation/Confirmation';
import InvoicePage from './pages/Invoice';
import InvoiceAffiliates from './pages/Invoice/InvoiceAffiliates';
import InvoiceMetrics from './pages/Invoice/InvoiceMetrics';
import Products from './pages/Products';
import NewAdmin from './pages/NewAdmin';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/signup/:invite',
    element: <SignUp />,
  },
  {
    path: 'about',
    element: <div>About</div>,
  },
  {
    path: '/recuperar-senha',
    element: <ForgotPassword />,
  },
  {
    path: '/recuperar-senha/:uuid',
    element: <ResetPassword />,
  },
  {
    element: <Logged />,
    path: 'app',
    children: [
      {
        path: '',
        element: <Campaign />,
      },
      {
        path: 'afiliados',
        element: <Affiliates />,
      },
      {
        path: 'criar',
        element: <NewCampaign />,
      },
      {
        path: 'campanha/editar/:uuid',
        element: <EditCampaing />,
      },
      {
        path: 'configuracao',
        element: <Settings />,
      },
      {
        path: 'financeiro',
        element: <InvoicePage />,
      },
      {
        path: 'financeiro/afiliados',
        element: <InvoiceAffiliates />,
      },
      {
        path: 'metricas',
        element: <InvoiceMetrics />,
      },
      {
        path: 'produtos',
        element: <Products />,
      },
      {
        path: 'admin',
        element: <NewAdmin />,
      },
      {
        path: 'clientes',
        element: <NewClient />,
      },

    ],
  },
  {
    element: <CampaignLayout />,
    path: 'campaign',
    children: [
      {
        path: ':sharedId',
        element: <CampaignAvailable />,
      },
      {
        path: 'bilhetes',
        element: <MyTitles />,
      },
      {
        path: ':sharedId/ganhadores',
        element: <Winners />,
      },
      {
        path: ':sharedId/pagamento-pix',
        element: <PixPage />,
      },
      {
        path: ':sharedId/login-usuario',
        element: <UserLogin />,
      },
      {
        path: 'confirmation',
        element: <Confirmation />,
      },
    ],
  },
  {
    path: '*',
    element: <Page404 />,
  },
  {
    path: 'confirmacao-email',
    element: <EmailConfirmation />,
  },
]);

export default router;

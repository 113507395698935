import React, { useEffect, useState } from 'react';
import { FireIcon } from '@heroicons/react/24/outline';
import { Select } from 'components/Select';
import { Button } from 'components/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from 'context/GlobalContext';
import cryImg from '../../assets/img/crying.svg';
import HasCampaign from './Components/HasCampaign';
import { useCampaignsContext } from '../../context/CampaignsContext';
import { getUserData } from '../../utils';

const status = [
  {
    value: 'CRIADA',
    name: 'Criada',
  },
  {
    value: 'ATIVA',
    name: 'Ativa',
  },
  {
    value: 'CONCLUIDA',
    name: 'Concluida',
  },
];
export default function Campaign() {
  const user = getUserData();
  const navigate = useNavigate();
  const { getCampaignsCallback, getCampaigns } = useCampaignsContext();
  const { listPermissionsCallback, permissions } = useUserContext();
  const [selectedStatus, setSelectedStatus] = useState('CRIADA');

  const onChange = (event: React.ChangeEvent<
        HTMLInputElement |
        HTMLSelectElement |
        HTMLTextAreaElement>) => {
    const { value } : any = event.currentTarget;
    setSelectedStatus(value);
  };

  useEffect(() => {
    getCampaignsCallback();
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    const data = getUserData();
    listPermissionsCallback(data.id, () => {});
  }, []);

  return (
    <>
      <Link to="criar">
        {user && (permissions.permissions.gerenciamento_campanhas || user?.roles === 'Client') && (
        <Button label="Criar campanha" customClass="bg-primary-700 hover:bg-primary-500 w-44 mt-7" Icon={FireIcon} />
        )}
      </Link>
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg mt-10">
        <h1 className="font-semibold">Minhas campanhas</h1>
        <p className="text-sm">Aqui estão suas campanhas.</p>
        <Select defaultValue={selectedStatus} options={status} onChange={onChange} customClass="w-auto mt-5 hidden" />
        {
              getCampaigns.length > 0 ? (
                <HasCampaign campaignData={getCampaigns} user={user} permissions={permissions} />
              ) : (
                <div className="w-full p-4 flex justify-center items-center flex-col gap-4">
                  <div className="flex flex-col gap-1 items-center">
                    <img src={cryImg} className="size-10" alt="imagem de um emoti chorando" />
                    <p>Não há campanhas criadas</p>
                  </div>
                  <Link to="criar" className="w-full">
                    {user && permissions.permissions.gerenciamento_campanhas && (
                    <Button label="Criar campanha" customClass="bg-primary-700 hover:bg-primary-500 w-full" />
                    )}
                  </Link>
                </div>
              )
          }
      </div>
    </>
  );
}

import React from 'react';
import { Permission } from 'interfaces';
import { isUserActivated } from '../../../utils';
import avatar from '../../../assets/img/Avatar.svg';

interface TableUserProps {
    users: any[]
    selectedUser: (user: any) => void
    disableUser: (user: any) => void
    openChangePasswordModal: () => void
    setOpen: (value: boolean) => void
    permissions: Permission
    userLogged: any
}

function Table({
  users,
  selectedUser,
  disableUser,
  openChangePasswordModal,
  setOpen,
  userLogged,
  permissions,
}: TableUserProps) {
  return (
    <table className="min-w-full divide-y divide-gray-300 table-auto">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold uppercase text-gray-500 sm:pl-6"
          >
            Nome
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-xs font-semibold uppercase text-gray-500"
          >
            Porcentagem
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-xs font-semibold uppercase text-gray-500"
          >
            Status
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {users.length > 0 ? (
          users.map((person) => (
            <tr key={person.email}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex gap-4 items-center">
                <div>
                  <img
                    src={person.image ?? avatar}
                    className="rounded-full w-10"
                    alt={person.name}
                  />
                </div>
                <div className="flex flex-col">
                  <span>{person.name}</span>
                  <span className="text-sm font-normal text-gray-500">
                    {person.email}
                  </span>
                </div>
              </td>
              <td className="px-3 py-4 text-sm text-gray-500">
                <span>
                  {person.porcentagem !== undefined
                    ? `${person.porcentagem}%`
                    : ''}
                </span>
              </td>
              <td className="px-3 py-4 text-sm text-gray-500">
                <span>{person.status}</span>
              </td>
              {userLogged && (permissions.permissions.gerenciamento_afiliados || userLogged?.roles === 'Client') && (
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <button
                    onClick={() => {
                      selectedUser(person);
                      setOpen(true);
                    }}
                    type="button"
                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                  >
                    Editar
                  </button>
                  <button
                    className="text-red-600 hover:text-red-900 mr-4"
                    type="button"
                    onClick={() => {
                      selectedUser(person);
                      console.log(person);
                      openChangePasswordModal();
                    }}
                  >
                    Alterar Senha
                  </button>
                  <button
                    onClick={() => {
                      disableUser(person);
                    }}
                    className="text-red-600 hover:text-red-900"
                    type="button"
                  >
                    {isUserActivated(person.status)
                      ? 'Bloquear'
                      : 'Desbloquear'}
                  </button>
                </td>
              )}
            </tr>
          ))
        ) : (
          <tr className="text-sm font-medium text-gray-900">
            <td className="p-6" colSpan={3}>
              <span className="text-gray-500">Não há usuário</span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export { Table };

import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from '../utils';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string | ReactNode;
  customClass?: string;
  roundedClass?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  Icon?: any,
  iconClass?: string
}

const Button: React.FC<ButtonProps> = ({
  id,
  name,
  label,
  disabled,
  type,
  customClass = '',
  roundedClass = 'rounded-md',
  onClick,
  Icon,
  iconClass = '',
}: ButtonProps) => (
  <button
    id={id}
    name={name}
    type={type === 'button' ? 'button' : 'submit'}
    className={classNames(
      customClass,
      roundedClass,
      ' flex justify-center bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
    )}
    disabled={disabled}
    onClick={onClick}
  >
    {Icon && <Icon className={` ${iconClass} text-white group-hover:text-white size-6 shrink-0 mr-1`} />}
    {label}
  </button>
);

Button.defaultProps = {
  onClick: () => {},
  customClass: '',
  Icon: '',
  iconClass: '',
  roundedClass: 'rounded-md',
};

export { Button };

import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cryImg from '../../../assets/img/crying.svg';
import useGet from '../../../hooks/useGet';
import { Button } from '../../../components/Button';

interface ModalProps {
  toggleModal: boolean;
  setOpen: (value: boolean) => void;
  rifaId: string;
}

function ModalSearchNumber({ toggleModal, setOpen, rifaId }: ModalProps) {
  const [number, setNumber] = useState<number>();
  const [details, setDetails] = useState<{
    name:string,
    last_name: string
    number: string
    phone_number: string
    cpf: string
  }>();
  const { request: getNumber } = useGet();

  const findNumber = () => {
    if (number || number === 0) {
      getNumber(`rifa/detail/number/${rifaId}/${number.toString()}`, (response) => setDetails(response.details));
    } else {
      toast.warning('Digite um número');
    }
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Transition.Root show={toggleModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:ml-20 sm:p-4 sm:mt-8 md:p-0 md:mt-0">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[840px] sm:p-4"
              >
                <div className="flex w-full justify-end">
                  <XMarkIcon className="w-6 text-gray-700 cursor-pointer" onClick={handleCloseModal} />
                </div>
                <div className="bg-white shadow sm:rounded-lg mt-6 mb-4">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="mt-2 text-sm text-gray-500 text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">Busque um determinado número</h3>
                      <p>Essa busca retorna as informações do comprador.</p>
                    </div>
                    <div className="mt-5 justify-center w-full flex items-center">
                      <div className="w-full flex gap-4">
                        <input
                          type="number"
                          name="number"
                          id="numer"
                          className="block rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="000000"
                          onChange={(event) => setNumber(parseInt(event.currentTarget.value, 10))}
                        />
                        <Button
                          label="Buscar número"
                          customClass="mt-3 w-52  items-center justify-center rounded-md bg-primary-700 p-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
                          onClick={() => findNumber()}

                        />
                      </div>

                    </div>

                    {details?.number ? (
                      <div className="-mx-4 mt-8 flow-root sm:mx-0 overflow-auto">
                        <table className="min-w-full ">
                          <colgroup>
                            <col className="w-full sm:w-1/2" />
                            <col className="sm:w-1/6" />
                            <col className="sm:w-1/6" />
                            <col className="sm:w-1/6" />
                            <col className="sm:w-1/6" />
                          </colgroup>
                          <thead className="border-b border-gray-300 text-gray-900">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                              >
                                Nome
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                              >
                                CPF
                              </th>
                              <th
                                scope="col"
                                className="hidden py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-3"
                              >
                                Número
                              </th>
                              <th
                                scope="col"
                                className="hidden py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-3"
                              >
                                Contato
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="border-b border-gray-200 border-b-0">
                              <td className="hidden py-5 text-left text-sm text-gray-500 sm:table-cell">
                                {details?.name}
                                {' '}
                                {details?.last_name}
                              </td>
                              <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">{details?.cpf}</td>
                              <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">{details?.number}</td>
                              <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">{details?.phone_number}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div
                        className="bg-gray-800 flex flex-col gap-4 items-center justify-center mt-2 p-4 rounded-lg w-full"
                      >
                        <div className="flex flex-col gap-1 items-center text-white">
                          <img src={cryImg} className="size-10 invert" alt="imagem de um emoti chorando" />
                          <p>Não há compra vinculada a este número.</p>
                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { ModalSearchNumber };

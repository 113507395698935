import React from 'react';

interface AvatarProps {
    size?: 'small' | 'medium' | 'large'
    photo?: string
    name: string
}

const Avatar: React.FC<AvatarProps> = ({ size = 'medium', photo, name }) => {
  const getSizeClass = (): string => {
    switch (size) {
      case 'small':
        return 'w-8 h-8';
      case 'large':
        return 'w-12 h-12';
      default:
        return 'w-10 h-10';
    }
  };

  const getInitials = (userName: string): string => {
    const initials = userName
      .split(' ')
      .map((part) => part[0] && part[0].toUpperCase())
      .join('');

    return initials.length > 2 ? initials.slice(0, 2) : initials;
  };

  return (
    <div
      className={`flex items-center justify-center border-2 border-white dark:border-gray-700 bg-indigo-100 rounded-full overflow-hidden ${getSizeClass()}`}
    >
      {(photo != null && photo !== '')
        ? (
          <img
            src={photo}
            alt="Avatar"
            className="w-full h-full object-cover"
          />
        )
        : (
          <span className="text-indigo-900 text-base font-bold">
            {getInitials(name)}
          </span>
        )}
    </div>
  );
};

Avatar.defaultProps = {
  size: 'small',
  photo: '',
};

export default Avatar;

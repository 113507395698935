import { TopPurchases } from '../../../interfaces';

interface TopBuyersTableProps {
  topPurchases: TopPurchases | undefined
}
function TopBuyersTable({ topPurchases }: TopBuyersTableProps) {
  console.log(topPurchases);
  return (
    <div className="px-4 sm:px-6 lg:px-8 overflow-y-auto max-h-[900px]">
      <h3 className="font-semibold">Top 5 compradores</h3>
      <div className="-mx-4 mt-4 flow-root sm:mx-0">
        <table className="min-w-full">
          <colgroup>
            <col className="w-full sm:w-1/2" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
          </colgroup>
          <thead className="border-b border-gray-300 text-gray-900">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Nome
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Telefone
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Bilhetes Comprados
              </th>
            </tr>
          </thead>
          <tbody>
            {topPurchases?.purchases.map((topPurchase) => (
              <tr key={topPurchase.telefone_afiliado} className="border-b border-gray-200">
                <td className="hidden py-5 text-left text-sm text-black sm:table-cell">{topPurchase.nome_afiliado}</td>
                <td className="flex justify-between px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">
                  <span>{topPurchase.telefone_afiliado}</span>
                </td>
                <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">{topPurchase.quantidade_numeros}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export { TopBuyersTable };

import React, { useState, useCallback } from 'react';
import { api } from '../api/api';

interface UsePostResponse {
  loading: boolean
  error: Error | null
  setError: React.Dispatch<React.SetStateAction<Error | null>>
  request: (url: string, data: any, callback: (payload: any) => void) => any
}

const usePost = (): UsePostResponse => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (url: string, data: any, callback: (payload: any) => void) => {
      try {
        setLoading(true);
        const response = await api.post(`api/${url}`, data);
        callback(response.data);
      } catch (requestError: any) {
        if (requestError.response === undefined) {
          setError(requestError.message);
        }
        if (requestError.response !== undefined && requestError.response.data.message) {
          setError(requestError.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    request, loading, error, setError,
  };
};

export default usePost;

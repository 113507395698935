/* eslint-disable max-len */
import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Promo } from 'interfaces';
import { Button } from '../../../components/Button';
import { formatValue } from '../../../components/Currency';
import usePost from '../../../hooks/usePost';
import Loading from '../../../components/Loading';
import { useUserContext } from '../../../context/GlobalContext';
import { setPaymentValue } from '../../../services/localstorageService';
// import { LoginModal } from './LoginModal';

interface ModalProps{
  setOpen: (value:boolean) => void
  data: {
    shared_id: string
    sorteio_id: string
    valor: number
    user_id: string
    quantidade_numeros: number
    promoqtd: number
    selectedProm?: Promo
  }
}
function PurchaseModal({ setOpen, data }:ModalProps) {
  const navigate = useNavigate();
  const { request: paymentRequest, error: paymentError, loading: paymentLoading } = usePost();
  const {
    setPixCopyPaste, setTxid, setPixDate, toggleLoginModalCallback,
  } = useUserContext();
  const handleCloseModal = () => {
    setOpen(false);
  };

  const payment = () => {
    if (data.user_id) {
      setPaymentValue(formatValue((data.selectedProm === undefined ? (data.valor * data.quantidade_numeros) : (data.selectedProm.valor * data.promoqtd)).toString()));
      paymentRequest('payment/new', {
        ...data,
        valor: data.selectedProm === undefined ? (data.valor * data.quantidade_numeros) : (data.selectedProm.valor * data.promoqtd),
      }, (response) => {
        setPixCopyPaste(response.pix);
        setTxid(response.txid);
        const now = new Date(response.data_geracao);
        now.setMinutes(now.getMinutes() + 3);
        setPixDate(now);
        navigate('pagamento-pix');
      });
    } else {
      toggleLoginModalCallback();
    }
  };

  useEffect(() => {
    if (paymentError) {
      toast.error(`${paymentError}`);
    }
  }, [paymentError]);

  return (
    <>
      <Loading open={paymentLoading} />
      <Transition.Root show as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => ''} id="purchase-modal-dialog">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel id="purchase-modal-panel" className="relative transform overflow-hidden rounded-lg bg-primary-900 px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[400px] sm:p-4">
                  <div className="text-left">
                    <div className="text-center">
                      <h5 className="font-medium text-white text-4xl mb-2">Confirmar Compra?</h5>
                      <p className="text-sm text-white">
                        Você está comprando
                        {' '}
                        {data.quantidade_numeros}
                        {' '}
                        bilhetes por
                        {' '}
                        {
                          data.selectedProm === undefined ? `${formatValue(data.valor.toString())} cada totalizando ${formatValue((data.valor * data.quantidade_numeros).toString())}` : formatValue((data.selectedProm.valor * data.promoqtd).toString())
                        }
                      </p>
                    </div>
                    <div className="flex justify-between gap-4 mt-4">
                      <Button onClick={handleCloseModal} label="Cancelar" customClass="bg-white hover:bg-primary-700 w-full !rounded-full border border-gray-300 !text-gray-500 hover:!text-white" />
                      <Button onClick={payment} label="Confirmar" customClass="w-full border !text-primary-800 !rounded-full hover:bg-secondary-500 bg-secondary-500 border-primary-700" />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export { PurchaseModal };

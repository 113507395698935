import React, { ChangeEvent } from 'react';
import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface FileInputProps {
  image: File | null
  setImage: React.Dispatch<React.SetStateAction<File | null>>
  setPreviewImage: React.Dispatch<React.SetStateAction<string | undefined>>
  preview?: string | undefined
  name?: string
  hasRemove?: boolean
}
function FileInput({
  image, setImage, preview, name, setPreviewImage, hasRemove,
}:FileInputProps) {
  const onImageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    console.log(e.target.files);
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  return (
    <div className="col-span-full">
      <div className="block text-sm font-medium leading-6 text-gray-900 cursor-default">
        { name ?? 'Imagem de campanha'}
        <div
          className="mt-2 flex flex-col items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2 h-68"
        >
          {hasRemove && (preview || image) && (
            <div className="flex w-full justify-end cursor-default">
              <XMarkIcon
                className="w-6 text-gray-700 cursor-pointer"
                onClick={() => {
                  setPreviewImage(undefined);
                  setImage(null);
                }}
              />
            </div>
          )}
          <label htmlFor="cover-photo" className="cursor-pointer">
            {
            image && (
            <div
              className="text-center w-full flex flex-col py-14 max-w-[500px]"
              style={{
                backgroundImage: `url(${URL.createObjectURL(image)})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
              }}
            />
            )
          }
            {

              (preview && !image) && (
                <div
                  className="text-center w-full flex flex-col py-14 max-w-[500px]"
                  style={{
                    backgroundImage: `url(${preview})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                  }}
                />
              )
          }
            <div>
              {
                image ? '' : <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
            }
            </div>
            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Tamanho recomendado</span>
                <input
                  id="cover-photo"
                  name="file-upload"
                  onChange={onImageChange}
                  type="file"
                  className="sr-only"
                />
              </label>
              <p className="pl-1">1365x758 pixels</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF até 10MB</p>
          </label>
        </div>
      </div>
    </div>
  );
}

FileInput.defaultProps = {
  preview: '',
  hasRemove: false,
};

export { FileInput };

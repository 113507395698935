import { Button } from 'components/Button';
import { Input } from 'components/Input';
import {
  LockClosedIcon,
} from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import ModalMessage from 'components/ModalMessage';
import React, { useEffect, useState } from 'react';
import { useUserContext } from 'context/GlobalContext';
import { InferType, object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

const recoverySchema = object({
  phone_number: string().required('Telefone obrigatório'),
});

type Recovery = InferType<typeof recoverySchema>

export default function ForgotPassword() {
  const [openModalMessage, setOpenModalMessage] = useState<boolean>(false);
  const { recoveryCallback, errorRecoveryRequest, recoveryLoading } = useUserContext();
  const {
    setValue, formState: { errors }, handleSubmit, reset,
  } = useForm({
    resolver: yupResolver(recoverySchema),
  });

  const onSubmitHandler = (data: Recovery) => {
    recoveryCallback(
      data,
      () => {
        setOpenModalMessage(true);
        reset();
      },
    );
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } : any = event.currentTarget;
    setValue(name, value);
  };

  useEffect(() => {
    if (errorRecoveryRequest) {
      toast.error(`${errorRecoveryRequest}`);
    }
  }, [recoveryLoading]);

  return (
    <div className="flex h-screen items-center justify-center flex-col">
      <div className="w-max-[500px] flex flex-col items-center justify-center">
        <LockClosedIcon className="size-40 text-primary-500 my-6" />
        <h1 className="text-4xl font-bold mb-2">Esqueceu a senha?</h1>
        <h2 className="text-md font-bold text-gray-400">Não se preocupe, acontece com o melhor de nós.</h2>
        <h2 className="text-md font-bold text-gray-400">Digite seu telefone para recuperar sua senha.</h2>
        <form
          className="w-full"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Input name="phone_number" onChange={onChange} placeholder="Telefone" mask="(99) 99999-9999" customClass="py-3" containerClass="my-6" type="tel" errorMessage={errors.phone_number?.message} />
          <Button type="submit" label="Enviar" customClass="w-full py-2 bg-primary-700 hover:bg-primary-500" />
        </form>
        <h2 className="text-sm font-bold text-gray-400 my-6">
          Lembrou da senha? volte para o
          {' '}
          <Link to="/" className="text-primary-700 hover:text-primary-500">Login</Link>
        </h2>
      </div>
      <ModalMessage message="" open={openModalMessage} setOpen={setOpenModalMessage}>
        <span className="font-normal">
          Um link de recuperação de senha foi enviado ao seu
          {' '}
          <span className="font-bold">e-mail</span>
        </span>
      </ModalMessage>
    </div>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useUserContext } from 'context/GlobalContext';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  InferType, object, ref, string,
} from 'yup';

const changePasswordSchema = object({
  password: string().required('Senha obrigatória'),
  confirm_password: string().required('Confirmação de senha obrigatória').oneOf([ref('password')], 'As senhas devem ser iguais'),
});

type ChangePassword = InferType<typeof changePasswordSchema>

interface ChangePasswordModalProps {
  show: boolean;
  setOpen: (value: boolean) => void;
  associateId: string;
}

export default function ChangePasswordModal({
  associateId,
  show,
  setOpen,
}: ChangePasswordModalProps) {
  const { changeAssociatePasswordCallback, errorChangeAssociatePassword } = useUserContext();

  const {
    setValue, formState: { errors }, handleSubmit, reset,
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const onChange = (event: any) => {
    const { value, name } = event.currentTarget;
    setValue(name, value);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmitHandler = (data: ChangePassword) => {
    changeAssociatePasswordCallback(
      {
        associateId,
        password: data.password,
      },
      () => {
        toast.success('Senha alterada com sucesso!!');
        reset();
        handleCloseModal();
      },
    );
  };

  useEffect(() => {
    if (errorChangeAssociatePassword) {
      toast.error('Erro ao alterar senha');
    }
  }, [errorChangeAssociatePassword]);
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:ml-20 sm:p-4 sm:mt-8 md:p-0 md:mt-0">
          <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[500px] sm:p-4">
                <div>
                  <div className="flex min-h-full flex-1 flex-col justify-center  lg:px-4">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center" />
                    <div className="mt-6 sm:mx-auto sm:w-full">
                      <h3 className="font-semibold text-center text-3xl pl-5 text-gray-700">
                        Alterar Senha
                      </h3>
                      <div className="bg-white px-6 pb-8">
                        <form
                          className=" mt-6"
                          onSubmit={handleSubmit(
                            onSubmitHandler,
                          )}
                        >
                          <div className="grid sm:grid-cols-1 md:grid-cols-1 gap-5">
                            <Input
                              label="Senha"
                              name="password"
                              type="password"
                              onChange={onChange}
                              errorMessage={errors.password?.message}
                            />
                            <Input
                              label="Confirmação de Senha"
                              name="confirm_password"
                              type="password"
                              onChange={onChange}
                              errorMessage={errors.confirm_password?.message}
                            />
                          </div>
                          <div>
                            <div>
                              <div className="flex justify-center gap-4">
                                <Button
                                  label="Cancelar"
                                  type="button"
                                  customClass="bg-rose-500 sm:w-full md:w-32 hover:bg-rose-400 mt-10"
                                  onClick={handleCloseModal}
                                />
                                <Button
                                  label="Salvar"
                                  type="submit"
                                  customClass="bg-primary-700 sm:w-full md:w-32 hover:bg-primary-500 mt-10"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Button';
import { ToggleButton } from 'components/ToggleButton';
import { Permission } from 'interfaces';
import React, { Fragment } from 'react';

interface ModalPermissionsProps {
  setOpen: (value: boolean) => void
  show: boolean
  permissions: Permission
  setPermissions: React.Dispatch<React.SetStateAction<Permission>>
  savePermission: (permissions: Permission, callback: () => void) => void
  user: any
}

export function ModalPermissions({
  setOpen, show, permissions, setPermissions, savePermission, user,
}: ModalPermissionsProps) {
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:ml-20 sm:p-4 sm:mt-8 md:p-0 md:mt-0">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[1046px] sm:p-4">
                <div className="text-left">
                  <div className="rounded-md p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white">
                    <div className="flex gap-2 justify-between">
                      <h3 className="font-semibold text-3xl text-gray-700">Permissões</h3>
                      <div>
                        <XMarkIcon className="w-6 text-gray-700 cursor-pointer" onClick={handleCloseModal} />
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:gap-4 md:gap-x-16 mt-10">
                      <ToggleButton
                        label="Adicionar/Editar Clientes"
                        defaultValue={permissions?.permissions.gerenciamento_clientes}
                        customClass="justify-between"
                        yesNoLabelActive
                        callback={(value: boolean) => setPermissions((prevstate) => ({
                          permissions: {
                            ...prevstate.permissions,
                            gerenciamento_clientes: value,
                          },
                        }))}
                      />
                      <ToggleButton
                        label="Adicionar/Editar Afiliados"
                        defaultValue={permissions?.permissions.gerenciamento_afiliados}
                        customClass="justify-between"
                        yesNoLabelActive
                        callback={(value: boolean) => setPermissions((prevstate) => ({
                          permissions: {
                            ...prevstate.permissions,
                            gerenciamento_afiliados: value,
                          },
                        }))}
                      />
                    </div>
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:gap-4 md:gap-x-16  mt-4">
                      <ToggleButton
                        label="Adicionar/Editar Campanhas"
                        defaultValue={permissions?.permissions.gerenciamento_campanhas}
                        customClass="justify-between"
                        yesNoLabelActive
                        callback={(value: boolean) => setPermissions((prevstate) => ({
                          permissions: {
                            ...prevstate.permissions,
                            gerenciamento_campanhas: value,
                          },
                        }))}
                      />
                      <ToggleButton
                        label="Permitir o controle do Pixel para campanhas"
                        defaultValue={permissions?.permissions.pixel}
                        customClass="justify-between"
                        yesNoLabelActive
                        callback={(value: boolean) => setPermissions((prevstate) => ({
                          permissions: {
                            ...prevstate.permissions,
                            pixel: value,
                          },
                        }))}
                      />
                    </div>
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:gap-4 md:gap-x-16  mt-4">
                      <ToggleButton
                        label="Visualizar Relatório Financeiro"
                        defaultValue={permissions?.permissions.relatorio_financeiro}
                        customClass="justify-between"
                        yesNoLabelActive
                        callback={(value: boolean) => setPermissions((prevstate) => ({
                          permissions: {
                            ...prevstate.permissions,
                            relatorio_financeiro: value,
                          },
                        }))}
                      />
                      <ToggleButton
                        label="Visualizar Relatório de Afiliados"
                        defaultValue={permissions?.permissions.relatorio_afiliados}
                        customClass="justify-between"
                        yesNoLabelActive
                        callback={(value: boolean) => setPermissions((prevstate) => ({
                          permissions: {
                            ...prevstate.permissions,
                            relatorio_afiliados: value,
                          },
                        }))}
                      />
                    </div>

                    <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:gap-4 md:gap-x-16  mt-4">
                      <ToggleButton
                        label="Visualizar Métricas"
                        defaultValue={permissions?.permissions.metricas}
                        customClass="justify-between"
                        yesNoLabelActive
                        callback={(value: boolean) => setPermissions((prevstate) => ({
                          permissions: {
                            ...prevstate.permissions,
                            metricas: value,
                          },
                        }))}
                      />
                      <ToggleButton
                        label="Permitir o controle de credenciais Pix"
                        defaultValue={permissions?.permissions.credenciais_pix}
                        customClass="justify-between"
                        yesNoLabelActive
                        callback={(value: boolean) => setPermissions((prevstate) => ({
                          permissions: {
                            ...prevstate.permissions,
                            credenciais_pix: value,
                          },
                        }))}
                      />
                    </div>

                    <div className="flex gap-4 ">
                      <Button label="Cancelar" customClass="bg-rose-500 sm:w-full md:w-32 hover:bg-rose-400 mt-10" onClick={handleCloseModal} />
                      <Button
                        label="Salvar"
                        customClass="bg-primary-700 sm:w-full md:w-32 hover:bg-primary-500 mt-10"
                        onClick={() => {
                          console.log(permissions);
                          savePermission({
                            permissions: {
                              ...permissions.permissions,
                              user_id: user.ID,
                            },
                          }, () => handleCloseModal());
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

// eslint-disable-next-line import/no-extraneous-dependencies
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale/pt-BR';

registerLocale('ptBR', ptBR);
interface DateRangepickerProps extends ReactDatePickerProps {
    label?: string
    errorMessage: string | undefined
    containerClass?: string,
    onChange: (data:any) => void
}
function DateRangepicker({
  label = '',
  onChange,
  errorMessage,
  containerClass,
  startDate,
  endDate,
}: DateRangepickerProps) {
  return (
    <div className={`w-full ${containerClass}`}>
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-600"
      >
        {label}
      </label>
      <div>
        <DatePicker
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          isClearable
          locale="ptBR"
          className="block rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
        />
        {errorMessage && <p className="text-rose-500 mt-0">{errorMessage}</p> }
      </div>
    </div>
  );
}
DateRangepicker.defaultProps = {
  label: '',
  containerClass: '',
};

export { DateRangepicker };

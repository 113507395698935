import {
  object, string,
} from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Input } from 'components/Input';
import { CheckBox } from 'components/CheckBox';
import { Button } from 'components/Button';
import { useUserContext } from 'context/GlobalContext';
import { getToken, isAuthenticated } from 'services/localstorageService';
import { useNavigate } from 'react-router-dom';
import { PhoneInput } from '../../../components/PhoneInput';
import { getUserData } from '../../../utils';

const loginSchema = object({
  phone: string().required('Invalid phone'),
  password: string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
});
export default function UserLogin() {
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(false);
  const { loginByPhoneCallback } = useUserContext();
  const {
    setValue, formState: { errors }, handleSubmit, reset, watch,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } : any = event.currentTarget;
    setValue(name, value);
  };

  const onSubmitHandler = (data: any) => {
    loginByPhoneCallback(data, setIsLogged);
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      const user = getUserData();
      if (user) {
        if (user.role === 'User') {
          navigate('/campaign');
        }
      }
    }
  }, [getToken(), isLogged]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center  py-12 sm:px-4 lg:px-8 bg-primary-900">
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[650px]">
        <div className="bg-white md:px-12 py-12 shadow sm:rounded-lg sm:px-4">
          <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit(onSubmitHandler)}>
            <PhoneInput label="Telefone" name="phone" value={watch('phone') || ''} onChange={onChange} errorMessage={errors.phone?.message} />
            <Input label="Senha" name="password" type="password" onChange={onChange} errorMessage={errors.password?.message} />
            <div className="flex items-center justify-center">
              <CheckBox label="Lembrar da conta" name="remember-me" />
            </div>
            <div>
              <Button label="Continuar" type="submit" customClass="bg-primary-400 hover:bg-primary-500 w-full font-medium" />
            </div>
            <div>
              <Button label="Cadastre-se" type="button" customClass="bg-primary-400 hover:bg-primary-500 w-full font-medium" onClick={() => navigate('/signup/user')} />
            </div>
          </form>

          <div>
            <div className="relative mt-10">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm leading-6 hidden">
                <span className="bg-white px-2 text-gray-500">
                  <a className="text-gray-600 px-1 font-normal" href="/signup">Esqueceu sua senha?</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import {
  object, string,
} from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PinInput } from 'components/PinInput';
import { Input } from '../components/Input';
import { CheckBox } from '../components/CheckBox';
import { Button } from '../components/Button';
import logo from '../assets/img/Header.png';
import { useUserContext } from '../context/GlobalContext';
import { getToken, isAuthenticated } from '../services/localstorageService';
import Loading from '../components/Loading';
import { getUserData } from '../utils';
import { PhoneInput } from '../components/PhoneInput';
import { Modal } from '../components/Modal';

const loginSchema = object({
  password: string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  phone_number: string().required('Invalid phone'),
});

export default function Login() {
  const [isLogged, setIsLogged] = useState(false);
  const {
    loginCallback, errorLogin, loginLoading, setErrorLogin,
  } = useUserContext();
  const [pinCodeModalOpen, setPinCodeModalOpen] = useState<boolean>(false);
  const [pinValue, setPinValue] = useState<string>();
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    setValue, formState: { errors }, handleSubmit, reset, watch,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } : any = event.currentTarget;
    setValue(name, value);
  };

  const onSubmitHandler = (data: any) => {
    loginCallback(data, setIsLogged);
  };

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      const user = getUserData();
      if (user?.roles === 'Admin' || user?.roles === 'Afiliado' || user?.roles === 'Manager' || user?.roles === 'Client') {
        navigate('/app');
      }
    }
  }, [getToken(), isLogged]);

  const handlePin = () => {
    if (pinValue === undefined) setRequiredError(true);
    setPinCodeModalOpen(false);
    setErrorLogin(null);
    loginCallback({
      phone_number: watch('phone_number'),
      verification_code: pinValue,
      password: watch('password'),
    }, setIsLogged);
  };

  const loginErrors: Record<string, () => void> = {
    'blocked user': () => {
      toast.error('Usuário bloqueado');
    },
    'invalid credentials': () => {
      toast.error('Usuário/Senha incorreto');
    },
    'Código de verificação inválido': () => {
      toast.error('Código de verificação inválido');
      setPinCodeModalOpen(true);
    },
    'informe o código de verificação': () => {
      setPinCodeModalOpen(true);
    },
  };

  useEffect(() => {
    if (errorLogin) {
      const errorMessage = errorLogin as any as string;
      const errorHandler = loginErrors[errorMessage];
      if (errorHandler === undefined) toast.error('Ops!! erro inesperado, contate o administrador');
      else errorHandler();
    }
  }, [errorLogin]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center  py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center">
        <img src={logo} className="sm h-16 md:h-32" alt="logo" />
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[650px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg">
          <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit(onSubmitHandler)}>
            <PhoneInput label="Telefone" name="phone_number" value={watch('phone_number') || ''} onChange={onChange} errorMessage={errors.phone_number?.message} />
            <Input label="Password" name="password" type="password" onChange={onChange} errorMessage={errors.password?.message} />
            <div className="flex items-center justify-between">
              <CheckBox label="Lembrar da conta" name="remember-me" />
              <div className="text-sm leading-6">
                <Link
                  to="/recuperar-senha"
                  className="text-gray-900 hover:text-indigo-500"
                >
                  Trocar senha
                </Link>
              </div>
            </div>

            <div>
              <Button label="Login" type="submit" customClass="bg-primary-700 hover:bg-primary-500 w-full" />
            </div>
          </form>

          <div className="hidden">
            <div className="relative mt-10">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm leading-6">
                <span className="bg-white px-2 text-gray-500">
                  Não tem conta ainda?
                  <a className="text-gray-600 px-1 font-medium" href="/signup">Registrar-se</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading open={loginLoading} />
      <Modal open={pinCodeModalOpen} setOpen={setPinCodeModalOpen}>
        <div className="w-full justify-start mb-4">
          <h1 className="font-bold text-2xl mb-4 text-left">Código de Verificação</h1>
          <span>
            Digite o código do seu autenticador
          </span>
        </div>

        <PinInput setValue={setPinValue} callback={handlePin} />
        {requiredError && <span>Campo obrigatório</span>}
        <Button customClass="w-full mt-4" label="Enviar" onClick={handlePin} />
      </Modal>
    </div>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Button } from './Button';

interface QuestionDialogProps {
  title: string
  open: boolean
  setOpen: (value: boolean) => void
  yesCallback: () => void
}
export function QuestionDialog({
  open, setOpen, title, yesCallback,
}: QuestionDialogProps) {
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:ml-20 sm:p-4 sm:mt-8 md:p-0 md:mt-0">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[400px] sm:p-4">
                <div className="text-left">
                  <div className="rounded-md p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white">
                    <div className="flex gap-2 justify-between">
                      <div className="flex justify-center items-center gap-3">
                        <h2 className="text-gray-800 font-semibold">
                          {title}
                        </h2>
                      </div>
                      <div>
                        <XMarkIcon
                          className="w-6 text-gray-700 cursor-pointer"
                          onClick={handleCloseModal}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 justify-center">
                      <Button
                        label="Não"
                        customClass=" bg-rose-500 sm:w-full md:w-32 hover:bg-rose-400 mt-10"
                        onClick={handleCloseModal}
                      />
                      <Button
                        label="Sim"
                        customClass="bg-primary-700 sm:w-full md:w-32 hover:bg-primary-500 mt-10"
                        onClick={() => {
                          yesCallback();
                          handleCloseModal();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

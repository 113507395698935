import React, {
  createContext, type ReactNode, useCallback, useContext, useMemo, useState,
} from 'react';
import { v4 as uuidV4 } from 'uuid';
import {
  AssociateCampaignData,
  AwardCampaign,
  AwardForceProcessData,
  Campaign, CampaignDetails, ListAssociateCampaign, Promo, PromoData, TopPurchases,
} from '../interfaces';
import usePost from '../hooks/usePost';
import useGet from '../hooks/useGet';

interface CampaignsContextData {
    newCampaignCallback: (
        data: any,
        addAwards: (campaignId: string) => void
    ) => void
    getCampaignsCallback: () => void
    getCampaignsDetailsCallback: () => void
    getCampaignDetailCallback: (shareId: string) => void
    getCampaignBySharedId: (sharedId: string) => void
    updateCampaignStatusCallback: (
        campaingId: string,
        status: string,
        callback: () => void
    ) => void
    associateCampaignCallback: (data: AssociateCampaignData, callback: () => void) => void
    newPromotionCallback: (data: Promo[]) => void
    deletePromotionCallback: (promoId: string, data: Promo[]) => void
    updatePromotionCallback: (data: Promo[]) => void
    listPromotionCallback: (campaignId: string) => void
    listAssociateCampaignCallback: (
      userId: string, callback: (response: ListAssociateCampaign) => void) => void
    listAwardsByCampaignCallback: (data: {
      campaignId: string,
      page: number,
      total?: number,
      isAdminPanel?: boolean
    },
      callback: (response: AwardCampaign) => void) => void
    awardForceProcessCallback: (data: AwardForceProcessData, callback: () => void) => void
    awardsCampaign?: AwardCampaign
    getCampaigns: Campaign[]
    getCampaignsDetails: CampaignDetails[]
    getCampaignDetails: CampaignDetails[]
    campaignBySharedId: Campaign | undefined
    promos?: Promo[]
    promotions?: Promo[]
    setPromotions: React.Dispatch<React.SetStateAction<Promo[] | undefined>>
    newCampaignLoading: boolean
    campaignBySharedIdLoading: boolean
    errorGetCampaigns: Error | null
    errorNewCampaign: Error | null
    errorCampaignBySharedId: Error | null
    errorAssociateCampaign: Error | null
    errorListAssociateCampaign: Error | null
    errorGetAwardsByCampaignId: Error | null
    errorAwardForceProcess: Error | null
    errorDeletePromotion: Error | null
    errorNewPromotion: Error | null
    errorUpdatePromotion: Error | null
    errorListPromotion: Error | null
    getPurchaseTop5Details?: TopPurchases
    getPurchaseTop5Callback: (shareId: string) => void
    errorGetPurchasedTop5: Error | null
    errorUpdateCampaignStatus: Error | null
    updateCampaignStatusLoading: boolean
    getTopPurchaseTop5Loading: boolean
    associateCampaignLoading: boolean
    listAssociateCampaignLoading: boolean
    awardForceProcessLoading: boolean
    newPromotionLoading: boolean
    deletePromotionLoading: boolean
    updatePromotionLoading: boolean
    listPromotionLoading: boolean
    getAwardsByCampaignIdLoading: boolean
}

const CampaignsContext = createContext<CampaignsContextData | null>(null);

interface CampaignsProviderProps {
    children: ReactNode
}
export const CampaignsContextProvider: React.FC<CampaignsProviderProps> = ({ children }) => {
  const [getCampaigns, setCampaigns] = useState([]);
  const [getCampaignsDetails, setCampaignsDetails] = useState([]);
  const [getCampaignDetails, setCampaignDetails] = useState([]);
  const [getPurchaseTop5Details, setPurchaseTop5Details] = useState<TopPurchases>();
  const [campaignBySharedId, setCampaignBySharedId] = useState<Campaign>();
  const [promos, setPromos] = useState<Promo[]>();
  const [promotions, setPromotions] = useState<Promo[]>();
  const [awardsCampaign, setAwardsCampaign] = useState<AwardCampaign>();
  const { request: getCampaignsRequest, error: errorGetCampaigns } = useGet();
  const {
    request: getAwardsByCampaignIdRequest,
    loading: getAwardsByCampaignIdLoading,
    error: errorGetAwardsByCampaignId,
  } = useGet();
  const {
    request: newCampaignRequest,
    error: errorNewCampaign,
    loading: newCampaignLoading,
  } = usePost();
  const {
    request: campaignRequest,
    error: errorCampaignBySharedId,
    loading: campaignBySharedIdLoading,
  } = useGet();

  const {
    request: listAssociateCampaignRequest,
    error: errorListAssociateCampaign,
    loading: listAssociateCampaignLoading,
  } = useGet();

  const {
    request: getCampaignDetailsRequest,
  } = useGet();

  const {
    request: getPurchaseTop5Request,
    error: errorGetPurchasedTop5,
    loading: getTopPurchaseTop5Loading,
  } = useGet();

  const {
    request: updateCampaignStatusRequest,
    error: errorUpdateCampaignStatus,
    loading: updateCampaignStatusLoading,
  } = usePost();

  const {
    request: associateCampaignRequest,
    error: errorAssociateCampaign,
    loading: associateCampaignLoading,
  } = usePost();

  const {
    request: awardForceProcessRequest,
    error: errorAwardForceProcess,
    loading: awardForceProcessLoading,
  } = usePost();

  const {
    request: newPromotionRequest,
    error: errorNewPromotion,
    loading: newPromotionLoading,
  } = usePost();

  const {
    request: deletePromotionRequest,
    error: errorDeletePromotion,
    loading: deletePromotionLoading,
  } = usePost();

  const {
    request: updatePromotionRequest,
    error: errorUpdatePromotion,
    loading: updatePromotionLoading,
  } = usePost();

  const {
    request: listPromotionRequest,
    error: errorListPromotion,
    loading: listPromotionLoading,
  } = usePost();

  const newCampaignCallback = useCallback((
    data: any,
    addAward: (campaignId: string) => void,
  ) => {
    newCampaignRequest('rifa/register', data, (response) => { addAward(response.id); });
  }, [newCampaignRequest]);

  const getCampaignsCallback = useCallback(() => {
    getCampaignsRequest('rifa/list', (response) => { setCampaigns(response); });
  }, [getCampaignsRequest]);

  const getCampaignsDetailsCallback = useCallback(() => {
    getCampaignsRequest('rifa/details', (response) => { setCampaignsDetails(response); });
  }, [getCampaignsRequest]);

  const getCampaignDetailCallback = useCallback((sharedId:string) => {
    getCampaignDetailsRequest(`rifa/details?rifauuid=${sharedId}`, (response) => { setCampaignDetails(response); });
  }, [getCampaignDetailsRequest]);

  const getCampaignBySharedId = useCallback((sharedId: string) => {
    campaignRequest(`user/find/sharedcampaign/${sharedId}`, (response) => {
      setCampaignBySharedId(response.rifa);
      setPromos(response.promotions);
    });
  }, [campaignRequest]);

  const getPurchaseTop5Callback = useCallback((sharedId:string) => {
    getPurchaseTop5Request(`user/find/purchases/ranking?rifauuid=${sharedId}&limit=5`, (response) => { setPurchaseTop5Details(response); });
  }, [getPurchaseTop5Request]);

  const updateCampaignStatusCallback = useCallback(
    (campaingId: string, status: string, callback: () => void) => {
      updateCampaignStatusRequest(
        `rifa/update/${campaingId}/status/${status}`,
        {},
        () => {
          callback();
        },
      );
    },
    [updateCampaignStatusRequest],
  );

  const associateCampaignCallback = useCallback(
    (data: AssociateCampaignData, callback: () => void) => {
      associateCampaignRequest('user/register/associate/campaign', data, () => {
        callback();
      });
    },
    [associateCampaignRequest],
  );

  const listAssociateCampaignCallback = useCallback(
    (userId: string, callback: (response: ListAssociateCampaign) => void) => {
      listAssociateCampaignRequest(`user/register/associate/campaign/list/${userId}`, (response) => callback(response));
    },
    [listAssociateCampaignRequest],
  );

  const listAwardsByCampaignCallback = useCallback((
    {
      campaignId, page, total = 7, isAdminPanel = true,
    }: {
      campaignId: string,
      page: number,
      total?: number,
      isAdminPanel?: boolean
    },
    callback: (response: AwardCampaign) => void,
  ) => {
    getAwardsByCampaignIdRequest(`rifa/award/force/list/${campaignId}/${page}/${total}?isAdminPanel=${isAdminPanel}`, (response) => {
      setAwardsCampaign(response);
      callback(response);
    });
  }, [getAwardsByCampaignIdRequest]);

  const awardForceProcessCallback = useCallback((
    data: AwardForceProcessData,
    callback: () => void,
  ) => {
    awardForceProcessRequest('rifa/award/force/process', data, () => {
      callback();
    });
  }, [awardForceProcessRequest]);

  const newPromotionCallback = useCallback((data: Promo[]) => {
    if (data.length > 0) {
      const body = data.map((value) => ({
        ...value,
        valor: Number(value.valor.toString().replace(',', '.')),
      }));
      newPromotionRequest('rifa/promotion/register', body, () => {

      });
    }
  }, [newPromotionRequest]);

  const deletePromotionCallback = useCallback((promoId: string, data: Promo[]) => {
    deletePromotionRequest(`rifa/promotion/delete/${promoId}`, {}, () => {
      console.log(data);
    });
  }, [deletePromotionRequest]);

  const updatePromotionCallback = useCallback((data: Promo[]) => {
    if (data.length > 0) {
      const body = data.map((value) => ({
        ...value,
        valor: Number(value.valor.toString().replace(',', '.')),
      }));
      console.log(body);
      updatePromotionRequest('rifa/promotion/update', body, () => {
        console.log(body);
      });
    }
  }, [updatePromotionRequest]);

  const listPromotionCallback = useCallback((campaignId: string) => {
    listPromotionRequest(`rifa/promotion/list/${campaignId}`, {}, (response: PromoData) => {
      if (response && response.promotions.length === 0) {
        setPromotions([
          {
            id: uuidV4(),
            quantidade: 0,
            valor: '0',
            deleted: false,
            rifa_id: campaignId,
            updated: false,
            new: true,
          },
        ]);
        return;
      }
      setPromotions(response.promotions.map((prom) => ({
        ...prom,
        updated: true,
        deleted: false,
        new: false,
      })));
    });
  }, [listPromotionRequest]);

  const providerValue = useMemo(
    () => ({
      newCampaignCallback,
      getCampaignsCallback,
      getCampaignsDetailsCallback,
      getCampaignBySharedId,
      updateCampaignStatusCallback,
      associateCampaignCallback,
      listAssociateCampaignCallback,
      listAwardsByCampaignCallback,
      updatePromotionCallback,
      newPromotionCallback,
      deletePromotionCallback,
      listPromotionCallback,
      awardsCampaign,
      deletePromotionLoading,
      errorUpdateCampaignStatus,
      errorGetAwardsByCampaignId,
      errorAwardForceProcess,
      updateCampaignStatusLoading,
      awardForceProcessCallback,
      getCampaigns,
      setPromotions,
      getCampaignsDetails,
      getPurchaseTop5Details,
      campaignBySharedId,
      promos,
      promotions,
      newCampaignLoading,
      campaignBySharedIdLoading,
      errorGetCampaigns,
      errorNewCampaign,
      errorDeletePromotion,
      errorUpdatePromotion,
      errorListPromotion,
      errorCampaignBySharedId,
      errorListAssociateCampaign,
      errorAssociateCampaign,
      getPurchaseTop5Callback,
      associateCampaignLoading,
      errorGetPurchasedTop5,
      errorNewPromotion,
      newPromotionLoading,
      getTopPurchaseTop5Loading,
      awardForceProcessLoading,
      getCampaignDetailCallback,
      listPromotionLoading,
      updatePromotionLoading,
      listAssociateCampaignLoading,
      getCampaignDetails,
      getAwardsByCampaignIdLoading,
    }),
    [
      getCampaigns,
      getCampaignsDetails,
      errorUpdateCampaignStatus,
      campaignBySharedId,
      errorGetAwardsByCampaignId,
      errorAwardForceProcess,
      errorUpdatePromotion,
      awardsCampaign,
      promos,
      promotions,
      newCampaignLoading,
      campaignBySharedIdLoading,
      errorGetCampaigns,
      errorNewCampaign,
      errorNewPromotion,
      newPromotionLoading,
      deletePromotionLoading,
      errorDeletePromotion,
      errorCampaignBySharedId,
      errorListPromotion,
      getPurchaseTop5Details,
      setPromotions,
      errorGetPurchasedTop5,
      errorListAssociateCampaign,
      getTopPurchaseTop5Loading,
      updateCampaignStatusLoading,
      listPromotionLoading,
      associateCampaignLoading,
      getCampaignDetails,
      listAssociateCampaignLoading,
      awardForceProcessLoading,
      updatePromotionLoading,
      errorAssociateCampaign,
      getAwardsByCampaignIdLoading,
    ],
  );
  return (
    <CampaignsContext.Provider
      value={providerValue}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export const useCampaignsContext = (): CampaignsContextData => {
  const context = useContext(CampaignsContext);

  if (context === null) {
    throw new Error('useCampaignsContext deve ser usado dentro de um CampaignsContextProvider');
  }

  return context;
};

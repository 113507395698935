import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Input } from 'components/Input';
import { PhoneInput } from 'components/PhoneInput';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Campaign } from 'interfaces';
import avatar from '../../../assets/img/Avatar.svg';
import { Button } from '../../../components/Button';
import { handleFormData } from '../../../utils';

interface ModalProps{
  toggleModal: boolean,
  setOpen: (value:boolean) => void
  selectedUser: any,
  setSelectedUser : (value:any) => void
  saveSelectedUser: () => void
  campaigns: Campaign[]
  selectedCampaign: Campaign[] | null
  setSelectedCampaign: React.Dispatch<React.SetStateAction<Campaign[] | null>>
}

function Modal({
  toggleModal,
  setOpen,
  selectedUser: user,
  setSelectedUser, saveSelectedUser, campaigns, selectedCampaign, setSelectedCampaign,
}:ModalProps) {
  const handleCloseModal = () => {
    setOpen(false);
  };

  const campaignTemplate = (option: Campaign) => (
    <div className="flex align-items-center">
      <img alt={option.titulo} src={option.imagem} className="mr-2 w-32" />
      <div className="flex flex-col">
        <span className="font-bold">{option.titulo}</span>
        <span className="flex flex-wrap w-0.5">{option.descricao}</span>
      </div>
    </div>
  );
  return (
    <Transition.Root show={toggleModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:ml-20 sm:p-4 sm:mt-8 md:p-0 md:mt-0">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[1046px] sm:p-4">
                <div className="text-left">
                  <div className="rounded-md p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white">
                    <div className="flex gap-2 justify-end">
                      <div>
                        <XMarkIcon className="w-6 text-gray-700 cursor-pointer" onClick={handleCloseModal} />
                      </div>
                    </div>
                    <div className="mt-10">
                      <p className="text-sm text-gray-700 font-medium">Foto</p>
                      <div className="flex gap-4">
                        <img src={avatar} className="w-10" alt="avatar" />
                        <Button label="Alterar" customClass="border bg-transparent !text-gray-700 hover:bg-primary-500 hover:!text-white" />
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:gap-4 md:gap-x-16  mt-4">
                      <Input
                        label="Nome"
                        name="name"
                        value={user?.name}
                        onChange={(e) => handleFormData(e, setSelectedUser)}
                        errorMessage=""
                      />
                      <Input
                        label="Sobrenome"
                        name="last_name"
                        value={user?.last_name}
                        onChange={(e) => handleFormData(e, setSelectedUser)}
                        errorMessage=""
                      />
                    </div>
                    <div className="mt-4 gap-4 flex flex-col">
                      <Input
                        label="Email"
                        name="email"
                        value={user?.email}
                        onChange={(e) => handleFormData(e, setSelectedUser)}
                        errorMessage=""
                      />
                      <PhoneInput
                        label="Telefone"
                        name="phone_number"
                        defaultValue={user?.phone_number}
                        onChange={(e) => handleFormData(e, setSelectedUser)}
                        errorMessage=""
                      />
                      <Input
                        label="Porcentagem de lucro"
                        name="porcentagem"
                        type="number"
                        value={user?.porcentagem}
                        onChange={(e) => handleFormData(e, setSelectedUser)}
                        errorMessage=""
                        customClass="w-28 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      />
                    </div>
                    <div className="mt-4 gap-4 flex flex-col">
                      <MultiSelect
                        value={selectedCampaign}
                        onChange={(e: MultiSelectChangeEvent) => setSelectedCampaign(e.value)}
                        options={campaigns}
                        optionLabel="titulo"
                        filter
                        itemTemplate={campaignTemplate}
                        placeholder="Selecione as Campanhas"
                        className="w-full md:w-20rem border rounded-md ring-gray-300"
                      />
                    </div>
                    <div className="flex gap-4 ">
                      <Button label="Cancelar" customClass="bg-rose-500 sm:w-full md:w-32 hover:bg-rose-400 mt-10" onClick={handleCloseModal} />
                      <Button
                        label="Salvar"
                        customClass="bg-primary-700 sm:w-full md:w-32 hover:bg-primary-500 mt-10"
                        onClick={() => {
                          saveSelectedUser();
                          handleCloseModal();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { Modal };

import React, { SelectHTMLAttributes } from 'react';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    label?: string;
    customClass?:string;
    options?: any[]
    errorMessage?: string | undefined,
}

function Select({
  label = '',
  name,
  id,
  onChange,
  value,
  required,
  customClass = '',
  options,
  errorMessage,
}: SelectProps) {
  return (

    <div className="mt-1 w-full">
      <label className="block text-sm font-medium leading-6 text-gray-600" htmlFor={id}>
        {label}
      </label>
      <select
        value={value}
        name={name}
        id={id}
        className={` ${customClass} block rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm`}
        onChange={onChange}
        required={required}
      >
        {options && options.map((option: any) => (
          <option key={option.value} value={option.value}>{option.name}</option>
        ))}
      </select>
      {errorMessage && <p className="text-red-500 mt-1">{errorMessage}</p>}
    </div>

  );
}

Select.defaultProps = {
  label: '',
  customClass: '',
  options: [],
  errorMessage: '',
};

export { Select };

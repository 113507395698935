/* eslint-disable max-len */
/* eslint-disable consistent-return */
import { Awards } from 'interfaces';
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import coinImg from '../assets/img/coin.gif';

interface InfiniteScrollComponentProps {
  fetchData: () => void;
  items: Awards[]
  hasMore: boolean
}

const InfiniteScrollComponent: React.FC<InfiniteScrollComponentProps> = ({
  fetchData, items, hasMore,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const loadMoreItems = useCallback(async () => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);
    try {
      fetchData();
    } catch (error) {
      console.error('Erro ao carregar mais itens:', error);
    }
    setIsLoading(false);
  }, [isLoading, hasMore, fetchData]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight) {
      loadMoreItems();
    }
  }, [loadMoreItems]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  return (
    <div ref={containerRef} className="w-full" style={{ height: '400px', overflowY: 'auto' }}>
      <div
        className="grid-cols-2 grid gap-8 w-full mt-4"
      >
        {
                                    items.map((cota) => (
                                      <div
                                        key={cota.id}
                                        className="flex flex-col items-center"
                                      >
                                        <p
                                          className={`${cota.user_name ? 'bg-yellow-500' : 'bg-green-500'} bg-gren-700 w-full rounded-full px-1 py-2 border border-green-900 flex flex-col`}
                                        >
                                          {' '}
                                          <span className="text-sm text-white font-semibold">
                                            N°
                                            {' '}
                                            {cota.number}
                                          </span>
                                          {' '}
                                          <span className="text-sm text-white">{cota.description}</span>
                                        </p>
                                        <div className="text-white">
                                          {cota.user_name
                                            ? (
                                              <div className="flex text-xs justify-center items-center">
                                                <span>Ganhador</span>
                                                {' - '}
                                                {cota.user_name}
                                                {' '}
                                                <img src={coinImg} className="size-3 block rounded-full" alt="" />
                                              </div>
                                            ) : (
                                              <div className="flex text-sm items-center gap-4">
                                                <span>Disponível</span>
                                                <span className="size-3 block rounded-full bg-green-500" />
                                              </div>
                                            )}

                                        </div>
                                      </div>

                                    ))
                          }
      </div>
      {isLoading && <p>Carregando...</p>}
    </div>
  );
};

export default InfiniteScrollComponent;

/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { PhoneInput } from 'components/PhoneInput';
import {
  FireIcon, ArrowRightIcon,
} from '@heroicons/react/24/outline';
import { TextArea } from 'components/TextArea';
import { Select } from 'components/Select';
import {
  boolean,
  date, InferType, number, object, string,
} from 'yup';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { CheckBox } from 'components/CheckBox';
import { getUserData, padNumber } from 'utils';
import { useUserContext } from 'context/GlobalContext';
import { Modal } from 'components/Modal';
import { useCampaignsContext } from 'context/CampaignsContext';
import { v4 as uuidV4 } from 'uuid';
import { formatValue } from 'components/Currency';
import { FileInput } from '../../../components/FileInput';
import { ModalEditPrizes } from '../Components/ModalEditPrizes';
import { ModalEditPromos } from '../Components/ModalEditPromos';
import { PromotionsAndAwards } from '../../Affiliates/Components/PromotionsAndAwards';
import {
  AwardWinner,
  Campaign, Cota,
} from '../../../interfaces';
import useGet from '../../../hooks/useGet';
import usePost from '../../../hooks/usePost';
import Loading from '../../../components/Loading';
import { PrizesData } from '../Components/AutomaticPrizes';
import { QuestionDialog } from '../../../components/QuestionDialog';

const campaignSchema = object({
  titulo: string().required('Nome da rifa é obrigatorio'),
  descricao: string().required('Digite uma descrição valida'),
  quantidade_numeros: number().required('Quantidade invalida').default(1000),
  preco_por_numero: string().required('Preço invalido'),
  data_inicio: date().required('Digite o inicio do sorteio'),
  data_encerramento: string().nullable('Digite o inicio do sorteio'),
  data_sorteio: string().nullable('Digite o inicio do sorteio'),
  status: string().required('Defina o status do sorteio').default('CRIADA'),
  celular_suporte: string().required('O telefone não é valido'),
  qntd_min: number().nullable('Quantidade minima de bilhetes não é válido'),
  mostrar_top: string().required('Defina uma opção').default('NONE'),
  mostrar_porcentagem_vendas: boolean().default(false),
  pixel_id: string(),
  pix_client_id: string(),
  pix_secret_id: string(),
  chave_pix: string(),
  titulo_pagina: string(),
});

const options = [
  {
    value: 1000,
    name: '0-1000',
  },
  {
    value: 10000,
    name: '1000-10.000',
  },
  {
    value: 100000,
    name: '10.000-100.000',
  },
  {
    value: 1000000,
    name: '100.000-1.000.000',
  },
  {
    value: 10000000,
    name: '1.000.000-10.000.000',
  },
];

const status = [
  {
    value: 'CRIADA',
    name: 'Criada',
  },
  {
    value: 'ATIVA',
    name: 'Ativa',
  },
  {
    value: 'CONCLUIDA',
    name: 'Concluida',
  },
];

const topBuyers = [
  {
    value: 'NONE',
    name: 'Não exibir',
  },
  {
    value: 'TOP3',
    name: 'Exibir o Top 3',
  },
  {
    value: 'TOP5',
    name: 'Exibir o Top 5',
  },
];

type CampaignData = InferType<typeof campaignSchema>
const uuidGenerated = uuidV4();

const cotaMock: Cota = {
  id: uuidGenerated,
  description: '',
  name: 'Cota nº 1',
  number: '',
  rifa_id: '7868451527',
  sorteado: false,
  isNew: true,
  isUpdated: false,
  isEditable: true,
};

export default function EditCampaing() {
  const { uuid } = useParams();
  const { request: getCampaign } = useGet();
  const { request: editCampaign } = usePost();
  const [awardNow, setAwardNow] = React.useState(false);
  const [scheduledOpen, setScheduledOpen] = React.useState(false);
  const [campaign, setCampaign] = useState<Campaign>();
  const [image, setImage] = useState<File | null>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
  const [previewLogo, setPreviewLogo] = useState<string | undefined>(undefined);
  const [openPrizes, setOpenPrizes] = useState(false);
  const [openPromos, setOpenPromos] = useState(false);
  const [openCotaPremiada, setOpenCotaPremiada] = useState(false);
  const [selectedCota, setSelectedCota] = useState<Cota>(undefined);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [dateError, setDateError] = useState<string>();
  const [countAutomaticPrizes, setCountAutomaticPrizes] = useState(0);
  const [cotas, setCotas] = useState<Cota[]>([{
    ...cotaMock,
    rifa_id: uuid,
  }]);
  const [loading, setLoading] = useState(false);
  const [activatePixel, setActivatePixel] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const { request: registerAwards, error: awardsError, loading: awardsloading } = usePost();
  const {
    request: registerNewAwards,
    error: awardsNewError,
    loading: awardsNewloading,
  } = usePost();
  const {
    request: listWinnerAwardsRequest,
    error: listWinnerAwardsError,
  } = useGet();
  const navigate = useNavigate();
  const { listPermissionsCallback, permissions } = useUserContext();
  const {
    listAwardsByCampaignCallback,
    awardForceProcessCallback, errorAwardForceProcess,
    newPromotionCallback,
    deletePromotionCallback,
    updatePromotionCallback,
    listPromotionCallback,
    promotions,
    getAwardsByCampaignIdLoading,
  } = useCampaignsContext();

  const {
    setValue, formState: { errors }, handleSubmit, reset, watch,
  } = useForm({
    resolver: yupResolver(campaignSchema),
  });

  const onChange = (event: React.ChangeEvent<
    HTMLInputElement |
    HTMLSelectElement |
    HTMLTextAreaElement>) => {
    const { value, name }: any = event.currentTarget;
    setValue(name, value);
  };

  const dateValue = (value: string | undefined) => {
    if (value) {
      return format(value, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS');
    } return '';
  };

  const adaptCota = (cota: Cota, campaignId: string) => ({
    id: cota.id,
    rifa_id: campaignId,
    name: cota.name,
    description: cota.description,
    number: Number.parseInt(cota.number, 10),
  });

  const isAwardBiggerThanPossibilities = (cota: Cota) => {
    const isAwardBiggerThanPossibility = cota.number > campaign?.quantidade_numeros;
    if (isAwardBiggerThanPossibility) {
      toast.error(`Não é possível concluir a operação, pois o limite de número de cota é ${campaign?.quantidade_numeros}`);
    }
    return isAwardBiggerThanPossibility;
  };

  const handleSaveAward = (cota: Cota) => {
    const adaptedCota = adaptCota(cota, uuid);
    if (isAwardBiggerThanPossibilities(adaptedCota)) return;
    if (cota.isNew) registerAwards('rifa/register/award', [adaptedCota], () => toast.success('Cota registrada com sucesso!'));
    else registerAwards('rifa/update/award', [adaptedCota], () => toast.success('Cota atualizada com sucesso!'));
    setCountAutomaticPrizes((prevState) => prevState + 1);
  };

  const onSubmitHandler = async (data: CampaignData) => {
    const form = new FormData();

    Object.keys(data).forEach((key: string) => {
      if (key === 'preco_por_numero') {
        form.append('preco_por_numero', Number(data[key].toString().replace(',', '.')));
      } else form.append(key, data[key]);
    });
    if (image) {
      form.append('file', image);
    }
    if (logo) {
      form.append('logo', logo);
    }

    if (previewLogo) {
      form.append('deleted_image', false);
    } else {
      form.append('deleted_image', true);
    }
    if (!activatePixel) form.set('pixel_id', '');
    editCampaign(`rifa/update/${uuid}`, form, () => toast.success('Alterado com sucesso'));

    navigate('/app');
  };

  useEffect(() => {
    if (awardsNewError) {
      toast.error('Erro ao gerar cotas automáticas, entre em contato com o administrador');
    }
  }, [awardsNewError, countAutomaticPrizes]);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (awardsError || listWinnerAwardsError) {
      toast.error(`${awardsError || listWinnerAwardsError}`);
    }
  }, [awardsError, listWinnerAwardsError, countAutomaticPrizes]);

  useEffect(() => {
    const data = getUserData();
    listPermissionsCallback(data.id, () => { });
  }, []);

  useEffect(() => {
    if (errorAwardForceProcess) toast.error(errorAwardForceProcess);
  }, [errorAwardForceProcess]);

  useEffect(() => {
    setLoading(true);
    getCampaign(`rifa/list/${uuid}`, (response) => {
      const { rifa } = response;
      setCampaign(rifa);
      setActivatePixel(!!rifa.pixel_id);
      setValue('data_inicio', dateValue(rifa.data_inicio));
      setValue('quantidade_numeros', rifa.quantidade_numeros);
      setValue('qntd_min', rifa.qntd_min);
      setValue('celular_suporte', rifa.celular_suporte);
      setValue('titulo', rifa.titulo);
      setValue('descricao', rifa.descricao);
      setValue('preco_por_numero', rifa.preco_por_numero.toString().replace('.', ','));
      setValue('status', rifa.status);
      setValue('mostrar_top', rifa.mostrar_top);
      setValue('data_sorteio', rifa.data_sorteio ? dateValue(rifa.data_sorteio) : '');
      setValue('data_encerramento', rifa.data_encerramento ? dateValue(rifa.data_encerramento) : '');
      setValue('mostrar_porcentagem_vendas', rifa.mostrar_porcentagem_vendas);
      setValue('pixel_id', rifa.pixel_id);
      setValue('pix_client_id', rifa.pix_client_id);
      setValue('pix_secret_id', rifa.pix_secret_id);
      setValue('chave_pix', rifa.chave_pix);
      setValue('titulo_pagina', rifa.titulo_pagina);
      setPreviewImage(rifa?.imagem);
      setPreviewLogo(rifa?.logo);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    listAwardsByCampaignCallback({
      campaignId: uuid,
      page,
      total: 5,
    }, (response) => {
      const { awards, is_last_page: isLastPageResponse, total_pages } = response;
      setIsLastPage(isLastPageResponse);
      setTotalPages(total_pages);
      if (awards.length === 0) return;
      listWinnerAwardsRequest(`user/find/awards/winner/${uuid}`, (responseWinner: AwardWinner) => {
        const { winners } = responseWinner;
        if (campaign !== undefined) {
          const cotasResponse: Cota[] = awards.map((value) => ({
            id: value.id,
            description: value.description,
            name: value.name,
            number: padNumber(value.number.toString(), campaign!.quantidade_numeros.toString().length - 1),
            user_name: winners === null ? undefined : winners.find((winner) => winner.number === value.number)?.name,
            rifa_id: uuid,
            isEditable: false,
          }));
          setCotas(cotasResponse);
        }
      });
    });
  }, [uuid, page]);

  const onSubmitHandlerAwards = (data: PrizesData) => {
    const userLogged = getUserData();
    registerNewAwards('rifa/register/new/awards', {
      rifa_id: uuid,
      user_id: userLogged.id,
      ...data,
    }, (response) => {
      setOpenPrizes(false);
      toast.success(response.awards);
    });
    setCountAutomaticPrizes((prevState) => prevState + 1);
  };

  const handleCota = (value) => {
    if (value.id === selectedCota?.id) {
      return {
        ...value,
        schedule_to: new Date(selectedDate),
      };
    }
    return value;
  };

  const savePromos = (callback: () => void) => {
    const promoConverted = promotions?.map((promoValue) => ({
      ...promoValue,
      quantidade: Number(promoValue.quantidade),
      valor: promoValue.valor.toString(),
    }));
    const deletedPromos = promoConverted.filter((promoValue) => promoValue.deleted);
    const updatedPromos = promoConverted.filter((promoValue) => promoValue.updated && !promoValue.deleted);
    const newPromos = promoConverted.filter(
      (promoValue) => promoValue.new && !promoValue.deleted && promoValue.valor !== 0 && promoValue.quantidade !== 0,
    );

    newPromotionCallback(newPromos);
    deletedPromos.forEach((promoValue) => {
      deletePromotionCallback(promoValue.id, deletedPromos);
    });
    updatePromotionCallback(updatedPromos);
    callback();
  };

  const finalValue = watch('qntd_min') * watch('preco_por_numero');

  return (
    <div className="w-full flex flex-col">
      <Loading open={(loading || awardsloading || getAwardsByCampaignIdLoading || awardsNewloading)} />
      <div className="flex md:items-center sm:items-start gap-8 md:flex-row sm:flex-col ">
        <div className="flex items-center justify-center">
          <FireIcon className="size-5 mr-2" />
          <h1 className="text-xl font-semibold">Editar campanha</h1>
        </div>
      </div>
      <div>
        <form className="space-y-6 mt-6" action="#" method="POST" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="flex flex-col gap-5">
            <div className="grid md:grid-cols-2 gap-4">
              <Input value={watch('titulo')} defaultValue={campaign?.titulo} label="Nome da campanha" name="titulo" onChange={onChange} errorMessage={errors.titulo?.message} />
              <Input value={watch('titulo_pagina')} defaultValue={campaign?.titulo_pagina} label="Título da campanha" name="titulo_pagina" onChange={onChange} errorMessage={errors.titulo_pagina?.message} />
            </div>
            <TextArea value={watch('descricao')} defaultValue={campaign?.descricao} label="Descrição da campanha" name="descricao" onChange={onChange} errorMessage={errors.descricao?.message} />
            <div className="grid md:grid-cols-4 gap-4">
              <div className="hidden">
                <Select
                  value={watch('quantidade_numeros')}
                  defaultValue={campaign?.quantidade_numeros}
                  label="Quantidade de bilhetes"
                  customClass="w-full"
                  options={options}
                  name=""
                  errorMessage={errors.quantidade_numeros?.message}
                  onChange={onChange}
                />
              </div>
              <Select label="Status" value={watch('status')} options={status} name="status" onChange={onChange} errorMessage={errors.status?.message} customClass="w-full" />
              <Select customClass="w-full" value={watch('mostrar_top')} label="Top compradores" options={topBuyers} name="mostrar_top" onChange={onChange} errorMessage={errors.mostrar_top?.message} />
              <CheckBox id="show_percentage" checked={watch('mostrar_porcentagem_vendas')} label="Mostrar porcentagem de vendas da campanha?" name="mostrar_porcentagem_vendas" customClass="mt-5" onChange={(e) => setValue('mostrar_porcentagem_vendas', e.target.checked)} />
            </div>

            <Input value={watch('preco_por_numero')} defaultValue={campaign?.preco_por_numero} label="Preço" type="text" name="preco_por_numero" onChange={onChange} errorMessage={errors.preco_por_numero?.message} />
            <div className="grid md:grid-cols-3 gap-4">
              <Input value={watch('data_inicio')} defaultValue={dateValue(campaign?.data_inicio)} type="datetime-local" label="Data de inicio" name="data_inicio" onChange={onChange} errorMessage={errors.data_inicio?.message} />
              <Input value={watch('data_encerramento')} defaultValue={dateValue(campaign?.data_encerramento)} type="datetime-local" label="Data de encerramento" name="data_encerramento" onChange={onChange} errorMessage={errors.data_encerramento?.message} />
              <Input value={watch('data_sorteio')} defaultValue={dateValue(campaign?.data_sorteio)} type="datetime-local" label="Data do sorteio" name="data_sorteio" onChange={onChange} errorMessage={errors.data_sorteio?.message} />
            </div>
            <FileInput name="Imagem da plataforma" image={logo} setImage={setLogo} preview={previewLogo} setPreviewImage={setPreviewLogo} hasRemove />
            <FileInput image={image} setImage={setImage} preview={previewImage} setPreviewImage={setPreviewImage} />
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
              <div className="flex flex-col">
                <Input value={watch('qntd_min')} defaultValue={campaign?.qntd_min} onChange={onChange} label="Quantidade mínima de bilhetes por compra" type="number" name="qntd_min" errorMessage={errors.qntd_min?.message} />
                <span className="text-gray-500 text-xs italic">
                  O valor mínimo de compra é
                  {' '}
                  {formatValue(Number.isNaN(finalValue) ? 0 : finalValue)}
                </span>
              </div>
              <PhoneInput name="celular_suporte" value={watch('celular_suporte')} defaultValue={campaign?.celular_suporte} label="Telefone / WhatsApp para suporte" onChange={onChange} errorMessage={errors.celular_suporte?.message} />
            </div>

            {permissions.permissions.pixel && (
              <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-4">
                <CheckBox id="activate_pixel" checked={activatePixel} label="Ativar o Pixel para essa campanha?" name="activate_pixel" onChange={(e) => setActivatePixel(e.target.checked)} />
                {
                  activatePixel && (
                    <Input
                      value={watch('pixel_id')}
                      defaultValue={campaign?.pixel_id}
                      type="text"
                      label="Identificação do conjunto de dados do Pixel"
                      onChange={onChange}
                      name="pixel_id"
                    />
                  )
                }
              </div>
            )}
            {
              permissions.permissions.credenciais_pix && (
                <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
                  <Input
                    value={watch('chave_pix')}
                    defaultValue={campaign?.chave_pix}
                    type="text"
                    label="Chave Pix"
                    onChange={onChange}
                    name="chave_pix"
                  />
                  <Input
                    value={watch('pix_client_id')}
                    defaultValue={campaign?.pix_client_id}
                    type="text"
                    label="Pix Client Id"
                    onChange={onChange}
                    name="pix_client_id"
                  />
                  <Input
                    value={watch('pix_secret_id')}
                    defaultValue={campaign?.pix_secret_id}
                    type="text"
                    label="Pix Secret Id"
                    onChange={onChange}
                    name="pix_secret_id"
                  />
                </div>
              )
            }
          </div>
          <div>
            <PromotionsAndAwards
              campaignId={uuid}
              callback={listPromotionCallback}
              setOpenPromos={setOpenPromos}
              openPrizes={() => {
                listAwardsByCampaignCallback({
                  campaignId: uuid,
                  page: 0,
                  total: 5,
                }, (response) => {
                  setOpenPrizes(true);
                  const { awards, is_last_page: isLastPageResponse } = response;
                  setIsLastPage(isLastPageResponse);
                  if (awards.length === 0) return;
                  listWinnerAwardsRequest(`user/find/awards/winner/${uuid}`, (responseWinner: AwardWinner) => {
                    const { winners } = responseWinner;
                    if (campaign !== undefined) {
                      const cotasResponse: Cota[] = awards.map((value) => ({
                        id: value.id,
                        description: value.description,
                        name: value.name,
                        number: padNumber(value.number.toString(), campaign.quantidade_numeros.toString().length - 1),
                        user_name: winners === null ? undefined : winners.find((winner) => winner.number === value.number)?.name,
                        rifa_id: uuid,
                        isEditable: false,
                      }));
                      setCotas(cotasResponse);
                    }
                  });
                });
              }}
            />
          </div>
          <div>
            <Button label="Finalizar" type="submit" customClass="bg-primary-700 hover:bg-primary-500 w-44 mt-10 flex-row-reverse gap-2" Icon={ArrowRightIcon} />
          </div>
        </form>
      </div>
      <ModalEditPrizes
        toggleModal={openPrizes}
        setOpen={setOpenPrizes}
        onSubmitHandler={onSubmitHandlerAwards}
        setOpenCotaPremiada={setOpenCotaPremiada}
        openCotaPremiada={openCotaPremiada}
        cotas={cotas}
        setCotas={setCotas}
        setSelectedCota={setSelectedCota}
        campaingId={uuid}
        page={page}
        setPage={setPage}
        isLastPage={isLastPage}
        saveAward={handleSaveAward}
        totalPages={totalPages}
        uuidGenerated={uuidGenerated}
      />
      <ModalEditPromos
        toggleModal={openPromos}
        setOpen={setOpenPromos}
        callback={savePromos}
        campaignId={uuid}
      />
      <QuestionDialog
        title="Deseja realmente agendar a cota?"
        open={scheduledOpen}
        setOpen={setScheduledOpen}
        yesCallback={() => {
          if (!selectedDate) {
            setDateError('Campo Obrigatório');
            return;
          }
          setCotas((prevState) => prevState.map(handleCota));
          setDateError('');
          setOpenCotaPremiada(false);
          awardForceProcessCallback({
            award_id: selectedCota?.id,
            data_forcada: selectedDate,
          }, () => {
            toast.success('Cota agendada com sucesso!');
          });
        }}
      />
      <QuestionDialog
        title="Deseja realmente sortear a cota?"
        open={awardNow}
        setOpen={setAwardNow}
        yesCallback={() => {
          setCotas((prevState) => prevState.map((value) => {
            if (value.id === selectedCota?.id) {
              return {
                ...value,
              };
            }
            return value;
          }));
          awardForceProcessCallback({
            award_id: selectedCota?.id,
          }, () => {
            toast.success('Cota sorteado com sucesso!');
          });

          setOpenCotaPremiada(false);
        }}
      />
      <Modal open={openCotaPremiada} setOpen={setOpenCotaPremiada}>
        <h1>Deseja premiar a cota agora ou agenda-la?</h1>
        <Input
          type="datetime-local"
          label="Agendar cota"
          name="schedule_to"
          value={selectedDate}
          defaultValue={dateValue(selectedCota?.schedule_to)}
          errorMessage={dateError}
          onChange={(e) => {
            setSelectedDate(e.currentTarget.value);
          }}
        />
        <div className="flex gap-4 w-full">
          <Button
            label="Sortear Agora"
            customClass="w-full bg-primary-700 hover:bg-primary-500"
            onClick={() => {
              setAwardNow(true);
            }}
          />
          <Button
            label="Agendar"
            customClass="w-1/2 bg-primary-700 hover:bg-primary-500"
            onClick={() => {
              setScheduledOpen(true);
            }}
          />
          <Button
            label="Cancelar"
            customClass="w-1/2 bg-rose-500 hover:bg-rose-400"
            onClick={() => setOpenCotaPremiada(false)}
          />
        </div>
      </Modal>
    </div>
  );
}

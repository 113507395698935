/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Table } from './Components/Table';
import { useUserContext } from '../../context/GlobalContext';
import { Modal } from './ModalEdit';
import { Button } from '../../components/Button';
import { getUserData, isUserActivated } from '../../utils';
import { QuestionDialog } from '../../components/QuestionDialog';
import AddAffiliateModal from './Components/AddClientModal';
import ChangePasswordModal from '../Affiliates/Components/ChangePasswordModal';

export default function NewClient() {
  const [open, setOpen] = useState(false);
  const [openAddClient, setAddClient] = useState<boolean>(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);
  const [questionOpen, setQuestionOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [isUserToggledStatus, setIsUserToggledStatus] = useState(false);
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [myUser, setMyUser] = useState('');
  const {
    listClientCallback, disableAssociateCallback, enableAssociateCallback, clients, updateAssociateCallback, listPermissionsCallback, permissions,
  } = useUserContext();

  const defineSelectedUser = (user: any) => {
    setSelectedUser(user);
  };

  const openDisableQuestionModal = (user: any) => {
    setQuestionOpen(true);
    setSelectedUser(user);
  };

  const saveSelectedUser = () => {
    updateAssociateCallback(
      {
        ...selectedUser,
        porcentagem:
          selectedUser.porcentagem !== undefined
            ? Number(selectedUser.porcentagem) : 0,
      },
      () => {
        setIsUserUpdated((prevstate) => !prevstate);
        toast.success('Usuário atualizado com sucesso');
      },
      selectedUser.ID,
    );
  };

  const toggleStatus = () => {
    setIsUserToggledStatus((prevstate) => !prevstate);
    toast.success(
      `Usuário ${isUserActivated(selectedUser.status) ? 'bloqueado' : 'desbloqueado'} com sucesso`,
    );
  };

  const toggleUserStatus = () => {
    if (isUserActivated(selectedUser.status)) disableAssociateCallback(selectedUser.ID, toggleStatus);
    else enableAssociateCallback(selectedUser.ID, toggleStatus);
  };

  useEffect(() => {
    const data = getUserData();
    setMyUser(data.id);
  }, []);

  useEffect(() => {
    listClientCallback();
  }, [isUserToggledStatus, isUserUpdated, isRegister]);

  useEffect(() => {
    const data = getUserData();
    listPermissionsCallback(data.id, () => { });
  }, []);

  return (
    <div>
      <div className="px-4 sm:px-2 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="flex sm:items-start md:items-center gap-4 sm:flex-col md:flex-row mb-4">
                {permissions.permissions.gerenciamento_clientes && (
                  <Button
                    label="Adicionar Cliente"
                    type="button"
                    customClass="bg-primary-700 hover:bg-primary-500 hover:text-white w-44"
                    onClick={() => setAddClient(true)}
                  />
                )}
              </div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <Table
                  selectedUser={defineSelectedUser}
                  disableUser={openDisableQuestionModal}
                  users={clients}
                  openChangePasswordModal={() => setOpenChangePasswordModal(true)}
                  setOpen={setOpen}
                  permissions={permissions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        toggleModal={open}
        setOpen={setOpen}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        saveSelectedUser={saveSelectedUser}
      />
      <ChangePasswordModal
        show={openChangePasswordModal}
        setOpen={setOpenChangePasswordModal}
        associateId={selectedUser.ID}
      />
      <AddAffiliateModal invite={myUser} isRegister={isRegister} setIsRegister={setIsRegister} toggleModal={openAddClient} setOpen={setAddClient} />
      <QuestionDialog
        yesCallback={toggleUserStatus}
        open={questionOpen}
        setOpen={setQuestionOpen}
        title={`Deseja realmente ${isUserActivated(selectedUser.status || '') ? 'bloquear' : 'desbloquear'} o cliente?`}
      />
    </div>
  );
}

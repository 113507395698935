import React from 'react';
import { Link } from 'react-router-dom';

interface AlertProps {
  text: string;
  link: string;
  customClass?: string;
  Icon?: any;
  textColor?: string;
  IconColor?: string;
}

function AlertMessage({
  text,
  link,
  customClass = '',
  textColor = '',
  Icon, IconColor,
}: AlertProps) {
  return (
    <div className={`rounded-md ${customClass} p-4 border hover:border-gray-300 border-transparent transition-all duration-150 ease-in-out`}>
      <div className="flex">
        <Link className="flex items-center" to={link}>
          <div className="flex-shrink-0">
            {Icon && <Icon className={`${IconColor} group-hover:text-white size-5 shrink-0`} />}
          </div>
          <div className="ml-3">
            <div className={`${textColor} text-sm`}>
              <p>{text}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

AlertMessage.defaultProps = {
  customClass: '',
  textColor: 'text-black',
  Icon: '',
  IconColor: '',
};

export { AlertMessage };

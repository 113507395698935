import { toast } from 'react-toastify';
import React from 'react';
import { getToken } from '../services/localstorageService';

type ErrorResponse = Record<string, string>

export default function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export function getItem(key: string) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
  return undefined;
}

export const getData: any = (key: string) => {
  if (key) {
    return JSON.parse(String(localStorage.getItem(key)));
  }
  return {};
};
export const maskPhone = (value: string) => {
  let phone = value.replace(/\D/g, '');
  phone = value.replace(/^(\d{2})(\d)/g, '($1)$2');
  phone = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return phone;
};

export const extractErrorMessages = (response: ErrorResponse): string[] => {
  const errorMessages: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key in response) {
    if (Object.prototype.hasOwnProperty.call(response, key)) {
      const errorMessage = response[key];
      errorMessages.push(errorMessage);
    }
  }

  return errorMessages;
};

export const addPrefix = (
  phone: string,
  prefix: string,
  onChange: any,
) => {
  if (phone.includes(prefix)) {
    return phone;
  }
  switch (prefix) {
    case '+55':
      if (phone.includes('+1')) {
        const newPhone = phone.substring(0, 2);
        return onChange(`${prefix} ${newPhone}`);
      }
      return onChange(`${prefix} ${phone}`);
    case '+1':
      if (phone.includes('55')) {
        const newPhone = phone.substring(0, 3);
        return onChange(`${prefix} ${newPhone}`);
      }
      return onChange(`${prefix} ${phone}`);
    default:
      return phone;
  }
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};

export const getUserData = () => {
  const token = getToken();
  if (token) {
    return parseJwt(token);
  }
  return undefined;
};

export const showErrorToast = (error: any): void => {
  if (error !== null) {
    toast.error(error.message, { position: 'bottom-left', theme: 'colored' });
  }
};

export const handleFormData = (
  e:React.ChangeEvent<HTMLInputElement>,
  setState: (value:any)=> void,
) => {
  const { name, value } = e.currentTarget;
  setState((prevState:any) => ({
    ...prevState,
    [name]: value,
  }));
};

// function pad(num: number, size: number): string {
//   let s: string = num.toString();
//   while (s.length < size) s = `0${s}`;
//   return s;
// }

export function formatDate(dataString: string): string {
  const date: string = new Date(dataString).toLocaleDateString('pt-BR');
  return date;
}

export function formatDatetime(datetime: string): string {
  const date: string = new Date(datetime).toLocaleString('pt-BR', {
    timeZone: 'America/Sao_Paulo',
  });
  return date;
}

// eslint-disable-next-line no-shadow
export enum Roles {
    ADMIN = 'ADMIN',
    MANAGER = 'MANAGER',
    CLIENT = 'CLIENT'
}

export const isAdmin = (role: string): boolean => Roles.ADMIN === role.toUpperCase();
export const isAdminOrManager = (role: string): boolean => Roles.ADMIN === role.toUpperCase()
|| Roles.MANAGER === role.toLocaleUpperCase();
export const isAdminOrManagerOrClient = (role: string): boolean => isAdminOrManager(role)
|| Roles.CLIENT === role.toUpperCase();

export const isUserActivated = (status: string) => status.toUpperCase() === 'ATIVO';

export function converterParaAmericano(valor: string): number {
  const valorSemMilhar = valor.replace(/\./g, '');
  const valorAmericano = valorSemMilhar.replace(',', '.');
  return Number(valorAmericano);
}

export function converterParaBrasileiro(valor: number) {
  const valorString = valor.toFixed(2).toString();
  const [inteira, decimal] = valorString.split('.');
  const inteiraValue = inteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${inteiraValue},${decimal}`;
}

export function padNumber(numberStr: string, totalLength: number): string {
  const numZeros = totalLength - numberStr.length;
  if (numZeros < 0) return numberStr;
  const zeros = '0'.repeat(numZeros);
  return zeros + numberStr;
}

import React, { useEffect, useState } from 'react';
import { Select } from 'components/Select';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCampaignsContext } from '../../context/CampaignsContext';
import {
  formatDate, formatDatetime, getUserData, isAdminOrManager,
} from '../../utils';
import { CampaignDetails } from '../../interfaces';
import { formatValue } from '../../components/Currency';
import { Button } from '../../components/Button';
import { ModalSearchNumber } from './components/ModalSearchNumber';
import { ToggleButton } from '../../components/ToggleButton';

const status = [
  {
    value: '0',
    name: 'Mostrar todas',
  },
  {
    value: 'CRIADA',
    name: 'Criada',
  },
  {
    value: 'ATIVA',
    name: 'Ativa',
  },
  {
    value: 'CONCLUIDA',
    name: 'Concluida',
  },
  {
    value: 'INATIVA',
    name: 'Inativa',
  },
];

export default function Products() {
  const user = getUserData();
  const navigate = useNavigate();
  const {
    getCampaignsDetailsCallback,
    getCampaignsDetails,
    updateCampaignStatusCallback,
  } = useCampaignsContext();
  const [selectedStatus, setSelectedStatus] = useState('0');
  const [openFindNumber, setOpenFindNumber] = useState(false);
  const [rifaId, setRifaId] = useState<string>('');
  const [filteredCampaigns, setFilteredCampaigns] = useState<CampaignDetails[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [statusUpdated, setStatusUpdated] = useState<boolean>(false);

  const filterStatus = (event: React.ChangeEvent<
      HTMLInputElement |
      HTMLSelectElement |
      HTMLTextAreaElement>) => {
    const { value } : any = event.currentTarget;
    setSelectedStatus(value);
  };

  const filterName = (event: React.ChangeEvent<
      HTMLInputElement |
      HTMLSelectElement |
      HTMLTextAreaElement>) => {
    const { value } : any = event.currentTarget;
    setSelectedCampaign(value);
  };

  const handleFindNumberId = (id:string) => {
    setRifaId(id);
    setOpenFindNumber(true);
  };

  useEffect(() => {
    getCampaignsDetailsCallback();
  }, [statusUpdated]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    let filtered = getCampaignsDetails;
    if (selectedStatus !== '0') {
      filtered = filtered.filter(
        (campaign) => campaign.status === selectedStatus,
      );
    }
    if (selectedCampaign.trim() !== '') {
      filtered = filtered.filter(
        (campaign) => campaign.titulo.toLowerCase()
                  === selectedCampaign.toLowerCase(),
      );
    }
    setFilteredCampaigns(filtered);
  }, [selectedStatus, selectedCampaign, getCampaignsDetails]);

  const campaignOptions = [{ value: '', name: 'Filtrar por nome' }, ...getCampaignsDetails.map((campaign) => ({ value: campaign.titulo, name: campaign.titulo }))];

  const toggleCampaignStatus = (checked: boolean, campaignId: string) => {
    const statusCampaign = checked ? 'INATIVA' : 'ATIVA';
    updateCampaignStatusCallback(campaignId, statusCampaign, () => {
      toast.success('Status atualizado com sucesso!');
      setStatusUpdated((prevstate) => !prevstate);
      setSelectedStatus('0');
    });
  };

  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg mt-10">
      <h1 className="font-semibold text-xl">Produtos</h1>
      <div className="flex gap-4">
        <div>
          <Select
            defaultValue={selectedStatus}
            options={status}
            value={selectedStatus}
            onChange={filterStatus}
            customClass="w-auto mt-5 "
          />
        </div>
        <div>
          <Select
            defaultValue={selectedCampaign}
            options={campaignOptions}
            onChange={filterName}
            customClass="w-auto mt-5 max-w-[300px]"
          />
        </div>
      </div>
      {filteredCampaigns.map((campaign: CampaignDetails) => (
        <div
          className="flex flex-wrap gap-4 mt-8 max-w-5xl"
          key={campaign.id}
        >
          <div className=" w-[45%]  shadow-md p-4 rounded-md bg-zinc-50">
            <h1 className="font-semibold text-xl mb-4 border-b border-gray-700">
              {campaign.titulo}
            </h1>
            <p>
              <span className="font-semibold ">Valor:</span>
              {' '}
              {formatValue(campaign.preco_por_numero.toString())}
            </p>
            {campaign.data_sorteio && (
              <p>
                <span className="font-semibold text-sm">
                  Data do sorteio:
                </span>
                {' '}
                {formatDatetime(campaign.data_sorteio)}
              </p>
            )}
            {campaign.data_inicio && (
              <p>
                <span className="font-semibold text-sm">
                  Data de inicio:
                </span>
                {' '}
                {formatDate(campaign.data_inicio)}
              </p>
            )}
            <p>
              <span className="font-semibold text-sm">
                {' '}
                Bilhetes vendidos:
              </span>
              {' '}
              {campaign.quantidade_numeros_vendidos}
            </p>
            <p>
              <span className="font-semibold text-sm">
                {' '}
                Bilhetes restantes:
              </span>
              {' '}
              {campaign.quantidade_numeros_restantes}
            </p>
            <p>
              <span className="font-semibold text-sm">
                {' '}
                Valor arrecadado:
              </span>
              {' '}
              {formatValue(campaign.pago_valor)}
            </p>
            <p>
              <span className="font-semibold text-sm">
                {' '}
                Status:
              </span>
              {' '}
              {campaign.status}
            </p>
            <div>
              {isAdminOrManager(user?.roles) && (
              <ToggleButton
                label="Desativar campanha"
                defaultValue={campaign.status === 'INATIVA'}
                callback={(checked: boolean) => toggleCampaignStatus(checked, campaign.id)}
              />
              )}
            </div>
            <div className="flex">
              <Button
                label="Consultar bilhete"
                customClass="bg-primary-700 hover:bg-primary-500 text-white px-1 mt-4 rounded-md"
                onClick={() => handleFindNumberId(campaign?.id)}
              />
            </div>
          </div>
        </div>
      ))}
      <ModalSearchNumber
        toggleModal={openFindNumber}
        setOpen={setOpenFindNumber}
        rifaId={rifaId}
      />
    </div>
  );
}

import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { formatValue } from 'components/Currency';
import useGet from '../../../hooks/useGet';
import Loading from '../../../components/Loading';
import { formatDate, formatDatetime } from '../../../utils';
import { Button } from '../../../components/Button';
import cryImg from '../../../assets/img/crying.svg';
import { Titles } from '../../../interfaces';

export default function MyTitles() {
  const [titles, setTitles] = useState<Titles[]>([]);
  const { request: getTitle, loading: loadingGetTitle } = useGet();
  const [titleId, setTitleId] = useState<string>('');
  const handleToggle = (rifaId:string) => {
    if (titleId === rifaId) {
      setTitleId('');
    } else {
      setTitleId(rifaId);
    }
  };

  useEffect(() => {
    getTitle('user/find/purchases', (response) => setTitles(response.purchases));
  }, []);

  const verificaNum = () => {
    const index = titles.findIndex((value) => Array.isArray(value.numeros));
    return index > -1;
  };

  const formatTitle = (title: number) => {
    const titleString = title.toString();
    if (titleString.length < 6) {
      let newNumber = '';
      new Array(6 - titleString.length).fill(0).forEach(() => {
        newNumber = `0${newNumber}`;
      });
      return `${newNumber}${titleString}`;
    }
    return titleString;
  };

  return (
    <div className="w-full">
      <Loading open={loadingGetTitle} />
      <div className=" h-full sm:p-2 md:p-8 mt-10 flex mx-auto flex-col items-center justify-center max-w-[1280px]">
        <div className="bg-white p-4 rounded-md w-full flex flex-col justify-center items-center max-w-[650px]">
          <div className="text-center">
            <h1 className="text-primary-900 text-3xl font-semibold">
              Meus Bilhetes
            </h1>
          </div>
          <div className="w-full m-6">
            {
              (titles && titles.length > 0 && verificaNum()) ? (
                titles?.map((title) => Array.isArray(title?.numeros) && (
                  <div key={title?.pago_horario} className="border border-gray-300 px-4 py-8 rounded-md mb-4">
                    <div className="flex gap-4">
                      <div>
                        <img src={title?.imagem} className="rounded shadow-xl size-24" alt="" />
                      </div>
                      <div className="flex flex-col justify-center w-full">
                        <h3 className="text-gray-700 text-xl font-medium">{title?.titulo}</h3>
                        <div className="flex items-center gap-2">
                          {title.data_sorteio && (
                          <div className="flex flex-col">
                            <span className="text-gray-700 text-md font-medium">Sorteio</span>
                            <span className="text-gray-700  text-sm">{formatDate(title?.data_sorteio)}</span>
                          </div>
                          )}
                        </div>
                        <div className="flex items-center w-full justify-between gap-2 mt-4">
                          {title.pago_valor && (
                          <div className="flex flex-col">
                            <span className="text-gray-700 text-md font-medium">Valor pago</span>
                            <span className="text-gray-700  text-sm">{formatValue(title?.pago_valor.toString())}</span>
                          </div>
                          )}

                          {title.pago_horario && (
                          <div className="flex flex-col">
                            <span className="text-gray-700 text-md font-medium">Data da compra</span>
                            <span className="text-gray-700  text-sm">{formatDatetime(title?.pago_horario)}</span>
                          </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="flex flex-col relative w-full">
                        <span className="text-gray-700 font-semibold my-3 text-xl">Bilhete(s)</span>

                        <Button
                          onClick={() => handleToggle(title?.rifa_id)}
                          Icon={PlusIcon}
                          iconClass="size-4 !m-0 p-0"
                          label=""
                          customClass="bg-primary-700 size-6 absolute top-[20px] right-0 hover:bg-primary-500 !rounded-full flex items-center justify-center"
                        />
                        <span
                          className={`${title?.rifa_id === titleId ? ' overflow-auto' : 'max-h-[40px] overflow-hidden'} text-gray-700 font-normal text-xl flex flex-wrap gap-4`}
                        >
                          {
                              Array.isArray(title?.numeros)
                              && title?.numeros.map((numero:number) => (
                                <p
                                  key={numero}
                                  className="bg-primary-700 text-white rounded-full font-semibold py-1 px-4"
                                >
                                  {formatTitle(numero)}
                                </p>
                              ))
                            }
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full flex-col gap-4 flex items-center justify-center rounded p-4 mb-56">
                  <img src={cryImg} alt="" />
                  <h5 className="font-semibold text-gray-700">Você não possui nenhum bilhete</h5>
                </div>
              )

              }
          </div>
        </div>
      </div>
    </div>
  );
}

import { BanknotesIcon, GiftIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface PromotionsAndAwardsProps {
    setOpenPromos: React.Dispatch<React.SetStateAction<boolean>>
    callback: (campaignId: string) => void
    campaignId: string
    openPrizes: () => void
}
export const PromotionsAndAwards:React.FC<PromotionsAndAwardsProps> = ({
  setOpenPromos,
  callback,
  openPrizes,
  campaignId,
}) => (
  <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-8">
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions
            ,jsx-a11y/click-events-have-key-events */}
    <span
      onClick={() => {
        openPrizes();
      }}
      className="flex flex-col rounded-lg border border-primary-500 p-10 hover:shadow-md transition-shadow ease-in duration-200"
    >
      <div className="flex flex-col p-4 items-center justify-center cursor-pointer">
        <div className="p-2 bg-blue-50 rounded">
          <GiftIcon className="size-5 text-primary-700" />
        </div>
        <p className="font-semibold text-gray-600">Adicionar Cotas</p>
      </div>
    </span>
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions
            ,jsx-a11y/click-events-have-key-events */}
    <span
      onClick={() => {
        callback(campaignId);
        setOpenPromos(true);
      }}
      className="flex flex-col rounded-lg border border-primary-500 p-10 hover:shadow-md transition-shadow ease-in duration-200"
    >
      <div className="flex flex-col p-4 items-center justify-center cursor-pointer">
        <div className="p-2 bg-blue-50 rounded">
          <BanknotesIcon className="size-5 text-primary-700" />
        </div>
        <p className="font-semibold text-gray-600">Editar promoções</p>
      </div>
    </span>
  </div>
);

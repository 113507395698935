/* eslint-disable consistent-return */
import React, {
  Fragment, useEffect, useLayoutEffect, useState,
} from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  FireIcon,
  UserGroupIcon,
  XMarkIcon,
  Cog8ToothIcon,
  ArrowLeftCircleIcon,
  DocumentTextIcon,
  ClipboardDocumentIcon,
  ArrowTrendingUpIcon,
  RectangleGroupIcon,
  IdentificationIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import {
  Link, useLocation, useNavigate, useOutlet,
} from 'react-router-dom';
import { isPast, toDate } from 'date-fns';
import { useUserContext } from 'context/GlobalContext';
import logo from '../assets/img/Header2.png';
import { isAuthenticated, removeToken, validateUserObject } from '../services/localstorageService';
import classNames, { getUserData } from '../utils';
import Avatar from '../components/Avatar';

const navigationList = [
  {
    name: 'Campanhas', href: '', icon: FireIcon, current: true,
  },
  {
    name: 'Afiliados', href: 'afiliados', icon: UserGroupIcon, current: false, isAdmin: true, isManager: true,
  },
  {
    name: 'Produtos', href: 'produtos', icon: RectangleGroupIcon, current: false, isAdmin: true, isManager: true,
  },
  {
    name: 'Relatório financeiro', href: 'financeiro', icon: DocumentTextIcon, current: false, isAdmin: true, isManager: true,
  },
  {
    name: 'Meu Relatório de Vendas', href: 'financeiro/afiliados', icon: ClipboardDocumentIcon, current: false, isAdmin: true, isManager: true,
  },
  {
    name: 'Métricas', href: 'metricas', icon: ArrowTrendingUpIcon, current: false, isAdmin: true, isManager: true,
  },
  {
    name: 'Admin', href: 'admin', icon: IdentificationIcon, current: false, isAdmin: true, isManager: false,
  },
  {
    name: 'Clientes', href: 'clientes', icon: UserIcon, current: false, isAdmin: true, isManager: true,
  },
  {
    name: 'Configurações', href: 'configuracao', icon: Cog8ToothIcon, current: false, isAdmin: true, isManager: true,
  },
];

function Logged() {
  const user = getUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const [navigation, setNavigation] = useState(navigationList);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const outlet = useOutlet();
  const { listPermissionsCallback, permissions } = useUserContext();

  if (!isAuthenticated()) {
    console.log('teste');
    window.location.href = '/';
  }

  const changeCurrentNavigationOption = (index: number) => {
    const updateNavigation = navigation.map((item, updateIndex) => {
      if (updateIndex === index) {
        return {
          ...item,
          current: true,
        };
      }
      return {
        ...item,
        current: false,
      };
    });
    setNavigation(updateNavigation);
  };

  useLayoutEffect(() => {
    const index = navigation.findIndex((list) => `/app/${list.href}` === location.pathname);
    if (index > -1) {
      changeCurrentNavigationOption(index);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user.roles === 'User') {
        navigate('/campaign');
      }
      const past = isPast(toDate(`${user.exp}000`));
      if (past) {
        removeToken();
        navigate('/');
      }
    }
    if (!user) {
      navigate('/');
    }
  }, [user, getUserData()]);

  useEffect(() => {
    const data = getUserData();
    listPermissionsCallback(data.id, () => {});
  }, []);

  const managerRules = (href: string): boolean => {
    if (href === 'financeiro') return permissions.permissions.relatorio_financeiro;
    if (href === 'financeiro/afiliados') return permissions.permissions.relatorio_afiliados;
    if (href === 'metricas') return permissions.permissions.metricas;
    return true;
  };

  // eslint-disable-next-line max-len
  const ShowOption = (isAdmin: boolean | undefined, isManager: boolean | undefined, href: string) => {
    if ((href === 'afiliados' || href === 'financeiro' || href === 'metricas' || href === 'produtos') && user?.roles === 'Client') return true;
    if (href === 'financeiro/afiliados' && (user?.roles === 'Client' || user?.roles === 'Afiliado')) return true;
    if (isAdmin || isManager) {
      return user?.roles === 'Admin' || (user?.roles === 'Manager' && isManager && managerRules(href));
    } return true;
  };

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  <div className="flex pt-4 shrink-0 items-center justify-center">
                    <img
                      className="h-12 w-auto justify-center"
                      src={logo}
                      alt="Your Company"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="navigation" className="-mx-2 space-y-1">
                          {navigation.map((item, index) => ShowOption(
                            item.isAdmin,
                            item.isManager,
                            item.href,
                          ) && (
                          <li key={item.name}>
                            <Link
                              className={classNames(
                                item.current
                                  ? 'bg-primary-700 text-white'
                                  : 'text-primary-700 hover:text-white hover:bg-primary-500',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                              )}
                              to={item.href}
                              onClick={
                                () => {
                                  changeCurrentNavigationOption(index);
                                  setSidebarOpen(false);
                                }
                              }
                              replace
                            >
                              <item.icon
                                className={classNames(
                                  item.current ? 'text-white' : 'text-primary-700 group-hover:text-white',
                                  'h-6 w-6 shrink-0',
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </li>
                          ))}
                          <li>
                            <Link
                              className={classNames(
                                'text-primary-700 hover:text-white hover:bg-primary-500',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                              )}
                              to="/"
                              onClick={() => removeToken()}
                              replace
                            >
                              <ArrowLeftCircleIcon
                                className={classNames(
                                  'text-primary-700 group-hover:text-white',
                                  'h-6 w-6 shrink-0',
                                )}
                                aria-hidden="true"
                              />
                              Sair
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col overflow-y-auto bg-white  border-r-4 border-r-green-500 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center justify-center p-4">
            <img
              className="h-12 w-auto"
              src={logo}
              alt="Your Company"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item, index) => ShowOption(
                    item.isAdmin,
                    item.isManager,
                    item.href,
                  ) && (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-primary-700 text-white'
                          : 'text-primary-700 hover:text-white hover:bg-primary-500',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      )}
                      onClick={() => changeCurrentNavigationOption(index)}
                      replace
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-white' : 'primary-700 group-hover:text-white',
                          'h-6 w-6 shrink-0',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                  ))}

                  <li>
                    <Link
                      className={classNames(
                        'text-primary-700 hover:text-white hover:bg-primary-500',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      )}
                      to="/"
                      onClick={() => removeToken()}
                      replace
                    >
                      <ArrowLeftCircleIcon
                        className={classNames(
                          'text-primary-700 group-hover:text-white',
                          'h-6 w-6 shrink-0',
                        )}
                        aria-hidden="true"
                      />
                      Sair
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div
          className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-primary-700 text-white  px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
        >
          <button type="button" className="-m-2.5 p-2.5 text-white lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="flex items-center gap-x-4 lg:gap-x-6m justify-between w-full">
              <h2 className="sm:text-md md:text-2xl font-semibold">
                Olá,
                {' '}
                {validateUserObject() ? validateUserObject().name : user?.name}
              </h2>
              <Menu as="div" className="relative">
                <Avatar
                  name={validateUserObject() ? validateUserObject().name : user?.name}
                  photo={validateUserObject() ? validateUserObject().photo : user?.photo}
                />
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            {outlet}
          </div>
        </main>
      </div>
    </div>
  );
}

export { Logged };

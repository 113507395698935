import axios, {
  AxiosError, InternalAxiosRequestConfig,
} from 'axios';
import { getToken, removeToken } from '../services/localstorageService';

const api = axios.create({
  // baseURL: 'http://localhost:8080',
  baseURL: 'https://app-rifas-df3f6ixkga-uc.a.run.app/',
  timeout: 300000,
});

const onRequest = (config:InternalAxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    const { headers } = config;
    headers.Authorization = `${token}`;
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  if (error) {
    if (error.response) {
      if (error.response.status === 403) {
        removeToken();
        // eslint-disable-next-line no-unused-expressions
        window.location.host;
      }
    }
  }
  return Promise.reject(error);
};

api.interceptors.request.use(onRequest, onRequestError);

export { api };

import React, {
  createContext, useContext, type ReactNode, useState, useCallback, useMemo,
} from 'react';
import usePost from '../hooks/usePost';
import { setToken, setUserToStorage } from '../services/localstorageService';
import useGet from '../hooks/useGet';
import {
  ChangePassword, PaymentData, User, RecoveryData,
  ResetPasswordData, Permission,
} from '../interfaces';
import { parseJwt } from '../utils';

interface UserContextData {
  userData: any
  loginCallback: (data: any, isLogged: React.Dispatch<React.SetStateAction<boolean>>) => void
  loginByPhoneCallback: (
    data: any,
    isLogged: React.Dispatch<React.SetStateAction<boolean>>,
    setMessage: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => void
  registerCallback: (data: any, isRegister: React.Dispatch<React.SetStateAction<boolean>>) => void
  registerAssociateCallback: (
    data: any,
    isRegister: React.Dispatch<React.SetStateAction<boolean>>,
    uuid: string
  ) => void
  updateAssociateCallback: (
    data: any,
    callback: () => void,
    uuid: string
  ) => void
  disableAssociateCallback: (
    uuid: string,
    callback: () => void,
  ) => void
  enableAssociateCallback: (
    uuid: string,
    callback: () => void,
  ) => void
  registerUserCallback: (
    data: any,
    isRegister: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
  changeAssociatePasswordCallback: (
    data: ChangePassword,
    callback: () => void
  ) => void
  generateNumbersCallback: (data: any) => void
  checkPaymentCallBack: (txidData: string) => void
  listAssociateCallback: () => void
  listManagerCallback: () => void
  listClientCallback: () => void
  listPermissionsCallback: (userId: string, callback: () => void) => void
  recoveryCallback: (data: RecoveryData, callback: () => void) => void
  resetPasswordCallback: (data: ResetPasswordData, callback: () => void) => void
  savePermissionsCallback: (permissionsCallback: Permission, callback: () => void) => void
  errorLogin: Error | null
  setErrorLogin: React.Dispatch<React.SetStateAction<Error | null>>
  errorRecoveryRequest: Error | null
  errorResetPassword: Error | null
  errorRegister: Error | null
  errorRegisterUser: Error | null
  errorListAssociate: Error | null
  errorListPermissions: Error | null
  errorSavePermission: Error | null
  errorGenerateNumbers: Error | null
  errorAssociateRegister: Error | null
  errorLoginByPhone: Error | null
  errorChangeAssociatePassword: Error | null
  changeAssociatePasswordLoading: boolean
  loginLoading: boolean
  savePermissionLoading: boolean
  registerLoading: boolean
  registerUserLoading: boolean
  registerAssociateLoading: boolean
  loginByPhoneLoading: boolean
  recoveryLoading: boolean
  resetPasswordLoading: boolean
  errorListManager: Error | null
  errorListClient: Error | null
  associates: User[]
  managers: User[]
  clients: User[]
  permissions: Permission
  setPermissions: React.Dispatch<React.SetStateAction<Permission>>
  pixCopyPaste: string
  setPixCopyPaste: React.Dispatch<React.SetStateAction<string>>
  txid: string
  setTxid: React.Dispatch<React.SetStateAction<string>>
  setPixDate: React.Dispatch<React.SetStateAction<Date>>
  pixDate: Date
  openLoginModal: boolean,
  toggleLoginModalCallback: () => void,
  paymentData: PaymentData | undefined
}

const UserContext = createContext<UserContextData | null>(null);

interface UserProviderProps {
  children: ReactNode
}

export const UserContextProvider: React.FC<UserProviderProps> = ({ children }) => {
  const {
    request: loginRequest, error: errorLogin, loading: loginLoading, setError: setErrorLogin,
  } = usePost();
  const { request: registerRequest, error: errorRegister, loading: registerLoading } = usePost();
  const { request: generateNumbersRequest, error: errorGenerateNumbers } = usePost();
  const { request: checkPayment } = usePost();
  const { request: listAssociateRequest, error: errorListAssociate } = useGet();
  const { request: listManagerRequest, error: errorListManager } = useGet();
  const { request: listClientRequest, error: errorListClient } = useGet();
  const { request: listPermissionsRequest, error: errorListPermissions } = useGet();
  const {
    request: loginByPhoneRequest,
    error: errorLoginByPhone,
    loading: loginByPhoneLoading,
  } = usePost();
  const {
    request: registerUserRequest,
    error: errorRegisterUser,
    loading: registerUserLoading,
  } = usePost();
  const {
    request: changeAssociatePasswordRequest,
    error: errorChangeAssociatePassword,
    loading: changeAssociatePasswordLoading,
  } = usePost();
  const {
    request: registerAssociateRequest,
    error: errorAssociateRegister,
    loading: registerAssociateLoading,
  } = usePost();
  const {
    request: updateAssociateRequest,
    error: errorAssociateUpdate,
    loading: updateAssociateLoading,
  } = usePost();

  const {
    request: disableAssociateRequest,
    error: errorDisableAssociate,
    loading: disableAssociateLoading,
  } = usePost();

  const {
    request: enableAssociateRequest,
    error: errorEnableAssociate,
    loading: enableAssociateLoading,
  } = usePost();

  const {
    request: recoveryRequest,
    error: errorRecoveryRequest,
    loading: recoveryLoading,
  } = usePost();

  const {
    request: resetPasswordRequest,
    error: errorResetPassword,
    loading: resetPasswordLoading,
  } = usePost();

  const {
    request: savePermissionsRequest,
    error: errorSavePermission,
    loading: savePermissionLoading,
  } = usePost();

  const [userData, setUserData] = useState<any>();
  const [associates, setAssociates] = useState<User[]>([]);
  const [managers, setManagers] = useState<User[]>([]);
  const [clients, setClients] = useState<User[]>([]);
  const [pixCopyPaste, setPixCopyPaste] = useState('');
  const [txid, setTxid] = useState('');
  const [pixDate, setPixDate] = useState(new Date());
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState();
  const [permissions, setPermissions] = useState<Permission>({
    permissions: {
      administradores: false,
      credenciais_pix: false,
      gerenciamento_afiliados: false,
      gerenciamento_campanhas: false,
      gerenciamento_clientes: false,
      lista_clientes_por_administrador: false,
      metricas: false,
      pixel: false,
      relatorio_afiliados: false,
      relatorio_financeiro: false,
      user_id: '',
    },
  });

  const loginCallback = useCallback((
    data: any,
    isLogged: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    loginRequest('user/login', data, (response) => {
      const parseToken = parseJwt(response.token);
      setUserData(parseToken);
      setUserToStorage(parseToken);
      setToken(response.token);
      isLogged(true);
    });
  }, [loginRequest]);

  const loginByPhoneCallback = useCallback((
    data: any,
    isLogged: React.Dispatch<React.SetStateAction<boolean>>,
    setMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
  ) => {
    loginByPhoneRequest('user/auth/login', data, (response) => {
      if (response.message) {
        setMessage(response.message);
      }
      if (response.token) {
        setUserData(response.token);
        setToken(response.token);
        isLogged(true);
      }
    });
  }, [loginRequest]);

  const registerCallback = useCallback((
    data: any,
    isRegister: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    registerRequest('user/register', data, () => { isRegister(true); });
  }, [registerRequest]);

  const registerAssociateCallback = useCallback((
    data: any,
    isRegister: React.Dispatch<React.SetStateAction<boolean>>,
    uuid: string,
  ) => {
    registerAssociateRequest(`user/register/associate/${uuid}`, data, () => { isRegister(true); });
  }, [registerAssociateRequest]);

  const updateAssociateCallback = useCallback((
    data: any,
    callback: () => void,
    uuid: string,
  ) => {
    updateAssociateRequest(`user/register/update/associate/${uuid}`, data, () => { callback(); });
  }, [updateAssociateRequest]);

  const disableAssociateCallback = useCallback((
    uuid: string,
    callback: () => void,
  ) => {
    disableAssociateRequest(`user/associate/disable/${uuid}`, {}, () => { callback(); });
  }, [disableAssociateRequest]);

  const enableAssociateCallback = useCallback((
    uuid: string,
    callback: () => void,
  ) => {
    enableAssociateRequest(`user/associate/enable/${uuid}`, {}, () => { callback(); });
  }, [enableAssociateRequest]);

  const registerUserCallback = useCallback((
    data: any,
    isRegister: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    registerUserRequest('user/register/user', data, () => { isRegister(true); });
  }, [registerUserRequest]);

  const changeAssociatePasswordCallback = useCallback((
    data: ChangePassword,
    callback: () => void,
  ) => {
    changeAssociatePasswordRequest(`user/associate/change-password/${data.associateId}`, {
      password: data.password,
    }, () => { callback(); });
  }, [changeAssociatePasswordRequest]);

  const generateNumbersCallback = useCallback((
    data: any,
  ) => {
    generateNumbersRequest('numbers/new', data, (response) => { console.log(response); });
  }, [generateNumbersRequest]);

  const listAssociateCallback = useCallback(() => {
    listAssociateRequest('user/list?role=Afiliado', (response) => { setAssociates(response); });
  }, [listAssociateRequest]);

  const listManagerCallback = useCallback(() => {
    listManagerRequest('user/list?role=Manager', (response) => { setManagers(response); });
  }, [listManagerRequest]);

  const listClientCallback = useCallback(() => {
    listClientRequest('user/list?role=Client', (response) => { setClients(response); });
  }, [listClientRequest]);

  const listPermissionsCallback = useCallback((userId: string, callback: () => void) => {
    listPermissionsRequest(`user/permissions/${userId}`, (response) => {
      setPermissions({
        permissions: {
          ...response.permissions,
          user_id: userId,
        },
      }); callback();
    });
  }, [listPermissionsRequest]);

  // eslint-disable-next-line max-len
  const savePermissionsCallback = useCallback((permissionsCallback: Permission, callback: () => void) => {
    console.log(permissions);
    savePermissionsRequest('user/permissions/save', permissionsCallback.permissions, (response) => { console.log(response); callback(); });
  }, [savePermissionsRequest]);

  const toggleLoginModalCallback = useCallback(() => {
    setOpenLoginModal((prevState) => !prevState);
  }, [setOpenLoginModal]);

  const checkPaymentCallBack = useCallback((txidData: string) => {
    checkPayment(`payment/completed/${txidData}`, {}, (response) => setPaymentData(response.pagamento));
  }, [checkPayment]);

  const recoveryCallback = useCallback((data: RecoveryData, callback: () => void) => {
    recoveryRequest('user/recovery', data, () => callback());
  }, [recoveryRequest]);

  const resetPasswordCallback = useCallback((data: ResetPasswordData, callback: () => void) => {
    resetPasswordRequest('user/recovery/password', data, () => callback());
  }, [resetPasswordRequest]);

  const providerValue = useMemo(() => ({
    userData,
    loginCallback,
    loginByPhoneCallback,
    registerCallback,
    registerAssociateCallback,
    updateAssociateCallback,
    disableAssociateCallback,
    enableAssociateCallback,
    registerUserCallback,
    generateNumbersCallback,
    listManagerCallback,
    listAssociateCallback,
    listClientCallback,
    listPermissionsCallback,
    savePermissionsCallback,
    checkPaymentCallBack,
    changeAssociatePasswordCallback,
    recoveryCallback,
    resetPasswordCallback,
    resetPasswordLoading,
    errorChangeAssociatePassword,
    errorLogin,
    errorListClient,
    errorListPermissions,
    errorSavePermission,
    savePermissionLoading,
    setErrorLogin,
    errorRegister,
    errorRegisterUser,
    errorListManager,
    errorListAssociate,
    errorGenerateNumbers,
    errorAssociateRegister,
    errorRecoveryRequest,
    errorResetPassword,
    recoveryLoading,
    errorAssociateUpdate,
    errorDisableAssociate,
    errorEnableAssociate,
    errorLoginByPhone,
    loginLoading,
    registerLoading,
    registerUserLoading,
    registerAssociateLoading,
    updateAssociateLoading,
    disableAssociateLoading,
    enableAssociateLoading,
    loginByPhoneLoading,
    associates,
    managers,
    clients,
    permissions,
    setPermissions,
    pixCopyPaste,
    setPixCopyPaste,
    setTxid,
    pixDate,
    setPixDate,
    txid,
    openLoginModal,
    toggleLoginModalCallback,
    paymentData,
    changeAssociatePasswordLoading,
  }), [
    errorChangeAssociatePassword,
    errorLogin,
    setErrorLogin,
    errorRegister,
    errorListClient,
    errorListPermissions,
    errorSavePermission,
    savePermissionLoading,
    errorRegisterUser,
    errorListAssociate,
    errorGenerateNumbers,
    errorAssociateRegister,
    errorAssociateUpdate,
    errorDisableAssociate,
    errorEnableAssociate,
    errorListManager,
    errorRecoveryRequest,
    recoveryLoading,
    errorResetPassword,
    resetPasswordLoading,
    errorLoginByPhone,
    loginLoading,
    registerLoading,
    registerUserLoading,
    registerAssociateLoading,
    updateAssociateLoading,
    disableAssociateLoading,
    enableAssociateLoading,
    loginByPhoneLoading,
    changeAssociatePasswordLoading,
    associates,
    managers,
    clients,
    permissions,
    pixCopyPaste,
    openLoginModal,
    paymentData,
    setTxid,
    pixDate,
    setPixDate,
    txid,
  ]);

  return (
    <UserContext.Provider
      value={providerValue}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextData => {
  const context = useContext(UserContext);

  if (context === null) {
    throw new Error('useUserContext deve ser usado dentro de um UserContextProvider');
  }

  return context;
};

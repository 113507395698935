/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useCampaignsContext } from 'context/CampaignsContext';
import { Campaign } from 'interfaces';
import { Table } from './Components/Table';
import { useUserContext } from '../../context/GlobalContext';
import { Modal } from './ModalEdit';
import { Button } from '../../components/Button';
import { getUserData, isUserActivated } from '../../utils';
import { QuestionDialog } from '../../components/QuestionDialog';
import AddAffiliateModal from './Components/AddAffiliateModal';
import ChangePasswordModal from './Components/ChangePasswordModal';

export default function Affiliates() {
  const userLogged = getUserData();
  const [open, setOpen] = useState(false);
  const [openAddAffiliate, setAddAffiliate] = useState<boolean>(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);
  const [questionOpen, setQuestionOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [isUserToggledStatus, setIsUserToggledStatus] = useState(false);
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [myUser, setMyUser] = useState('');
  const {
    listAssociateCallback, disableAssociateCallback, enableAssociateCallback, associates, updateAssociateCallback, listPermissionsCallback, permissions,
  } = useUserContext();

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign[] | null>(null);

  const {
    getCampaignsCallback, getCampaigns, associateCampaignCallback, listAssociateCampaignCallback,
  } = useCampaignsContext();

  const defineSelectedUser = (user: any) => {
    setSelectedUser(user);
  };

  const openDisableQuestionModal = (user: any) => {
    setQuestionOpen(true);
    setSelectedUser(user);
  };

  const saveSelectedUser = () => {
    updateAssociateCallback(
      {
        ...selectedUser,
        porcentagem:
          selectedUser.porcentagem !== undefined
            ? Number(selectedUser.porcentagem) : 0,
      },
      () => {
        setIsUserUpdated((prevstate) => !prevstate);
        const rifaIds = selectedCampaign?.map((campaign) => campaign.id);
        if (rifaIds === undefined || selectedCampaign?.length === 0) {
          toast.error('Selecione ao menos uma rifa para ser associado');
          return;
        }
        associateCampaignCallback({
          user_id: selectedUser.ID,
          rifa_ids: rifaIds,
        }, () => {
          toast.success('Usuário atualizado com sucesso');
        });
      },
      selectedUser.ID,
    );
  };

  const toggleStatus = () => {
    setIsUserToggledStatus((prevstate) => !prevstate);
    toast.success(
      `Usuário ${isUserActivated(selectedUser.status) ? 'bloqueado' : 'desbloqueado'} com sucesso`,
    );
  };

  const toggleUserStatus = () => {
    if (isUserActivated(selectedUser.status)) disableAssociateCallback(selectedUser.ID, toggleStatus);
    else enableAssociateCallback(selectedUser.ID, toggleStatus);
  };

  useEffect(() => {
    const data = getUserData();
    setMyUser(data.id);
  }, []);
  useEffect(() => {
    const data = getUserData();
    listPermissionsCallback(data.id, () => { });
  }, []);

  useEffect(() => {
    listAssociateCallback();
  }, [isUserToggledStatus, isUserUpdated, isRegister]);

  useEffect(() => {
    getCampaignsCallback();
  }, []);

  useEffect(() => {
    if (Object.keys(selectedUser).length > 0) {
      listAssociateCampaignCallback(selectedUser.ID, (response) => {
        console.log(response.associate_campaigns); setSelectedCampaign(response.associate_campaigns);
      });
    }
  }, [selectedUser]);

  return (
    <div>
      <div className="px-4 sm:px-2 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="flex sm:items-start md:items-center gap-4 sm:flex-col md:flex-row mb-4">
                {
                  userLogged && (permissions.permissions.gerenciamento_afiliados || userLogged?.roles === 'Client') && (
                    <Button
                      label="Adicionar afiliado"
                      type="button"
                      customClass="bg-primary-700 hover:bg-primary-500 hover:text-white w-44"
                      onClick={() => setAddAffiliate(true)}
                    />
                  )
                }
              </div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <Table
                  selectedUser={defineSelectedUser}
                  disableUser={openDisableQuestionModal}
                  users={associates}
                  openChangePasswordModal={() => setOpenChangePasswordModal(true)}
                  setOpen={setOpen}
                  permissions={permissions}
                  userLogged={userLogged}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        toggleModal={open}
        setOpen={setOpen}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        saveSelectedUser={saveSelectedUser}
        campaigns={getCampaigns}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
      />
      <ChangePasswordModal
        show={openChangePasswordModal}
        setOpen={setOpenChangePasswordModal}
        associateId={selectedUser.ID}
      />
      <AddAffiliateModal invite={myUser} isRegister={isRegister} setIsRegister={setIsRegister} toggleModal={openAddAffiliate} setOpen={setAddAffiliate} />
      <QuestionDialog
        yesCallback={toggleUserStatus}
        open={questionOpen}
        setOpen={setQuestionOpen}
        title={`Deseja realmente ${isUserActivated(selectedUser.status || '') ? 'bloquear' : 'desbloquear'} o afiliado?`}
      />
    </div>
  );
}

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useUserContext } from 'context/GlobalContext';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  InferType, object, ref, string,
} from 'yup';

const resetPasswordSchema = object({
  password: string().required('Senha é obrigatória'),
  confirm_password: string().required('Confirmação de senha obrigatória').oneOf([ref('password')], 'As senhas devem ser iguais'),
});

type ResetPasswordData = InferType<typeof resetPasswordSchema>

export default function ResetPassword() {
  const { uuid } = useParams();
  const { resetPasswordCallback, errorResetPassword, resetPasswordLoading } = useUserContext();
  const {
    setValue, formState: { errors }, handleSubmit, reset,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const navigate = useNavigate();

  const onSubmitHandler = ({ password }: ResetPasswordData) => {
    if (uuid === undefined) return;
    resetPasswordCallback({
      id_recuperacao: uuid,
      password,
    }, () => {
      toast.success('Senha alterada com sucesso!!');
      reset();
      navigate('/');
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } : any = event.currentTarget;
    setValue(name, value);
  };

  useEffect(() => {
    if (errorResetPassword) {
      toast.error(`${errorResetPassword}`);
    }
  }, [resetPasswordLoading]);
  return (
    <div className="flex h-screen items-center justify-center flex-col">
      <div className="w-max-[500px] flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold mb-2">Crie uma nova senha</h1>
        <h2 className="text-md font-bold text-gray-400">Sua nova senha deve ser diferente</h2>
        <h2 className="text-md font-bold text-gray-400">das senhas anteriores.</h2>
        <form className="w-full" onSubmit={handleSubmit(onSubmitHandler)}>
          <Input type="password" onChange={onChange} errorMessage={errors.password?.message} customClass="py-2" containerClass="my-4" placeholder="Senha" name="password" />
          <Input type="password" onChange={onChange} errorMessage={errors.confirm_password?.message} customClass="py-2" containerClass="my-4" placeholder="Confirme a senha" name="confirm_password" />
          <Button type="submit" label="Continuar" customClass="w-full py-2 bg-primary-700 hover:bg-primary-500" />
        </form>
      </div>
    </div>
  );
}

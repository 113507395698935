import { useState } from 'react'; // Added React import
import { Switch } from '@headlessui/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface ToggleButtonProps{
    label: string
    callback?: (checked: boolean) => void
    customClass?: string
    defaultValue?: boolean
    yesNoLabelActive?: boolean
}

function ToggleButton({
  label,
  callback,
  customClass,
  defaultValue = false,
  yesNoLabelActive = false,
}:ToggleButtonProps) {
  const [enabled, setEnabled] = useState(defaultValue);

  const handleSwitch = (checked: boolean) => {
    setEnabled(checked);
    if (callback !== undefined) callback(checked);
  };

  return (
    <div className={`flex gap-4 items-center text-sm font-medium leading-6 text-gray-600 ${customClass}`}>
      <label>{ label }</label>
      <div className="flex gap-2 items-center justify-center">
        <Switch
          checked={enabled}
          onChange={handleSwitch}
          className={classNames(
            enabled ? 'bg-primary-500' : 'bg-gray-200',
            'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-6' : 'translate-x-0',
              'pointer-events-none inline-block h-4 w-4 text-gray-600 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
        {yesNoLabelActive && <span className="font-bold">{(enabled ? 'Sim' : 'Não')}</span>}
      </div>
    </div>

  );
}
export { ToggleButton };

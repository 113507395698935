import { UserIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Input } from 'components/Input';
import { PhoneInput } from 'components/PhoneInput';
import { AlertMessage } from 'components/AlertMessage';
import { TextArea } from 'components/TextArea';
import {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import { ToggleButton } from 'components/ToggleButton';
import { Modal } from 'components/Modal';
import { Button } from '../../../components/Button';
import avatar from '../../../assets/img/Avatar.svg';
import usePost from '../../../hooks/usePost';
import { setUserToStorage, validateUserObject } from '../../../services/localstorageService';
import Loading from '../../../components/Loading';
import { StoreButton } from './StoreButton';

type formType = {
  email: string,
  phone_number: string,
  name: string,
  last_name: string,
  two_step_auth_active: boolean,
  two_step_auth_secret: string
}
function UserProfile() {
  const [loading, setLoading] = useState(false);
  const inputUpload = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>(null);
  const { request: updateUser } = usePost();
  const { request: getGoogleAuthQrCode } = usePost();
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState<boolean>(false);
  const [qrCodeImg, setQrCodeImg] = useState<string>();
  const [formData, setFormData] = useState<formType>({
    email: '',
    phone_number: '',
    name: '',
    last_name: '',
    two_step_auth_active: false,
    two_step_auth_secret: '',
  });

  const changeFormData = (e: any) => {
    const { name, value } = e.currentTarget;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData({
      email: validateUserObject().email,
      phone_number: validateUserObject().phone_number,
      name: validateUserObject().name,
      last_name: validateUserObject().last_name,
      two_step_auth_active: validateUserObject().two_step_auth_active,
      two_step_auth_secret: validateUserObject().two_step_auth_secret,
    });
  }, []);

  const updateUserCallback = () => {
    setLoading(true);
    const form = new FormData();

    Object.keys(formData).forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      form.append(key, formData[key]);
    });
    if (image) {
      form.append('file', image);
    }
    updateUser('user/register/update', form, (response) => {
      setUserToStorage(response.user);
      toast.success('Usuário alterado com sucesso');
    });
    setLoading(false);
  };

  const onImageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleTwoStepAuth = (checked: boolean) => {
    if (!checked) {
      setFormData((prevState) => ({
        ...prevState,
        two_step_auth_active: checked,
        two_step_auth_secret: '',
      }));
      return;
    }
    getGoogleAuthQrCode('user/authenticator', {}, (response) => {
      setQrCodeImg(`data:image/png;base64,${response.authenticator}`);
      setQrCodeModalOpen(true);
      setFormData((prevState) => ({
        ...prevState,
        two_step_auth_active: checked,
        two_step_auth_secret: response.secret,
      }));
    });
  };

  return (
    <>
      <Loading open={loading} />
      <div className="text-left mt-10">
        <div className="rounded-xl shadow p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white">
          <div className="flex gap-2 justify-between">
            <div className="flex justify-center items-center gap-3">
              <UserIcon className="size-4" />
              <h2 className="text-gray-800 font-semibold">Minha conta</h2>
            </div>
          </div>
          <div className="mt-10">
            <p className="text-sm text-gray-700 font-medium">Foto</p>
            <div className="flex gap-4">
              <img src={image ? URL.createObjectURL(image) : avatar} className="w-10 rounded-full size-10" alt="avatar" />
              <input ref={inputUpload} type="file" name="photo" accept="image/*" hidden onChange={(event) => onImageChange(event)} />
              <Button onClick={() => inputUpload.current?.click()} label="Alterar" customClass="border bg-transparent !text-gray-700 hover:bg-primary-500 hover:!text-white" />
            </div>
          </div>
          <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:gap-4 md:gap-x-16  mt-4">
            <Input name="name" value={formData.name} label="Nome" errorMessage="" onChange={(event) => (changeFormData(event))} />
            <Input name="last_name" label="Sobrenome" value={formData.last_name} errorMessage="" onChange={(event) => (changeFormData(event))} />
          </div>
          <div className="mt-4 gap-4 flex flex-col">
            <Input value={formData.email} label="Email" errorMessage="" onChange={(event) => (changeFormData(event))} />
          </div>
          <div className="grid md:grid-cols-2 sm:grid-cols-1 sm:gap-4 md:gap-x-16  mt-4 ">
            <PhoneInput name="phone_number" value={formData.phone_number} label="Celular" errorMessage="" onChange={(event) => (changeFormData(event))} />
            <ToggleButton
              label="Ativar autenticação de dois fatores"
              defaultValue={validateUserObject().two_step_auth_active}
              customClass="md:mt-6"
              callback={handleTwoStepAuth}
            />
          </div>

          <Button
            type="button"
            label="Salvar"
            customClass="bg-primary-700 hover:bg-primary-500 mt-10"
            onClick={() => updateUserCallback()}
          />
        </div>
      </div>

      <div className="text-left mt-10">
        <div className="rounded-xl shadow p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white">
          <div className="flex gap-2 justify-between flex-col">
            <div className="flex flex-col gap-3 w-full">
              <h2 className="text-gray-800 font-semibold">Excluir Conta</h2>
              <AlertMessage text="Esta ação é irreversível, irá deletar todos os seus dados do nosso sistema." link="" customClass="bg-blue-50 font-semibold w-full" textColor="text-blue-700" Icon={InformationCircleIcon} IconColor="text-blue-500" />
            </div>
            <div className="mt-5">
              <TextArea label="Confirmar exclusão. Digite 'Autorizo excluir minha conta'" errorMessage="" />
            </div>
          </div>
          <Button label="Excluir" customClass="bg-red-500 hover:bg-red-400 mt-10" />
        </div>
      </div>
      <Modal open={qrCodeModalOpen} setOpen={setQrCodeModalOpen}>
        <h1 className="font-bold text-2xl">Autenticação de duas etapas</h1>
        <img alt="Qr code do Google Authenticator" src={qrCodeImg} className="h-44 w-44" />
        <span className="text-gray-400 text-center font-semibold text-sm -mt-4">
          Para escanear o QR Code,
          <br />
          {' '}
          por favor utilize o Google Authenticator
        </span>
        <div className="flex gap-2">
          <StoreButton store="google" />
          <StoreButton store="apple" />
        </div>
      </Modal>
    </>
  );
}

export { UserProfile };

import React from 'react';
import { Link } from 'react-router-dom';
import happyIcon from '../../../assets/img/happy.svg';
import { Button } from '../../../components/Button';

const Confirmation = () => (
  <div className="flex justify-center items-center ">
    <div
      className="h-full sm:p-2 md:p-8 mt-10 flex mx-auto flex-col items-center justify-center max-w-[1280px]"
    >
      <div
        className="bg-primary-500 p-4 rounded-md h-[500px] flex flex-col gap-8 justify-center items-center"
      >
        <h1 className="font-semibold text-2xl text-center text-white">Pagamento Concluído.</h1>
        <img src={happyIcon} className="size-32 invert" alt="um emoti feliz" />
        <Link to="/campaign/bilhetes">
          <Button label="Meus Bilhetes" customClass="bg-primary-700 w-44 !rounded-full hover:!bg-primary-700 border hover:!text-white hover:border-white" />
        </Link>
      </div>
    </div>
  </div>
);

export default Confirmation;

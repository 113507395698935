export const formatValue = (value:string, currency?:string) => {
  switch (currency) {
    case 'R$':
      return parseFloat(value).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    case 'US$':
      return parseFloat(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    case '€':
      return parseFloat(value).toLocaleString('en-GB', {
        style: 'currency',
        currency: 'EUR',
      });
    default:
      if (value) {
        return parseFloat(value).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      } return '';
  }
};

export const Currency = ({
  value,
  currency,
}
:{value:string, currency:string}) => formatValue(value, currency);

import React, { HTMLProps, InputHTMLAttributes } from 'react';

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement>{
    label: string
    customClass?: HTMLProps<HTMLElement>['className']
}
function CheckBox({
  name, id, label, customClass, onChange, checked,
}: CheckBoxProps) {
  return (
    <div className={`flex items-center justify-between ${customClass}`}>
      <div className="flex items-center">
        <input
          id={id}
          name={name}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 focus:ring-primary-500"
          checked={checked}
          onChange={onChange}
        />
        <label
          htmlFor={id}
          className="ml-3 block text-sm leading-6 text-gray-900"
        >
          {label}
        </label>
      </div>
    </div>
  );
}

export { CheckBox };

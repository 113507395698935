import React, { useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  object, string,
} from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { PhoneInput } from '../../../components/PhoneInput';
import { useUserContext } from '../../../context/GlobalContext';
import Loading from '../../../components/Loading';

interface ModalProps {
    toggleModal: boolean
    setOpen: (value: boolean) => void
    invite: string
    setIsRegister: React.Dispatch<React.SetStateAction<boolean>>
    isRegister: boolean
}

const loginSchema = object({
  email: string().email()
    .required('Email obrigatório'),
  password: string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  name: string()
    .required('Nome obrigatório.'),
  last_name: string()
    .required('Sobrenome obrigatório.'),
  cpf: string()
    .required('CPF obrigatório.'),
  phone_number: string().required('Telefone obrigatório'),
});

export default function AddAffiliateModal({
  toggleModal, setOpen, invite, setIsRegister, isRegister,
}:ModalProps) {
  const {
    registerCallback,
    registerLoading,
    errorRegister,
    registerAssociateCallback,
    registerAssociateLoading,
    errorAssociateRegister,
    errorRegisterUser,
    registerUserLoading,
  } = useUserContext();
  const navigate = useNavigate();

  const {
    setValue, formState: { errors }, handleSubmit, reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onChange = (event:any) => {
    const { value, name } = event.currentTarget;
    setValue(name, value);
  };

  useEffect(() => {
    if (errorRegister) {
      toast.error(`${errorRegister}`);
    }
    if (errorAssociateRegister) {
      toast.error(`${errorAssociateRegister}`);
    }
    if (errorRegisterUser) {
      toast.error(`${errorRegisterUser}`);
    }
  }, [errorRegister, errorAssociateRegister, errorRegisterUser]);

  useEffect(() => {
    if (isRegister) {
      toast.success('Usuário cadastrado com sucesso');
      reset();
    }
  }, [isRegister, reset, navigate]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmitHandler = (data:any) => {
    if (invite) {
      registerAssociateCallback(data, setIsRegister, invite);
      handleCloseModal();
    } else {
      registerCallback(data, setIsRegister);
    }
  };

  return (
    <Transition.Root show={toggleModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:ml-20 sm:p-4 sm:mt-8 md:p-0 md:mt-0">
          <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[1046px] sm:p-4">
                <div>
                  <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center" />
                    <div className="mt-6 sm:mx-auto sm:w-full">
                      <h3 className="font-semibold text-3xl pl-5 text-gray-700">Adicionar Afiliado</h3>
                      <div className="bg-white px-6 pb-12">
                        <form className="space-y-6 mt-6" onSubmit={handleSubmit(onSubmitHandler)}>
                          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-5">
                            <Input label="Nome" name="name" onChange={onChange} errorMessage={errors.name?.message} />
                            <Input label="Sobrenome" name="last_name" onChange={onChange} errorMessage={errors.last_name?.message} />
                          </div>
                          <div className="flex flex-col gap-y-4">
                            <Input label="CPF" mask="999.999.999-99" name="cpf" onChange={onChange} errorMessage={errors.cpf?.message} />
                            <Input label="Email" name="email" onChange={onChange} errorMessage={errors.email?.message} />
                            <Input label="Password" name="password" type="password" onChange={onChange} errorMessage={errors.password?.message} />
                            <PhoneInput label="Telefone" name="phone_number" onChange={onChange} errorMessage={errors.phone_number?.message} />
                          </div>
                          <div>
                            <div>
                              <div className="flex gap-4">
                                <Button
                                  label="Cancelar"
                                  type="button"
                                  customClass="bg-rose-500 sm:w-full md:w-32 hover:bg-rose-400 mt-10"
                                  onClick={handleCloseModal}
                                />
                                <Button
                                  label="Salvar"
                                  type="submit"
                                  customClass="bg-primary-700 sm:w-full md:w-32 hover:bg-primary-500 mt-10"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <Loading open={(registerLoading
                        || registerAssociateLoading
                        || registerUserLoading)}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { formatValue } from '../../../components/Currency';
import { Metrics } from '../../../interfaces';

interface MetricsProps {
  metrics: Metrics[] | undefined
}
function MetricsTable({ metrics }: MetricsProps) {
  return (
    <div className="px-4 sm:px-6 lg:px-8 overflow-y-auto max-h-[900px]">
      <div className="-mx-4 mt-4 flow-root sm:mx-0">
        <table className="min-w-full">
          <colgroup>
            <col className="w-full sm:w-1/2" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
          </colgroup>
          <thead className="border-b border-gray-300 text-gray-900">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Status
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Compradores
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Bilhetes vendidos
              </th>
              <th scope="col" className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                Valor médio
              </th>
            </tr>
          </thead>
          <tbody>
            {metrics && metrics.map((metric) => (
              <tr key={metric.status} className="border-b border-gray-200 ">
                <td className="hidden py-5 text-left text-sm text-black sm:table-cell font-semibold">{metric.status}</td>
                <td className="flex justify-between px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">
                  <span>{metric.compradores}</span>
                </td>
                <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">{metric.numeros_solicitados}</td>
                <td className="py-5 pl-3 pr-4 text-left text-sm text-gray-500 sm:pr-0">{formatValue(metric.valor_medio_por_comprador.toString())}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export { MetricsTable };

import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { cpf } from 'cpf-cnpj-validator';
import { Button } from '../../../components/Button';
import { useUserContext } from '../../../context/GlobalContext';
import { getToken, isAuthenticated } from '../../../services/localstorageService';
import { getUserData } from '../../../utils';
import { PhoneInput } from '../../../components/PhoneInput';
import { CheckBox } from '../../../components/CheckBox';
import { Input } from '../../../components/Input';
import { AlertMessage } from '../../../components/AlertMessage';

const clearCpf = (val: string | undefined | null) => {
  if (val) {
    const newCpf = val.match(/\d/g);
    if (newCpf) {
      return newCpf.join('');
    } return '';
  } return '';
};
const loginSchema = object({
  phone_number: string().required('Invalid phone'),
  name: string().nullable('Invalid name'),
  last_name: string().nullable('Invalid last name'),
  email: string().nullable('Invalid email'),
  cpf: string().nullable('Invalid cpf').test(
    'test-invalid-cpf',
    'cpf inválido',
    (val) => (val ? cpf.isValid(clearCpf(val)) : true),
  ),
});
function LoginModal() {
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const { loginByPhoneCallback, openLoginModal, toggleLoginModalCallback } = useUserContext();
  const {
    setValue, formState: { errors }, handleSubmit, watch, setError,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } : any = event.currentTarget;
    setValue(name, value);
  };
  const onSubmitHandler = (data: any) => {
    if (message) {
      if (!cpf.isValid(clearCpf(data.cpf))) {
        setError('cpf', { message: 'Invalid cpf' });
      }
      if (!data.name) {
        setError('name', { message: 'Invalid name' });
      }
      if (cpf.isValid(clearCpf(data.cpf)) && data.name) {
        loginByPhoneCallback(data, setIsLogged, setMessage);
      }
    } else {
      loginByPhoneCallback(data, setIsLogged, setMessage);
    }
  };

  useEffect(() => {
    if (isAuthenticated() && openLoginModal) {
      const user = getUserData();
      if (user) {
        if (user.roles === 'User') {
          toggleLoginModalCallback();
        }
      }
    }
  }, [getToken(), isLogged, openLoginModal]);

  useEffect(() => {
    if (message) toast.warning('Preencha suas informações de contato');
  }, [message]);

  return (
    <Transition.Root show={openLoginModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" id="login-modal" onClose={toggleLoginModalCallback}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[400px] sm:p-4">
                <div className="text-center">
                  <div className="flex justify-between">
                    <h1 className="font-semibold text-gray-500 mb-5">
                      Entrar para
                      comprar
                    </h1>
                    <div>
                      <XMarkIcon className="w-6 text-gray-700 cursor-pointer" onClick={toggleLoginModalCallback} />
                    </div>
                  </div>
                  <form
                    className="space-y-6 text-left"
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(onSubmitHandler)}
                  >
                    <PhoneInput
                      label="Telefone"
                      name="phone_number"
                      value={watch('phone_number') || ''}
                      onChange={onChange}
                      errorMessage={errors.phone_number?.message}
                    />
                    {message && (
                    <>
                      <AlertMessage
                        text="Estes dados são necessários para o recebimento do prêmio, caso seja o vencedor."
                        link=""
                        customClass="bg-blue-50 font-semibold w-full"
                        textColor="text-blue-700"
                        Icon={InformationCircleIcon}
                        IconColor="text-blue-500"
                      />
                      <div className="flex">
                        <Input label="Nome" name="name" errorMessage={errors.name?.message} onChange={onChange} />
                        <Input containerClass="ml-2" label="Sobrenome" name="last_name" errorMessage={errors.last_name?.message} onChange={onChange} />
                      </div>
                      <Input label="CPF" mask="999.999.999-99" name="cpf" errorMessage={errors.cpf?.message} onChange={onChange} />
                      <Input label="E-mail" name="email" errorMessage={errors.email?.message} onChange={onChange} />
                    </>
                    )}
                    <div className="flex items-center justify-center">
                      <CheckBox label="Lembrar da conta" name="remember-me" />
                    </div>
                    <div>
                      <Button
                        label="Continuar"
                        type="submit"
                        customClass="bg-primary-700 hover:bg-primary-500 !rounded-full w-full font-medium"
                      />
                    </div>
                    <div className="hidden">
                      <Button
                        label="Cadastre-se"
                        type="button"
                        customClass="bg-primary-400 hover:bg-primary-500 w-full font-medium"
                        onClick={() => navigate('/signup/user')}
                      />
                    </div>
                  </form>
                  <div className="flex mt-4 hidden">
                    <Button
                      onClick={toggleLoginModalCallback}
                      label="Cancelar"
                      customClass="bg-white w-full border border-gray-300 !text-gray-500 hover:!text-white"
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { LoginModal };

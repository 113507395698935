import { PencilSquareIcon, ShareIcon } from '@heroicons/react/20/solid';
import { Button } from 'components/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Permission } from 'interfaces';
import imgPlaceholder from '../../../assets/img/Photograph.svg';
import usePost from '../../../hooks/usePost';
import Loading from '../../../components/Loading';

interface hascampaignProps {
  campaignData: any[]
  user: any
  permissions: Permission
}
export default function HasCampaign({ campaignData, user, permissions }: hascampaignProps) {
  const [url, setUrl] = useState<string[]>(['']);
  const [showUrl, setShowUrl] = useState<boolean>(false);
  const {
    request: sharedCampaignRequest,
    error: errorSharedCampaign,
    loading: sharedCampaignLoading,
  } = usePost();
  const shareCampaign = (id: any) => {
    if (!url[id]) {
      sharedCampaignRequest(
        'user/register/sharedcampaign',
        { rifa_id: id },
        (response) => {
          const auxList = url;
          if (id === '0111de6e-b225-4d13-a906-a523afbff5cc') auxList[id] = `https://bilhetemilionario.com.br?sharedcampaignId=${response.shared.id}`;
          else auxList[id] = `${window.location.host}/campaign/${response.shared.id}`;
          setUrl(auxList);
        },
      );
    }
  };

  useEffect(() => {
    if (campaignData) {
      campaignData.map((campaign) => shareCampaign(campaign.id));
    }
  }, [campaignData]);

  useEffect(() => {
    if (errorSharedCampaign) {
      // toast.error(`${errorSharedCampaign}`);
    }
  }, [errorSharedCampaign]);

  return (
    <>
      <Loading open={sharedCampaignLoading} />
      {
        campaignData.map((campaign: any) => campaign.status !== 'INATIVA' && (
          <div
            className="w-full flex justify-center items-center flex-col gap-4 border border-solid border-gray-400 rounded-lg mt-10"
            key={campaign.id}
          >
            <div className="flex w-full items-center justify-end">
              {user && (permissions.permissions.gerenciamento_campanhas || user?.roles === 'Client') && (
                <Link to={`campanha/editar/${campaign.id}`}>
                  <PencilSquareIcon className="size-5 text-zinc-400 cursor-pointer hover:opacity-85 m-2" />
                </Link>
              )}
              {campaign.status === 'ATIVA' && (
                <>
                  {showUrl && (
                    <span className="text-xs text-zinc-400 border border-zinc-400 px-1">
                      {
                        url[campaign.id]
                      }
                    </span>
                  )}
                  <Button
                    label=""
                    Icon={ShareIcon}
                    iconClass="!text-gray-400 hover:opacity-85"
                    type="button"
                    customClass="bg-transparent border-0 shadow-none hover:!bg-transparent"
                    onClick={() => {
                      navigator.clipboard.writeText(url[campaign.id]);
                      setShowUrl(true);
                      toast.success('Convite copiado com sucesso');
                    }}
                  />
                </>
              )}
            </div>

            <div className="flex flex-col p-4 pb-0 items-center w-full border-b-2 border-solid">
              <img src={campaign?.imagem === '' ? imgPlaceholder : campaign?.imagem} className="max-h-[600px]" alt="placeholder" />
            </div>
            <div className="w-full px-4">
              <div className="w-full flex justify-between px-8">
                <p className="text-sm">{campaign?.titulo}</p>
                {/* <p className="text-sm">
                    0% de
                    {' '}
                    {campaign.quantidade_numeros}
                    {' '}
                    bilhetes
                  </p> */}
              </div>
              {/* <div className="w-full rounded-full h-1.5  dark:bg-gray-700 bg-gray-300">
                  <div className="bg-gray-400 h-1.5 rounded-full dark:bg-blue-500 w-14" />
                </div> */}
            </div>
            <div className="flex sm:flex-col md:flex-row xl:grid-cols-2 gap-x-4 w-full px-4 mb-6">
              {
                campaign.status === 'ATIVA' && <Button label="Publicada" customClass="bg-primary-700 hover:bg-primary-500 sm:mt-4 xl:mt-0 w-full" />
              }
            </div>
          </div>
        ))
      }
    </>

  );
}

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import InfiniteScrollComponent from 'components/InfiniteScroll';
import coinImg from '../../../assets/img/coin.gif';
import { Button } from '../../../components/Button';
import { Awards } from '../../../interfaces';

interface ModalProps{
    toggleModal: boolean,
    setOpen: (value:boolean) => void
    awards: Awards[] | undefined,
    next: () => void
    hasMore: boolean
}
function ModalCotas({
  toggleModal, setOpen, awards, next, hasMore,
}:ModalProps) {
  return (
    <Transition.Root show={toggleModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-primary-900 px-4 pb-4 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm md:max-w-[500px] sm:p-4">
                {awards && (
                  <div className="flex flex-col items-center gap-4">
                    <div className="flex gap-x-4">
                      <img src={coinImg} className="size-10 mt-5" alt="" />
                      <img src={coinImg} className="size-10" alt="" />
                      <img src={coinImg} className="size-10 mt-5" alt="" />
                    </div>
                    <div className="text-center w-full p-0 pt-0 rounded-xl text-white relative">
                      <div
                        className="flex items-center overflow-y-auto flex-col w-full max-h-[400px]"
                        id="scrollableDiv"
                      >
                        <h5 className="text-white font-semibold text-3xl my-2">Cotas Prêmiadas</h5>
                        <InfiniteScrollComponent
                          fetchData={next}
                          hasMore={hasMore}
                          items={awards}
                        />

                      </div>
                    </div>
                    <div className="flex mt-4 justify-center gap-4 w-full">
                      <Button
                        customClass="bg-primary-700 hover:bg-primary-500 disabled:!bg-slate-50 disabled:!text-slate-500 disabled:cursor-not-allowed"
                        label="Fechar"
                        onClick={() => {
                          setOpen(false);
                        }}
                      />
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { ModalCotas };

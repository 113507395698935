import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    label: string
    errorMessage: string | undefined,
    customClass?: string
}
function TextArea({
  label,
  name,
  id,
  placeholder,
  value,
  required,
  errorMessage,
  onChange,
  customClass,
  defaultValue,
}: InputProps) {
  return (
    <div>
      <label
        htmlFor=""
        className="block text-sm font-medium leading-6 text-gray-600"
      >
        {label}
      </label>
      <div>
        <textarea
          value={value ?? defaultValue}
          name={name}
          id={id}
          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
          focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6 ${customClass}`}
          placeholder={placeholder}
          required={required}
          rows={5}
          onChange={onChange}
        />
        {errorMessage && <p className="text-rose-500 mt-0">{errorMessage}</p> }
      </div>
    </div>
  );
}

TextArea.defaultProps = {
  customClass: '',
};

export { TextArea };

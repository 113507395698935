import AppStore from '../../../../assets/img/app_store.png';
import GooglePlay from '../../../../assets/img/google_play.png';

type Store = 'apple' | 'google'
interface StoreButtonProps {
  store: Store
  customClass?: string
}
type StoreObject = {
  name: string,
  link: string,
  image: string,
  alt: string,
  color: string,
}

const linkStore: Record<Store, StoreObject> = {
  apple: {
    name: 'App Store',
    link: 'https://apps.apple.com/br/app/google-authenticator/id388497605',
    image: AppStore,
    alt: '',
    color: 'bg-orange-400',
  },
  google: {
    name: 'Google Play',
    link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR',
    image: GooglePlay,
    alt: '',
    color: 'bg-slate-800',
  },
};

export function StoreButton({ store, customClass }: StoreButtonProps) {
  const {
    link, image, alt, name, color,
  } = linkStore[store];
  return (
    <a href={link} target="_blank" className={`flex items-center justify-center ${color} w-48 py-1 rounded-md gap-4 ${customClass}`} rel="noreferrer">
      <img className={`${store === 'google' ? 'h-8' : 'h-10'} ${store === 'google' && 'w-8'}`} src={image} alt={alt} />
      <div className="text-white">
        <span className="text-xs">
          Disponível
          {store === 'apple' ? ' na' : ' no'}
        </span>
        <h1 className="font-bold text-lg">{name}</h1>
      </div>
    </a>
  );
}

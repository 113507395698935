/* eslint-disable max-len */
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Cota } from 'interfaces';
import { v4 as uuidV4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { getUserData, isAdmin } from 'utils';

interface ManualPrizeProps {
  cotas: Cota[];
  setCotas: React.Dispatch<React.SetStateAction<Cota[]>>
  setOpenCotaPremiada: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedCota: React.Dispatch<React.SetStateAction<Cota>>
  campaignId: string
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  isLastPage: boolean
  saveAward: (cota: Cota) => void
  totalPages: number
  uuidGenerated: string
}

export default function ManualPrize({
  cotas, setCotas, setOpenCotaPremiada, setSelectedCota, campaignId,
  page, setPage, isLastPage, saveAward, totalPages, uuidGenerated,
}: ManualPrizeProps) {
  const user = getUserData();
  const [awardPosition, setAwardPosition] = useState(5);
  const [endSectionPage, setEndSectionPage] = useState(3);
  const [startSectionPage, setStartSectionPage] = useState(1);

  useEffect(() => {
    const cota = cotas.find((value) => value.id === uuidGenerated);
    setAwardPosition(page * 5 - (cota === undefined ? (5 - cotas.length) : 5));
  }, [page, cotas]);

  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { value, name } = event.currentTarget;
    const updatedCota = cotas.map((cota) => {
      if (cota.id === id) {
        return {
          ...cota,
          [name]: value,
          isUpdated: true,
        };
      }
      return cota;
    }) as Cota[];
    setCotas(updatedCota);
  };

  const handleScheduledText = (cota: Cota) => {
    if (cota.user_name) return 'Sorteado';
    return 'Editar';
  };

  const isDisabledButton = (cota: Cota) => !cota.description
    || !cota.number
    || Boolean(cota.user_name);

  const handleEnableEdit = (cota: Cota) => {
    const updatedCotas = cotas.map((cotaValue) => {
      if (cotaValue.id === cota.id) {
        return {
          ...cotaValue,
          isEditable: true,
        };
      }
      return {
        ...cotaValue,
        isEditable: false,
      };
    });
    setCotas(updatedCotas);
  };

  const handleDisableEdit = () => {
    const updatedCotas = cotas.map((cotaValue) => ({
      ...cotaValue,
      isEditable: false,
    }));
    setCotas(updatedCotas);
  };

  const addCota = () => {
    const id = (cotas.length + 1).toString();
    const numberOfNewCotas = cotas.filter((value) => value.isNew).length;
    if (numberOfNewCotas === 10) {
      toast.info('Não é possível adicionar mais cotas o limite é de 10');
      return;
    }
    handleDisableEdit();
    setCotas((prevState) => ([
      {
        id: uuidV4(),
        description: '',
        name: `Cota nº ${id}`,
        number: '',
        rifa_id: campaignId,
        sorteado: false,
        isNew: true,
        isUpdated: false,
        isEditable: true,
      },
      ...prevState,
    ]));
  };

  const removeCota = (cota: Cota) => {
    setCotas((prevState) => {
      const filteredCotas = prevState.filter((cotaValue) => cotaValue !== cota);
      return filteredCotas;
    });
  };

  return (
    <div className="py-4">
      <div className="flex gap-4">
        <Button
          label="Adicionar prêmio"
          customClass="w-full my-4 bg-primary-700 hover:bg-primary-500"
          onClick={addCota}
        />
      </div>

      {
        cotas.map((cota, index) => (
          <>
            <div key={cota.id} className="flex pt-2 gap-2 items-end">
              <Input customClass="disabled:bg-gray-100" name="description" placeholder="Informe a Cota" defaultValue={cota.description} label={`${awardPosition - (cotas.length - (index + 1))}ª Cota`} disabled={!cota.isEditable} type="text" errorMessage="" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInput(event, cota.id)} />
              <Input customClass="disabled:bg-gray-100" name="number" placeholder="Informe o Nº da Cota" defaultValue={cota.number} label="Nº da Cota" disabled={!cota.isEditable} type="text" errorMessage="" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInput(event, cota.id)} />
              {
                user && isAdmin(user.roles) && (
                  <>
                    {
                      !cota.isEditable ? (
                        <Button
                          label={handleScheduledText(cota)}
                          disabled={Boolean(cota.user_name)}
                          customClass="bg-primary-700 hover:bg-primary-500 disabled:!bg-slate-50 disabled:!text-slate-500 disabled:cursor-not-allowed"
                          onClick={() => handleEnableEdit(cota)}
                        />
                      ) : (
                        <>
                          <Button
                            label=""
                            Icon={CheckIcon}
                            iconClass="!mr-0"
                            customClass="!bg-green-500 !px-2 disabled:!bg-slate-400 disabled:cursor-not-allowed"
                            disabled={isDisabledButton(cota)}
                            onClick={() => {
                              saveAward(cota);
                              handleDisableEdit();
                            }}
                          />
                          <Button
                            label=""
                            Icon={XMarkIcon}
                            iconClass="!mr-0"
                            customClass="!bg-rose-500 !px-2"
                            onClick={() => {
                              if (cota.isNew) removeCota(cota);
                              else handleDisableEdit();
                            }}
                          />
                        </>
                      )
                    }
                    <Button
                      customClass="items-center h-9 !bg-green-600 focus-visible:outline-green-500 hover:!bg-green-500 disabled:!bg-slate-50 disabled:!text-slate-500 disabled:cursor-not-allowed cursor-pointer"
                      label="Premiar"
                      disabled={isDisabledButton(cota)}
                      onClick={() => {
                        setOpenCotaPremiada(true);
                        setSelectedCota(cota);
                      }}
                    />
                  </>
                )
              }
            </div>
            {cota.user_name && (
              <span className="text-gray-500 text-xs italic">
                Cliente sorteado:
                {' '}
                {cota.user_name}
              </span>
            )}

          </>
        ))
      }
      <div className="flex mt-4 justify-center gap-4 w-full">
        <Button
          customClass="bg-primary-700 hover:bg-primary-500 disabled:!bg-slate-50 disabled:!text-slate-500 disabled:cursor-not-allowed"
          label="Anterior"
          disabled={page === 1}
          onClick={() => {
            setPage((prevState) => prevState - 1);
            if (startSectionPage === page) {
              setStartSectionPage((prevState) => prevState - 3);
              setEndSectionPage((prevState) => prevState - 3);
            }
          }}
        />
        {
          endSectionPage !== 3 && (
            <Button
              customClass="bg-primary-700 hover:bg-primary-500"
              label="..."
              onClick={() => {
                setPage(startSectionPage - 1);
                setStartSectionPage((prevState) => prevState - 3);
                setEndSectionPage((prevState) => prevState - 3);
              }}
            />
          )
        }
        {
          pages.map((numberPage) => (numberPage >= startSectionPage && numberPage <= endSectionPage) && (
            <Button
              customClass={`bg-primary-700 hover:bg-primary-500 ${numberPage === page && 'underline'}`}
              label={numberPage}
              onClick={() => {
                setPage(numberPage);
              }}
            />
          ))
        }
        {
          !isLastPage && (
            <Button
              customClass="bg-primary-700 hover:bg-primary-500"
              label="..."
              onClick={() => {
                setPage(endSectionPage + 1);
                setStartSectionPage((prevState) => prevState + 3);
                setEndSectionPage((prevState) => prevState + 3);
              }}
            />
          )
        }

        <Button
          customClass="bg-primary-700 hover:bg-primary-500 disabled:!bg-slate-50 disabled:!text-slate-500 disabled:cursor-not-allowed"
          label="Próximo"
          disabled={isLastPage}
          onClick={() => {
            setPage((prevState) => prevState + 1);
            if (endSectionPage === page) {
              setStartSectionPage((prevState) => prevState + 3);
              setEndSectionPage((prevState) => prevState + 3);
            }
          }}
        />

      </div>
    </div>

  );
}

import React from 'react';
import './App.css';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { PrimeReactProvider } from 'primereact/api';
import router from './Routes';
import { UserContextProvider } from './context/GlobalContext';
import { CampaignsContextProvider } from './context/CampaignsContext';

function App() {
  return (
    <UserContextProvider>
      <CampaignsContextProvider>
        <PrimeReactProvider>
          <ToastContainer autoClose={5000} />
          <RouterProvider router={router} />
          <SpeedInsights />
        </PrimeReactProvider>
      </CampaignsContextProvider>
    </UserContextProvider>
  );
}

export default App;

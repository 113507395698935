import {
  EyeIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'components/Button';
import { Select } from 'components/Select';
import React, { useState, useEffect } from 'react';
import { formatDate } from 'date-fns';
import { useCampaignsContext } from '../../context/CampaignsContext';
import InvoiceTable from './components/InvoiceTable';
import useGet from '../../hooks/useGet';
import { Calculated } from '../../interfaces';
import Loading from '../../components/Loading';
import { DateRangepicker } from '../../components/DateRangePicker';

export default function InvoicePage() {
  const { getCampaignsCallback, getCampaigns } = useCampaignsContext();
  const [options, setOptions] = useState<{name: string, value: string}[]>();
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [invoiceByUuid, setInvoiceByUuid] = useState<Calculated[]>();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const dateRange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const { request: getInvoiceByUuid } = useGet();
  const onChange = (event: React.ChangeEvent<
        HTMLInputElement |
        HTMLSelectElement |
        HTMLTextAreaElement>) => {
    const { value, name } : any = event.currentTarget;
    if (name === 'campaign') {
      setSelectedCampaign(value);
    }
  };

  const getSelectedCampaign = () => {
    if (selectedCampaign) {
      setLoading(true);
      let url = `rifa/calculated/values/${selectedCampaign}`;
      if (startDate) {
        url = `${url}?dateStart=${formatDate(startDate, 'yyyy-MM-dd')}`;
      }
      if (endDate) {
        url = `${url}&dateEnd=${formatDate(endDate, 'yyyy-MM-dd')}`;
      }
      getInvoiceByUuid(url, (response) => setInvoiceByUuid(response.calculated));
      setLoading(false);
    }
  };

  useEffect(() => {
    getCampaignsCallback();
  }, [getCampaignsCallback]);

  useEffect(() => {
    const campaign: {name: string, value: string}[] = [{ name: 'Selecione uma campanha', value: '0' }];
    getCampaigns.map((value) => campaign.push({ value: value.id, name: value.titulo }));
    setOptions(campaign);
  }, [getCampaigns]);

  return (
    <>
      <Loading open={loading} />
      <div className="bg-white px-4 py-5 shadow-md sm:rounded-xl pb-12">
        <div className="flex gap-2 items-center">
          <h1 className="font-semibold text-xl">Relatório financeiro</h1>
        </div>
        <div className="flex sm:flex-col md:flex-row items-center gap-4 mt-6 ">
          <div className="w-[300px]">
            <Select
              name="campaign"
              label="Selecione a campanha"
              customClass="w-full p-2"
              options={options}
              onChange={onChange}
            />
          </div>
          <div className="flex gap-3 items-center justify-center">
            <div className="relative group">
              <DateRangepicker
                errorMessage=""
                onChange={dateRange}
                startDate={startDate}
                endDate={endDate}
                containerClass="mt-1"
                label="Selecione o período"
                name="datepicker"
              />
            </div>
          </div>
          <div className="flex gap-3 items-center justify-center">
            <div className="relative group">
              <Button
                label="Consultar"
                customClass="mt-7 py-2 bg-primary-700 hover:bg-primary-500"
                onClick={getSelectedCampaign}
              />
            </div>
          </div>
          <div className="flex gap-3 items-center justify-center">
            <div className="relative group hidden">
              <Button
                label="Dados"
                customClass="mt-7 pl-10 h-10 bg-transparent !text-gray-700 group-hover:!text-white border border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 group-hover:bg-primary-700"
              />
              <EyeIcon className="absolute top-[37px] left-4 size-5 group-hover:text-white" />
            </div>
          </div>
        </div>
      </div>

      {invoiceByUuid
          && (
          <div className="bg-white px-4 py-5 shadow-md sm:rounded-xl pb-12 mt-8">
            <InvoiceTable
              calculated={invoiceByUuid}
            />
          </div>
          )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { formatValue } from '../../../components/Currency';
import { Purchases } from '../../../interfaces';
import { Select } from '../../../components/Select';

interface PurchaseProps {
  purchases: Purchases[] | undefined;
}

const PurchasesTable = ({ purchases }: PurchaseProps) => {
  const [afiliates, setAfiliates] = useState<{ name: string; value: string }[]>([]);
  const [selectedAfiliate, setSelectedAfiliate] = useState<string>('');

  useEffect(() => {
    if (purchases) {
      const options: { name: string; value: string }[] = [{ name: 'Mostrar todos', value: '0' }];
      purchases.forEach((purchase) => {
        if (!options.find((option) => option.value === purchase.nome_afiliado)) {
          options.push({
            value: purchase.nome_afiliado,
            name: purchase.nome_afiliado,
          });
        }
      });
      setAfiliates(options);
    }
  }, [purchases]);

  const filteredPurchases = selectedAfiliate && selectedAfiliate !== '0'
    ? purchases?.filter((purchase) => purchase.nome_afiliado === selectedAfiliate)
    : purchases;

  const sortedPurchases = filteredPurchases?.slice()
    .sort((a, b) => b.quantidade_numeros - a.quantidade_numeros);

  return (
    <div className="px-4 sm:px-6 lg:px-8 overflow-y-auto max-h-[900px]">
      <div className="-mx-4 mt-4 flow-root sm:mx-0">
        <div>
          <Select
            label="Filtrar por afiliado"
            options={afiliates}
            value={selectedAfiliate}
            onChange={(e) => setSelectedAfiliate(e.target.value)}
          />
        </div>
        <table className="min-w-full">
          <colgroup>
            <col className="w-full sm:w-1/2" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
          </colgroup>
          <thead className="border-b border-gray-300 text-gray-900">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Nome
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                E-mail
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Telefone
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Porcentagem
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Porcentagem Calculada
              </th>
              <th scope="col" className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                Bilhetes vendidos
              </th>
              <th scope="col" className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedPurchases && sortedPurchases.map((purchase) => (
              <tr key={purchase.email_afiliado} className="border-b border-gray-200 ">
                <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                  <div className="font-medium text-gray-900">{purchase.nome_afiliado}</div>
                </td>
                <td className="flex justify-between px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">
                  <span>{purchase.email_afiliado}</span>
                </td>
                <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">{purchase.telefone_afiliado}</td>
                <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">
                  {purchase.porcentagem}
                  %
                </td>
                <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">
                  {formatValue(purchase.porcentagem_calculada.toString())}
                </td>
                <td className="hidden px-3 py-5 text-left text-sm text-gray-500 sm:table-cell">{purchase.quantidade_numeros}</td>
                <td className="py-5 pl-3 pr-4 text-left text-sm text-gray-500 sm:pr-0">{formatValue(purchase.pago_valor.toString())}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { PurchasesTable };

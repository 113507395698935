import React, { InputHTMLAttributes } from 'react';
import ReactInputMask from 'react-input-mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  errorMessage: string | undefined
}
function PhoneInput({
  label,
  name,
  id,
  placeholder = '(99) 9999-9999',
  onChange,
  value,
  errorMessage,
  defaultValue,
}: InputProps) {
  return (
    <div>
      <label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-600">
        {label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <ReactInputMask
          mask="(99) 99999-9999"
          type="text"
          name={name}
          id={id}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          defaultValue={value ?? defaultValue}
          onChange={onChange}
        />
      </div>
      {errorMessage && <p className="text-rose-500 mt-0">{errorMessage}</p> }
    </div>
  );
}

export { PhoneInput };

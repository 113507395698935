import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/Button';
import { CheckBox } from 'components/CheckBox';
import { Input } from 'components/Input';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// import { toast } from 'react-toastify';
import {
  date,
  InferType, number, object,
  string,
} from 'yup';

const prizesSchema = object({
  quantidade_numeros: number().required(),
  schedule_to: date(),
  name: string().required(),
});

export type PrizesData = InferType<typeof prizesSchema>
interface AutomaticPrizesProps {
  onSubmitHandler: (data: PrizesData) => void
}

export default function AutomaticPrizes({ onSubmitHandler }: AutomaticPrizesProps) {
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState('');
  const {
    formState: { errors }, handleSubmit, setValue, watch,
  } = useForm({
    resolver: yupResolver(prizesSchema),
  });
  const quantidadeNumeros = watch('quantidade_numeros') ? watch('quantidade_numeros') : 0;

  const onChange = (event: React.ChangeEvent<
      HTMLInputElement |
      HTMLSelectElement |
      HTMLTextAreaElement>) => {
    const { value, name } : any = event.currentTarget;
    setValue(name, value);
  };
  return (
    <form
      className="space-y-6 mt-6"
      action="#"
      method="POST"
      onSubmit={handleSubmit((data: PrizesData) => {
        if (!checked) {
          setCheckedError('É necessário aceitar para prosseguir');
          return;
        }
        if (quantidadeNumeros > 1000) {
          toast.error('O máximo de cotas automáticas é 1000');
          return;
        }
        onSubmitHandler(data);
      })}
    >
      <div className="flex gap-4">
        <Input placeholder="Informe a Cota" onChange={onChange} name="name" label="Prêmio" type="text" errorMessage={errors.name?.message} />
        <Input placeholder="Informe o Nº de Cotas" onChange={onChange} name="quantidade_numeros" label="Quantidade de cotas" type="text" errorMessage={errors.quantidade_numeros?.message} />
      </div>
      <CheckBox
        checked={checked}
        onChange={() => {
          setChecked((prevState) => !prevState);
        }}
        customClass="!mt-2"
        label={`Aceito gerar ${quantidadeNumeros} cota(s) automaticamente`}
      />
      <span className="text-rose-500 mt-0 text-sm">{checkedError}</span>
      <Button label="Gerar" type="submit" customClass="bg-primary-700 hover:bg-primary-500 w-full mt-10 flex-row-reverse gap-2" />
    </form>
  );
}

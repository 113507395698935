export const TOKEN_KEY = '@Code:JWT_TOKEN';
export const USER_DATA = '@USER_DATA';
export const PAYMENT_VALUE = '@PAYMENT_VALUE';
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_DATA);
  localStorage.removeItem(PAYMENT_VALUE);
};
export const getUser = () => localStorage.getItem(USER_DATA);

export const validateUserObject = () => {
  const userData = getUser();
  if (userData) {
    return JSON.parse(userData);
  } return {};
};
export const setUserToStorage = (data: any) => localStorage.setItem(
  USER_DATA,
  JSON.stringify(data),
);

export const setPaymentValue = (value: string) => localStorage.setItem(
  PAYMENT_VALUE,
  value,
);

export const getPaymentValue = () => localStorage.getItem(PAYMENT_VALUE);

export const removePaymentValue = () => localStorage.removeItem(PAYMENT_VALUE);

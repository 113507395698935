import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { CheckBox } from 'components/CheckBox';
import { PhoneInput } from 'components/PhoneInput';
import { FireIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import {
  boolean,
  date, InferType, number, object, string,
} from 'yup';
import { getUserData } from 'utils';
import { useUserContext } from 'context/GlobalContext';
import { TextArea } from '../../../components/TextArea';
import { Select } from '../../../components/Select';
import { FileInput } from '../../../components/FileInput';
import Loading from '../../../components/Loading';
import { useCampaignsContext } from '../../../context/CampaignsContext';
import ModalConfirmCampaign from '../Components/ModalConfirmCampaign';

const campaignSchema = object({
  titulo: string().required('Nome da rifa é obrigatorio'),
  descricao: string().required('Digite uma descrição valida'),
  quantidade_numeros: number().required('Quantidade invalida').default(1000),
  preco_numero: string().required('Preço invalido'),
  data_inicio: date().required('Digite o inicio do sorteio'),
  data_encerramento: date().nullable('Digite o inicio do sorteio'),
  data_sorteio: date().nullable('Digite o inicio do sorteio'),
  status: string().required('Defina o status do sorteio').default('CRIADA'),
  celular_suporte: string().required('O telefone não é valido'),
  qntd_min: number().nullable('Quantidade minima de bilhetes não é válido'),
  mostrar_top: string().required('Defina uma opção').default('NONE'),
  mostrar_porcentagem_vendas: boolean().default(false),
  pix_client_id: string(),
  pixel_id: string(),
  pix_secret_id: string(),
  chave_pix: string(),
  titulo_pagina: string(),
});

type Campaign = InferType<typeof campaignSchema>

const options = [
  {
    value: 1000,
    name: '0-1000',
  },
  {
    value: 10000,
    name: '1000-10.000',
  },
  {
    value: 100000,
    name: '10.000-100.000',
  },
  {
    value: 1000000,
    name: '100.000-1.000.000',
  },
  {
    value: 10000000,
    name: '1.000.000-10.000.000',
  },
];

const status = [
  {
    value: 'CRIADA',
    name: 'Criada',
  },
  {
    value: 'ATIVA',
    name: 'Ativa',
  },
  {
    value: 'CONCLUIDA',
    name: 'Concluida',
  },
];

const topBuyers = [
  {
    value: 'NONE',
    name: 'Não exibir',
  },
  {
    value: 'TOP3',
    name: 'Exibir o Top 3',
  },
  {
    value: 'TOP5',
    name: 'Exibir o Top 5',
  },
];

export default function NewCampaign() {
  const [image, setImage] = useState<File | null>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const [activatePixel, setActivatePixel] = useState<boolean>(false);
  const { newCampaignCallback, newCampaignLoading } = useCampaignsContext();
  const [openConfirmCampaign, setOpenConfirmCampaign] = useState(false);
  const [campaign, setCampaign] = useState<Campaign | undefined>(undefined);
  const navigate = useNavigate();
  const {
    setValue, formState: { errors }, handleSubmit, watch,
  } = useForm({
    resolver: yupResolver(campaignSchema),
  });
  const { listPermissionsCallback, permissions } = useUserContext();

  const onChange = (event: React.ChangeEvent<
      HTMLInputElement |
      HTMLSelectElement |
      HTMLTextAreaElement>) => {
    const { value, name } : any = event.currentTarget;
    setValue(name, value);
  };

  const onSubmitHandler = (data: Campaign) => {
    setOpenConfirmCampaign(true);
    setCampaign(data);
  };

  const confirmCampaign = () => {
    const formData = new FormData();

    Object.keys(campaign as any).forEach((key) => {
      formData.append(key, (campaign as any)[key]);
    });
    if (image) {
      formData.append('file', image);
    }
    if (logo) {
      formData.append('logo', logo);
    }
    newCampaignCallback(formData, () => { navigate('/app'); });
  };

  useEffect(() => {
    const data = getUserData();
    listPermissionsCallback(data.id, () => {});
  }, []);

  return (
    <div className="w-full flex flex-col">
      <Loading open={(newCampaignLoading)} />
      <div className="flex items-center gap-1">
        <FireIcon className="size-5" />
        <h1 className="text-xl font-semibold">Criar campanha</h1>
      </div>
      <div>
        <form className="space-y-6 mt-6" action="#" method="POST" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="flex flex-col gap-5">
            <div className="grid md:grid-cols-2 gap-4">
              <Input label="Nome da campanha" name="titulo" onChange={onChange} errorMessage={errors.titulo?.message} />
              <Input label="Título da campanha" name="titulo_pagina" onChange={onChange} errorMessage={errors.titulo_pagina?.message} />
            </div>
            <TextArea label="Descrição da campanha" customClass="" name="descricao" onChange={onChange} errorMessage={errors.descricao?.message} />
            <Select label="Quantidade de bilhetes" options={options} name="quantidade_numeros" onChange={onChange} errorMessage={errors.quantidade_numeros?.message} />
            <Input label="Preço" type="text" name="preco_numero" onChange={onChange} errorMessage={errors.preco_numero?.message} />
            <FileInput name="Imagem da plataforma" image={logo} setImage={setLogo} />
            <FileInput image={image} setImage={setImage} />
            <div className="grid md:grid-cols-3 gap-4 md:max-w-[600px]">
              <Input type="datetime-local" label="Data de inicio" name="data_inicio" onChange={onChange} errorMessage={errors.data_inicio?.message} />
              <Input type="datetime-local" label="Data de encerramento" name="data_encerramento" onChange={onChange} errorMessage={errors.data_encerramento?.message} />
              <Input type="datetime-local" label="Data do sorteio" name="data_sorteio" onChange={onChange} errorMessage={errors.data_sorteio?.message} />
            </div>
            <Input label="Quantidade mínima de bilhetes por compra" type="number" name="qntd_min" errorMessage={errors.qntd_min?.message} />
            <div className="grid md:grid-cols-3 gap-4 md:max-w-[600px]">
              <Select customClass="w-full" label="Status" options={status} name="status" onChange={onChange} errorMessage={errors.status?.message} />
              <Select customClass="w-full" label="Top compradores" options={topBuyers} name="mostrar_top" onChange={onChange} errorMessage={errors.mostrar_top?.message} />
              <CheckBox id="show_percentage" label="Mostrar porcentagem de vendas da campanha?" name="mostrar_porcentagem_vendas" customClass="mt-5" onChange={(e) => setValue('mostrar_porcentagem_vendas', e.target.checked)} />
            </div>
            <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-4">
              <PhoneInput label="Telefone / WhatsApp para suporte" name="celular_suporte" onChange={onChange} errorMessage={errors.celular_suporte?.message} />
            </div>

            {
              permissions.permissions.pixel && (
                <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-4">
                  <CheckBox id="activate_pixel" checked={activatePixel} label="Ativar o Pixel para essa campanha?" name="activate_pixel" onChange={(e) => setActivatePixel(e.target.checked)} />
                  {
                      activatePixel && (
                        <Input
                          type="text"
                          label="Identificação do conjunto de dados do Pixel"
                          onChange={onChange}
                          name="pixel_id"
                          errorMessage=""
                        />
                      )
                    }
                </div>
              )
            }

            {
              permissions.permissions.credenciais_pix && (
                <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
                  <Input
                    type="text"
                    label="Chave Pix"
                    onChange={onChange}
                    name="chave_pix"
                    errorMessage=""
                  />
                  <Input
                    type="text"
                    label="Pix Client Id"
                    onChange={onChange}
                    name="pix_client_id"
                    errorMessage=""
                  />
                  <Input
                    type="text"
                    label="Pix Secret Id"
                    onChange={onChange}
                    name="pix_secret_id"
                    errorMessage=""
                  />
                </div>
              )
            }
          </div>
          <div className="flex items-center">
            <CheckBox label="" name="remember-me" />
            <div className="text-sm leading-6">
              <p>
                Ao se criar esta campanha, você aceita nossos
                <a
                  href="/#"
                  className="text-gray-900 mx-1 hover:text-indigo-500 text-decoration-line: underline"
                >
                  Termos de Uso
                </a>
                e a nossa
                <a
                  href="/"
                  className="text-gray-900 mx-1 hover:text-indigo-500 text-decoration-line: underline"
                >
                  Política de
                  Privacidade.
                </a>
              </p>
            </div>
          </div>
          <div>
            <Button label="Continuar" type="submit" customClass="bg-primary-700 hover:bg-primary-500 w-44 mt-10 flex flex-row-reverse gap-2" Icon={ArrowRightIcon} />
          </div>
        </form>
      </div>
      <ModalConfirmCampaign
        setOpen={setOpenConfirmCampaign}
        open={openConfirmCampaign}
        quantityNumbers={Number.parseFloat(watch('quantidade_numeros')?.toString() ?? '1000')}
        onConfirm={confirmCampaign}
      />
    </div>
  );
}

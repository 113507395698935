import { useState, useCallback } from 'react';
import { api } from '../api/api';
import { removeToken } from '../services/localstorageService';

interface UseGetResponse {
  loading: boolean
  error: Error | null
  request: (url: string, callback: (data: any) => void) => any
}

const useGet = (): UseGetResponse => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (url: string, callback: (data: any) => void) => {
      try {
        setLoading(true);
        const response = await api.get(`api/${url}`);
        callback(response.data);
      } catch (requestError: any) {
        console.log(requestError);
        if (requestError.response.status === 403) {
          removeToken();
        }
        setError(requestError);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return { request, loading, error };
};

export default useGet;

import React, { useEffect, useRef, useState } from 'react';

interface PinInputProps {
  setValue: (value: string) => void
  callback: () => void
}

let currentOTPIndex: number = 0;
export function PinInput({ setValue, callback }: PinInputProps) {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const newOTP: string[] = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);

    setOtp(newOTP);
    setValue(newOTP.join(''));
  };

  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    currentOTPIndex = index;
    if (e.key === 'Backspace') setActiveOTPIndex(currentOTPIndex - 1);
    if (e.key === 'Enter') callback();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  return (
    <div className="flex justify-center items-center space-x-2">
      {otp.map((_, index) => (
        <>
          <input
            ref={activeOTPIndex === index ? inputRef : null}
            type="number"
            className="w-12 h-14 border-2 rounded bg-transparent outline-none text-center font-semibold text-xl shadow-sm spin-button-none ring-gray-300 focus:ring-inset focus:ring-primary-500 focus:text-gray-700 text-gray-900 transition [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            onChange={handleOnChange}
            onKeyDown={(e) => handleOnKeyDown(e, index)}
            value={otp[index]}
          />
          {index === otp.length - 1 ? null : (
            <span className="w-2 py-0.5 bg-gray-400" />
          )}
        </>
      ))}
    </div>
  );
}

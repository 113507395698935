import { Button } from '../../../components/Button';
import mercadoPago from '../../../assets/img/mlivreimg.svg';
import pix from '../../../assets/img/piximg.svg';

function PayMentMethod() {
  return (
    <>
      <div className="text-left mt-10">
        <div className="rounded-xl shadow p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white">
          <div className="flex gap-2 justify-between flex-col">
            <div className="flex flex-col gap-3 w-full">
              <div className="flex items-center gap-2">
                <img src={mercadoPago} className="size-10" alt="" />
                <h3 className="font-semibold text-xl text-gray-900">Mercado Pago</h3>
              </div>
              <p className="text-sm">
                Este meio de pagamento libera automaticamente os pedidos e cobra uma
                <span className="block font-bold text-gray-900">Taxa de 0,99% por transação</span>
              </p>
            </div>
          </div>
          <Button label="Abrir" customClass="bg-primary-700 hover:bg-primary-500 mt-10" />
        </div>
      </div>
      <div className="text-left mt-10">
        <div className="rounded-xl shadow p-4 border border-gray-300 border-transparent transition-all duration-150 ease-in-out bg-white">
          <div className="flex gap-2 justify-between flex-col">
            <div className="flex flex-col gap-3 w-full">
              <div className="flex items-center gap-2">
                <img src={pix} className="size-10" alt="" />
                <h3 className="font-semibold text-xl text-gray-900">Pix</h3>
              </div>
              <p className="text-sm md:flex">
                Pedidos com este meio de pagamento
                <span className="block font-bold text-red-500 px-1">NÃO liberam automaticamente</span>
                , você terá que liberar manualmente.
              </p>
            </div>
          </div>
          <Button label="Abrir" customClass="bg-primary-700 hover:bg-primary-500 mt-10" />
        </div>
      </div>
    </>
  );
}

export { PayMentMethod };

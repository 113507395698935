/* eslint-disable consistent-return */
/* eslint-disable max-len */
import {
  PlusIcon, MinusIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import ReactPixel from 'react-facebook-pixel';
import useGet from 'hooks/useGet';
import { PurchaseModal } from '../Components/PurchaseModal';
import Loading from '../../../components/Loading';
import { getUserData, padNumber } from '../../../utils';
import { formatValue } from '../../../components/Currency';
import { useUserContext } from '../../../context/GlobalContext';
import { ModalCotas } from '../Components/ModalCotas';
import { Awards, AwardWinner, Promo } from '../../../interfaces';
import coinImg from '../../../assets/img/coin.gif';
import trofeuImg from '../../../assets/img/trofeus.png';
import { useCampaignsContext } from '../../../context/CampaignsContext';
import { AlertMessage } from '../../../components/AlertMessage';

// const BRL = new Intl.NumberFormat('pt-BR', {
//   style: 'currency',
//   currency: 'BRL',
// });

export default function CampaignAvailable() {
  const { openLoginModal } = useUserContext();
  const {
    listAwardsByCampaignCallback,
    getCampaignBySharedId,
    campaignBySharedId,
    errorCampaignBySharedId,
    campaignBySharedIdLoading,
    getPurchaseTop5Details,
    getPurchaseTop5Callback,
    getCampaignDetails,
    getCampaignDetailCallback,
    promos,
  } = useCampaignsContext();
  const { sharedId } = useParams();
  const [options, setOptions] = useState<number[]>([2, 5, 10, 50, 100, 200]);
  const [minNumbers, setMinNumbers] = useState(10);
  const [numbers, setNumbers] = useState(10);
  const [promoqtd, setPromoqtd] = useState(0);
  const user = getUserData();

  const [open, setOpen] = useState(false);
  const [openModalCotas, setOpenModalCotas] = useState(false);
  const [cotas, setCotas] = useState<Awards[]>();
  const [page, setPage] = useState(2);
  const [isLastPage, setIsLastPage] = useState(false);
  const [paymentData, setPaymentData] = useState<{
    shared_id: string
    sorteio_id: string
    valor: number
    user_id: string
    quantidade_numeros: number
    promoqtd: number
    selectedProm?: Promo
  }>({
    shared_id: '',
    sorteio_id: '',
    valor: 0,
    user_id: '',
    quantidade_numeros: 0,
    promoqtd: 0,
  });
  const {
    request: listWinnerAwardsRequest,
  } = useGet();
  const [selectedProm, setSelectedProm] = useState<Promo>();

  useEffect(() => {
    if (sharedId) {
      getCampaignBySharedId(sharedId);
      getPurchaseTop5Callback(sharedId);
      getCampaignDetailCallback(sharedId);
    }
  }, []);

  useEffect(() => {
    if (campaignBySharedId && campaignBySharedId.pixel_id) {
      ReactPixel.init(campaignBySharedId.pixel_id);
      ReactPixel.pageView();
    }
  }, [campaignBySharedId]);

  useEffect(() => {
    if (errorCampaignBySharedId) {
      toast.error(`${errorCampaignBySharedId}`);
    }
  }, [errorCampaignBySharedId]);

  const submit = () => {
    setPaymentData({
      shared_id: sharedId ?? '',
      sorteio_id: campaignBySharedId?.id ?? '',
      valor: (selectedProm !== undefined ? Number(selectedProm.valor) : (campaignBySharedId?.preco_por_numero ?? 1)),
      user_id: user?.id ?? '',
      quantidade_numeros: numbers,
      selectedProm,
      promoqtd,
    });
  };
  const changeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedProm !== undefined) {
      setNumbers(0);
      setPromoqtd(0);
      setSelectedProm(undefined);
    }
    const { value } = event.currentTarget;
    setNumbers(parseFloat(value));
  };

  const handleProgressBar = (maxNumber:number | undefined, numbersSold:string) => {
    if (maxNumber && numbersSold) {
      const percentageSold = (parseInt(numbersSold, 10) / maxNumber) * 100;
      return percentageSold.toFixed(2);
    }
    return '';
  };

  const qtdMaxNumber = campaignBySharedId?.quantidade_numeros;
  const qtdNumbersSold = getCampaignDetails.find((campaign) => campaign.id === campaignBySharedId?.id)?.quantidade_numeros_vendidos ?? '0';

  useEffect(() => {
    if (campaignBySharedId) {
      listAwardsByCampaignCallback({
        campaignId: campaignBySharedId.id,
        page: 1,
        total: 10,
        isAdminPanel: false,
      }, (response) => {
        const { awards: awardsReponse, is_last_page: isLastPageResponse } = response;
        setIsLastPage(isLastPageResponse);
        if (awardsReponse.length === 0) return;
        listWinnerAwardsRequest(`user/find/awards/winner/${campaignBySharedId.id}`, (responseWinner: AwardWinner) => {
          const { winners } = responseWinner;
          const cotasReponse: Awards[] = awardsReponse.map((value) => ({
            id: value.id,
            description: value.description,
            name: value.name,
            user_name: winners === null ? undefined : winners.find((winner) => winner.number === value.number)?.name,
            number: padNumber(value.number.toString(), campaignBySharedId.quantidade_numeros.toString().length - 1),
            rifa_id: value.rifa_id,
          }));
          setCotas(cotasReponse);
        });
      });
    }
  }, [campaignBySharedId]);

  const nextPage = () => {
    listAwardsByCampaignCallback({
      campaignId: campaignBySharedId!.id,
      page,
      total: 10,
      isAdminPanel: false,
    }, (response) => {
      const { awards: awardsReponse, is_last_page: isLastPageResponse } = response;
      setIsLastPage(isLastPageResponse);
      if (awardsReponse.length === 0) return;
      listWinnerAwardsRequest(`user/find/awards/winner/${campaignBySharedId!.id}`, (responseWinner: AwardWinner) => {
        const { winners } = responseWinner;
        const cotasReponse: Awards[] = awardsReponse.map((value) => ({
          id: value.id,
          description: value.description,
          name: value.name,
          user_name: winners === null ? undefined : winners.find((winner) => winner.number === value.number)?.name,
          number: padNumber(value.number.toString(), campaignBySharedId!.quantidade_numeros.toString().length - 1),
          rifa_id: value.rifa_id,
        }));
        setCotas((prevState) => ([
          ...prevState!,
          ...cotasReponse,
        ]));
        setPage((prevState) => prevState + 1);
      });
    });
  };

  useEffect(() => {
    setPaymentData({
      shared_id: sharedId ?? '',
      sorteio_id: campaignBySharedId?.id ?? '',
      valor: selectedProm === undefined ? campaignBySharedId?.preco_por_numero ?? 1 : Number(selectedProm.valor),
      user_id: user?.id ?? '',
      quantidade_numeros: selectedProm === undefined ? numbers : promoqtd,
      selectedProm,
      promoqtd,
    });
  }, [openLoginModal]);

  useEffect(() => {
    if (campaignBySharedId) {
      setNumbers(campaignBySharedId.qntd_min);
      setMinNumbers(campaignBySharedId.qntd_min);
      const qntd = 5 / campaignBySharedId.preco_por_numero;
      const aux = [qntd, qntd * 2, qntd * 3, qntd * 5, qntd * 10, qntd * 15, qntd * 25];
      const auxRounded = aux.map((value) => Math.round(value));
      setOptions(auxRounded);
      getPurchaseTop5Callback(campaignBySharedId?.id);
    }
  }, [campaignBySharedId]);

  const isCampaignDisabled = campaignBySharedId?.status === 'INATIVA';

  const handleValue = (numbersvalue: number, precoPorNumero: number) => {
    if (selectedProm !== undefined) return Number(selectedProm.valor) * promoqtd;
    const calculatedValue = numbersvalue * precoPorNumero;
    return Number.isNaN(calculatedValue) ? 0 : calculatedValue;
  };

  return (
    <div>
      <Loading open={campaignBySharedIdLoading} />
      <div className="h-full sm:p-2 md:p-8 flex mx-auto flex-col items-center justify-center max-w-[680px]">
        {
            campaignBySharedId?.imagem && (
            <div className="w-full bg-primary-800 rounded-t-2xl sm:p-2 md:p-4">
              <img
                src={campaignBySharedId?.imagem}
                className="w-full md:max-h-[500px] md:object-contain"
                alt="banner"
              />
            </div>
            )
        }
        <div className="w-full bg-primary-500 flex-col items-center flex justify-center px-4 border-t-4 border-t-secondary-500">
          <h3 className="text-white font-semibold text-xl my-2">
            {campaignBySharedId?.titulo}
            {campaignBySharedId?.preco_por_numero && (` - Apenas ${formatValue((campaignBySharedId.preco_por_numero).toString())}`)}
          </h3>
        </div>
        <div className="w-full bg-primary-500 flex justify-center px-4 mt-[-1px] relative">
          {(cotas && cotas.length > 0) && (
            <>
              <Button
                onClick={() => {
                  setOpenModalCotas((prevValue) => !prevValue);
                }}
                label="Cotas premiadas"
                customClass="my-2 uppercase !rounded-full w-[60%] shadow-xl size-12 flex items-center border-2 !font-bold border-secondary-500 !bg-green-500 hover:bg-green-500"
              />
              <img src={coinImg} className="absolute size-6 right-[25%] top-[20px]" alt="" />
            </>
          )}

        </div>

        <div className="p-4 bg-primary-500 mt-[-1px] w-full rounded-b-md">
          {
            isCampaignDisabled && <AlertMessage text="Sem mais bilhetes disponíveis" link="" customClass="bg-yellow-100 font-semibold w-full" textColor="text-yellow-700" Icon={ExclamationCircleIcon} IconColor="text-yellow-500" />
          }
          {
            !isCampaignDisabled && (
              <div className="bg-primary-700 py-4 rounded-md">
                <div className="w-full grid grid-cols-3 gap-3 items-center sm:p-1 md:p-6">
                  {
                      promos && promos.length > 0 && promos[0].quantidade !== 0 && Number(promos[0].valor) !== 0 && (
                        <Button
                          label={(
                            <div className="flex justify-between">
                              <img src={coinImg} className="size-6" alt="" />
                              <span className="mx-8">
                                {promos[0].quantidade}
                                {' '}
                                bilhete
                                {promos[0].quantidade > 1 ? 's' : ''}
                                {' '}
                                por apenas
                                {' '}
                                {formatValue(promos[0].valor.toString())}
                              </span>
                              <img src={coinImg} className="size-6" alt="" />
                            </div>
)}
                          customClass="w-full !rounded-[50px] animate-pulse h-16 text-xl flex items-center  size-16  !text-primary-700 group-hover:!text-white border
                    border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-primary-500 bg-yellow-400 hover:bg-yellow-500 hover:bg-yellow-500 col-span-full"
                          onClick={() => {
                            if (selectedProm === undefined) {
                              setNumbers(0);
                              setPromoqtd(0);
                            }
                            setNumbers((prevState) => prevState + promos[0].quantidade);
                            setPromoqtd((prevState) => prevState + 1);
                            setSelectedProm(promos[0]);
                          }}
                        />

                      )
                    }
                  <Button
                    label={`+${options[0].toString()}`}
                    customClass="bg-primary-800 !rounded-[50px] h-12 flex items-center w-full !text-xl font-bold
                hover:bg-primary-500 text-secondary-500 border-2 border-secondary-500 max-w-[630px]"
                    onClick={() => {
                      setNumbers(numbers + options[0]);
                      setSelectedProm(undefined);
                    }}
                  />
                  <Button
                    label={`+${options[1].toString()}`}
                    customClass="bg-primary-800 !rounded-[50px] h-12 flex items-center w-full !text-xl font-bold
                hover:bg-primary-500 text-secondary-500 border-2 border-secondary-500 max-w-[630px]"
                    onClick={() => {
                      setNumbers(numbers + options[1]);
                      setSelectedProm(undefined);
                    }}
                  />
                  <div className="border-2 rounded-[50px] border-yellow-300 w-full max-w-[630px] relative">
                    <Button
                      label={`+${options[2].toString()}`}
                      customClass="bg-primary-800 !rounded-[50px] h-12 flex items-center w-full !text-xl font-bold
                hover:bg-primary-500 text-yellow-300 max-w-[630px]"
                      onClick={() => {
                        setNumbers(numbers + options[2]);
                        setSelectedProm(undefined);
                      }}
                    />
                    <span className="bg-yellow-300 absolute text-center text-primary-900 py-0 px-[3px] text-[10px] w-[70%] top-0 left-1/2 translate-x-[-50%]
                  translate-y-[-50%] font-semibold rounded-xl"
                    >
                      Mais popular
                    </span>
                  </div>

                  <Button
                    label={`+${options[3].toString()}`}
                    customClass="bg-primary-800 !rounded-[50px] h-12 flex items-center w-full !text-xl font-bold
                hover:bg-primary-500 text-secondary-500 border-2 border-secondary-500 max-w-[630px]"
                    onClick={() => {
                      setNumbers(numbers + options[3]);
                      setSelectedProm(undefined);
                    }}
                  />
                  <Button
                    label={`+${options[4].toString()}`}
                    customClass="bg-primary-800 !rounded-[50px] h-12 flex items-center w-full !text-xl font-bold
                hover:bg-primary-500 text-secondary-500 border-2 border-secondary-500 max-w-[630px]"
                    onClick={() => {
                      setNumbers(numbers + options[4]);
                      setSelectedProm(undefined);
                    }}
                  />
                  <Button
                    label={`+${options[5].toString()}`}
                    customClass="bg-primary-800 !rounded-[50px] h-12 flex items-center w-full !text-xl font-bold
                hover:bg-primary-500 text-secondary-500 border-2 border-secondary-500 max-w-[630px]"
                    onClick={() => {
                      setNumbers(numbers + options[5]);
                      setSelectedProm(undefined);
                    }}
                  />
                </div>
              </div>
            )
          }

          <div className="flex flex-col gap-4 mt-8">
            { !isCampaignDisabled && (
              <>
                <div className="flex gap-4 max-w-[630px] w-full items-center">
                  <Button
                    label=""
                    type="button"
                    customClass="bg-primary-800 size-12 flex items-center !text-8xl
                  font-bold hover:bg-primary-500 !rounded-full border border-transparent hover:border-secondary-500"
                    Icon={MinusIcon}
                    iconClass="!m-0 size-9 text-yellow-300"
                    onClick={() => {
                      if (numbers === minNumbers) setNumbers(numbers);
                      else setNumbers(numbers - 1);
                      setSelectedProm(undefined);
                    }}
                  />
                  <Input
                    inputMode="numeric"
                    type="number"
                    label=""
                    value={numbers}
                    customClass="!border-[1px] !rounded-full w-full border-primary-400 h-12
                  !text-2xl text-center text-primary-400 font-bold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    errorMessage=""
                    onChange={(event) => changeNumber(event)}
                  />
                  <Button
                    label=""
                    type="button"
                    customClass="bg-primary-800 !rounded-full size-12 flex items-center !text-8xl font-bold
                  hover:bg-primary-500 hover:text-primary-500 border border-transparent hover:border-secondary-500"
                    Icon={PlusIcon}
                    iconClass="!m-0 size-9 text-yellow-300"
                    onClick={() => {
                      setNumbers(numbers + 1);
                      setSelectedProm(undefined);
                    }}
                  />
                </div>
                <div
                  className="flex items-center justify-center rounded-full h-12 border-2 border-secondary-500 w-full max-w-[630px] p-3"
                >
                  {campaignBySharedId?.preco_por_numero && (
                  <span
                    className="text-white sm:text-2xl md:text-4xl font-bold"
                  >
                    {formatValue(
                      handleValue(numbers, campaignBySharedId.preco_por_numero).toString(),
                    )}
                  </span>
                  )}
                </div>
                <div className="grid sm:grid-col-1 md:grid-cols-1 gap-6 w-full max-w-[630px]">
                  <Button
                    label="Quero participar!!"
                    customClass="sticky w-full animate-pulse h-14 !rounded-full text-xl flex items-center  size-16  !text-primary-700 group-hover:!text-white border
                    border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-secondary-500 hover:bg-secondary-500 hover:bg-primary-500"
                    onClick={() => {
                      if (numbers < minNumbers || Number.isNaN(numbers)) {
                        toast.warn(`A quantidade mínima de ${minNumbers} bilhetes`);
                        setNumbers(minNumbers);
                      } else {
                        setOpen((prevValue) => !prevValue);
                        submit();
                      }
                    }}
                  />
                </div>
                {promos && promos.length > 1 && (
                  <div className="bg-primary-700 py-4 mt-2 rounded-md">
                    <h3 className="text-white text-center font-semibold text-xl my-2">
                      Promoções
                    </h3>
                    <div className="w-full flex gap-3 justify-center items-center">

                      {/* <div className="border-2 rounded-md border-yellow-300 w-full max-w-[630px] relative">
                      <Button
                        label="6 por R$ 3,00"
                        customClass="bg-primary-800 h-24 flex items-center w-full !text-xl font-bold
                hover:bg-primary-500 text-yellow-300 max-w-[630px]"
                      />
                      <span className="bg-yellow-300 absolute text-center text-primary-900 py-0 px-[3px] text-[10px] w-[70%] top-0 left-1/2 translate-x-[-50%]
                  translate-y-[-50%] font-semibold rounded-xl"
                      >
                        Mais vendido
                      </span>
                    </div>
                    <Button
                      label="10 por R$ 5,00"
                      customClass="bg-primary-800 h-24 flex items-center w-full !text-xl font-bold
                hover:bg-primary-500 text-secondary-500 max-w-[630px] border-2 border-secondary-500"
                    /> */}
                    </div>
                    <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 gap-3 justify-center items-center sm:p-1 md:p-6">
                      {
                        promos.map((promo, index) => {
                          if (index === 0) return;
                          return (
                            <Button
                              label={`${promo.quantidade} por apenas ${formatValue(promo.valor.toString())}`}
                              customClass="w-full h-24 text-xl flex items-center  size-16  !text-primary-700 group-hover:!text-white border
                    border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 bg-secondary-500 hover:bg-secondary-500 hover:bg-primary-500"
                              onClick={() => {
                                if (selectedProm === undefined || selectedProm.id !== promo.id) {
                                  setNumbers(0);
                                  setPromoqtd(0);
                                }
                                setNumbers((prevState) => prevState + promo.quantidade);
                                setSelectedProm(promo);
                                setPromoqtd((prevState) => prevState + 1);
                              }}
                            />
                          );
                        })
                      }
                    </div>
                  </div>
                )}
              </>
            )}
            {
                !isCampaignDisabled
                && getPurchaseTop5Details
                && (campaignBySharedId?.mostrar_top !== 'NONE' && campaignBySharedId?.mostrar_top !== undefined)
                && getPurchaseTop5Details?.purchases && (
                <div className="w-full bg-primary-500 flex-col items-center flex justify-center pt-8">
                  <h3 className="text-white text-xl font-semibold flex items-center gap-2 mb-4">
                    <img className="size-10" src={trofeuImg} alt="um trofeu" />
                    {' '}
                    Top compradores
                  </h3>
                  <div className="bg-primary-500 flex flex-col items-center text-center px-2 py-4 rounded-md w-full">
                    <div className="flex flex-wrap gap-[5px] items-center justify-center w-full">
                      {
                            // eslint-disable-next-line consistent-return, array-callback-return
                            getPurchaseTop5Details?.purchases?.filter((purchase, index) => {
                              if (campaignBySharedId?.mostrar_top === 'TOP3') return index < 3;
                              if (campaignBySharedId?.mostrar_top === 'TOP5') return index < 5;
                            }).map((purchase, index) => (

                              <div className="bg-primary-800 w-[31%] px-1 py-3 text-center rounded-2xl border-2 border-secondary-500 buyers">
                                <p className="font-semibold text-white coin">
                                  {index + 1}
                                  °
                                </p>
                                <p className="font-semibold text-[12px] text-white">{purchase.nome_afiliado}</p>
                                <p className="font-semibold text-[10px] text-white">
                                  {purchase.quantidade_numeros}
                                  {' '}
                                  bilhetes
                                </p>
                              </div>

                            ))
                          }
                    </div>
                  </div>
                </div>
                )
            }

            {
                !isCampaignDisabled
                && parseInt(qtdNumbersSold, 10) > 0
                && campaignBySharedId?.mostrar_porcentagem_vendas && (
                <div className="w-full bg-primary-500 flex-col items-center flex p-4">
                  <h3 className="text-white text-xl font-semibold flex items-center gap-2 mb-4">
                    Corra e garanta já seus
                    bilhetes
                  </h3>
                  <div className="relative pt-1 w-full bg-primary-700 rounded-md p-4">
                    <div className="flex mb-2 items-center justify-between">
                      <div>
                        <span className="text-xs font-semibold inline-block text-white">
                          Bilhetes vendidos -
                          {' '}
                          {handleProgressBar(qtdMaxNumber, qtdNumbersSold)}
                          %
                        </span>
                      </div>
                    </div>
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                      <div
                        style={{ width: `${handleProgressBar(qtdMaxNumber, qtdNumbersSold)}%` }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-secondary-500"
                      />
                    </div>
                  </div>
                </div>
                )
            }
            <div
              className="w-full bg-primary-500 px-4 py-10 mt-[-1px] max-h-[250px] overflow-y-auto"
            >
              {
                  campaignBySharedId?.descricao && (
                  <div className="flex items-center flex-col w-full text-center">
                    <pre className="text-white mt-4 w-full whitespace-break-spaces">{campaignBySharedId.descricao}</pre>
                  </div>

                  )
              }
            </div>
          </div>
          <div className="bg-primary-500 mt-[-1px] w-full sm:p-0 md:p-4 text-center">
            <div className="flex items-center sm:flex-col md:flex-row sm:mt-4 md:mt-auto justify-center">
              <h4 className="text-white text-xl font-bold uppercase underline">Sorteio loteria federal: </h4>
              {campaignBySharedId?.data_sorteio && (
              <span className="text-white text-2xl font-semibold px-1">
                {format(campaignBySharedId.data_sorteio, 'dd/MM/yyyy')}
                {' '}
                a partir
                {' '}
                {format(campaignBySharedId.data_sorteio, 'HH:mm')}
              </span>
              )}
            </div>
            <h4 className="font-semibold text-xl sm:p-4 md:px-32 text-white sm:mt-0 md:mt-8">
              Quanto mais bilhetes
              comprar, maiores são as chances de ganhar!
            </h4>
          </div>
        </div>
      </div>
      {(open && !openLoginModal) && <PurchaseModal setOpen={setOpen} data={paymentData} />}
      <ModalCotas
        toggleModal={openModalCotas}
        setOpen={setOpenModalCotas}
        awards={cotas}
        next={nextPage}
        hasMore={!isLastPage}
      />
    </div>
  );
}

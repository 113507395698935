import React, { HTMLProps, InputHTMLAttributes } from 'react';
import ReactInputMask from 'react-input-mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    errorMessage: string | undefined
    customClass?: HTMLProps<HTMLElement>['className']
    mask?: string | (string | RegExp)[]
    containerClass?: string
}
function Input({
  label = '',
  type,
  name,
  id,
  placeholder,
  onChange,
  value,
  required,
  errorMessage,
  customClass = '',
  mask,
  defaultValue,
  containerClass,
  disabled,
}: InputProps) {
  return (
    <div className={`w-full ${containerClass}`}>
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-600"
      >
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <div>
        <ReactInputMask
          mask={mask ?? ''}
          value={value ?? defaultValue}
          type={type}
          name={name}
          id={id}
          className={`${customClass} block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6`}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
        {errorMessage && <p className="text-rose-500 mt-0 text-sm">{errorMessage}</p> }
      </div>
    </div>
  );
}
Input.defaultProps = {
  label: '',
  customClass: '',
  mask: '',
  containerClass: '',
};

export { Input };
